import { Controller } from 'stimulus'
import SnackBar from '../../_components/snackbar/snackbar'

import { PAYMENTS_LICENSES_URL } from '../../_js/base/endpoints'

export default class PaymentCompleteController extends Controller {
  static targets = ['serial', 'serialLink', 'email', 'invoiceNumber', 'link', 'copyToClipboard']

  static classes = ['incompleteUrl', 'loading', 'licenseFound', 'licenseError', 'hasInvoiceNumber']

  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionID = urlParams.get('session_id')
    if (!sessionID) {
      this.element.classList.add(this.incompleteUrlClass)
      return
    }
    // we have a session id, i.e: a checkout session has been created
    // get some info about the state/serial etc and inform the customer
    this.getSessionInfo(sessionID)
  }

  async getSessionInfo(sessionID, attempt = 1) {
    this.element.classList.add(this.loadingClass)
    const url = `${PAYMENTS_LICENSES_URL}/1/checkout/session?id=${sessionID}`

    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
      })

      const sessionInfo = await response.json()

      if (sessionInfo.invoice_number) {
        this.invoiceNumber.textContent = sessionInfo.invoice_number
        this.element.classList.add(this.hasInvoiceNumberClass)
      }

      // Missing serial or invoice number, or status not complete
      if (!sessionInfo.serial || !sessionInfo.invoice_number) {
        if (attempt < 5) {
          const retryDelays = [2000, 4000, 6000, 8000, 10000]
          setTimeout(() => {
            this.getSessionInfo(sessionID, attempt + 1)
          }, retryDelays[attempt])

          return
        }

        this.element.classList.remove(this.loadingClass)
        this.element.classList.add(this.licenseErrorClass)
        return
      }

      this.element.classList.remove(this.loadingClass)

      if (sessionInfo.status === 'complete') {
        if (sessionInfo.serial) {
          this.element.classList.add(this.licenseFoundClass)
          this.serial.textContent = sessionInfo.serial
          this.serialLink.href = `sketch://license/register?key=${sessionInfo.serial}`
        } else {
          // this can happen if the payment is completed, but we haven't received the Stripe callback yet
          this.element.classList.add(this.licenseErrorClass)
        }

        if (sessionInfo.invoice_number) {
          this.invoiceNumber.textContent = sessionInfo.invoice_number
        }
        this.emailTargets.forEach((emailElement) => {
          emailElement.textContent = sessionInfo.customer_email // Update all 'email' target elements with the new email
        })
      }
    } catch (e) {
      // Fetch failed
      if (attempt < 5) {
        setTimeout(() => {
          this.getSessionInfo(sessionID, attempt + 1)
        }, 2000)
      } else {
        this.element.classList.remove(this.loadingClass)
        this.element.classList.add(this.licenseErrorClass)
      }
    }
  }

  copyToClipboard() {
    const serial = this.serial.textContent
    navigator.clipboard.writeText(serial).then(() => {
      const message = 'License key copied to Clipboard'
      const snackbar = new SnackBar(message, {
        modifiers: 'success',
        autoHide: true,
        duration: 5000,
      })
      snackbar.show()
    })
  }

  get serial() {
    return this.serialTarget
  }

  get invoiceNumber() {
    return this.invoiceNumberTarget
  }

  get serialLink() {
    return this.serialLinkTarget
  }
}
