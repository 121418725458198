export const KEYS = Object.freeze({
  tab: 9,
  enter: 13,
  space: 32,
  escape: 27,
  arrowUp: 38,
  arrowDown: 40,
  arrowLeft: 37,
  arrowRight: 39,
  A: 65,
  B: 66,
  H: 72,
  L: 76,
})

export const Strings = Object.freeze({
  dates: {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
})

export const REGEX = Object.freeze({
  anchorHref: /^#([^#][.\-\w]+)$/,
  licenseKey: /^SK3(?:-[0-9]{4}){5}$/,
})

// Please keep up to date with $viewport-* SCSS variables in variables.viewport.prism.scss
export const BREAKPOINTS = Object.freeze({
  VIEWPORT_3XS: 330,
  // Fully supported values ↓
  VIEWPORT_2XS: 360,
  VIEWPORT_XS: 390,
  VIEWPORT_S: 640,
  VIEWPORT_M: 768,
  VIEWPORT_L: 1024,
  VIEWPORT_XL: 1220,
  VIEWPORT_2XL: 1440,
  VIEWPORT_3XL: 1600,
  VIEWPORT_4XL: 1920,
  // Fully supported values ↑
  VIEWPORT_5XL: 2400,
})
