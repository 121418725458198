import { Controller } from 'stimulus'
import { copyToClipboard } from '../base/utils'
import SnackBar from '../../_components/snackbar/snackbar'

/**
 * Sharing
 *
 * Actions
 * - share: invokes the native sharing
 *
 * Values
 * - url:   optional, URL to share, defaults to window.location
 * - title: optional, title for usage in navigator.share
 * - text:  optional, text for usage in navigator.share
 */
export default class extends Controller {
  static values = {
    url: String,
    title: String,
    text: String,
  }

  /**
   * On connect, completely remove the element from the DOM if the browser doesn't
   * support both Web Share API and Clipboard API. If Clipboard API is supported,
   * 'data-tooltip' attribute text should be changed to reflect the function.
   */
  connect() {
    if (typeof navigator.share !== 'function') {
      if (typeof navigator.clipboard.writeText !== 'function') {
        this.element.remove()
      }

      this.element.setAttribute('aria-label', 'Copy URL to Clipboard…')
    }
  }

  get shareProps() {
    return {
      url: this.hasUrlValue ? this.urlValue : window.location,
      title: this.titleValue,
      text: this.textValue,
    }
  }

  share() {
    this.element.classList.add('is-active')
    if (typeof navigator.share === 'function') {
      navigator
        .share(this.shareProps)
        .catch(() => {
          // do nothing if the user decided not to share
        })
        .finally(() => {
          this.element.classList.remove('is-active')
        })
    } else {
      const snackbarMessage = copyToClipboard(this.shareProps.url)
      // eslint-disable-next-line no-new
      new SnackBar(snackbarMessage, { autoHide: true })
      this.element.classList.remove('is-active')
    }
  }
}
