import { Controller } from 'stimulus'

export default class BlogController extends Controller {
  static targets = ['codeBlock', 'demo', 'moreTagsButton', 'inThisArticle', 'post']

  connect() {
    if (this.hasInThisArticleTarget) {
      this.getAListOfHeadings()
      this.observeLinks()
    }
  }

  expandCodeBlock() {
    this.codeBlockTarget.classList.remove('highlighter-rouge--expandable')
  }

  displayIframe() {
    this.demoTarget.classList.add('is-loaded')
  }

  // eslint-disable-next-line class-methods-use-this
  showTags(event) {
    const parent = event.currentTarget.parentNode
    const collapsedTags = parent.querySelectorAll('.btc-tag--is-collapsed')
    collapsedTags.forEach((tag) => {
      tag.classList.remove('btc-tag--is-collapsed')
    })
    event.currentTarget.classList.add('is-hidden')
  }

  getAListOfHeadings() {
    const headings = this.postTarget.querySelectorAll('h2')
    const inThisArticle = this.inThisArticleTarget

    headings.forEach((heading) => {
      const li = document.createElement('li')
      const a = document.createElement('a')
      a.href = `#${heading.id}`
      a.textContent = heading.textContent
      a.classList.add('in-this-article__link')
      li.classList.add('in-this-article__item')
      li.appendChild(a)
      inThisArticle.appendChild(li)
    })
  }

  observeLinks() {
    let activeLinkId = null

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute('id')
          const activeLink = this.inThisArticleTarget.querySelector(`a[href="#${id}"]`)

          if (entry.isIntersecting && id !== activeLinkId) {
            // Remove active class from the previously active link
            if (activeLinkId) {
              const prevActiveLink = this.inThisArticleTarget.querySelector(`a[href="#${activeLinkId}"]`)
              prevActiveLink.classList.remove('is-active')
            }

            // Add active class to the current link
            activeLinkId = id
            activeLink.classList.add('is-active')
          }
        })
      },
      { threshold: 1 }
    )

    const headings = this.postTarget.querySelectorAll('h2')
    headings.forEach((heading) => {
      observer.observe(heading)
    })
  }
}
