import h from 'hyperscript'
import classNames from 'classnames'
import { bemPrefixModifiers } from '../../_js/base/utils'
import FocusTrap from '../../_js/utils/focus-trap'
import Overlay from '../overlay/overlay'

export default class Dialog {
  /**
   * Create new instance of Dialog
   * @param {Object}           [opts]
   * @param {(string|Element)} [opts.content]                  - content for the dialog
   * @param {boolean}          [opts.hasContainer=true]        - whether to use the default container for the dialog
   * @param {boolean}          [opts.shouldDestroyOnHide=true] - whether the dialog will be removed from the DOM when it’s hidden
   * @param {string}           [opts.modifiers]                - BEM-style modifiers for the default wrapper, only if custom is true
   * @param {string}           [opts.overlayModifiers]         - BEM-style modifiers for the overlay
   */
  constructor({ content, hasContainer = true, shouldDestroyOnHide = true, modifiers, overlayModifiers } = {}) {
    this.hasContainer = hasContainer
    this.containerModifiers = modifiers

    // The dialog’s element will be inserted into the overlay
    this.overlay = new Overlay({
      modifiers: `cover-all with-dialog ${overlayModifiers}`,
      shouldDestroyOnHide,
      shouldDismissOnClick: true,
      shouldDismissOnEsc: true,
      hasCloseButton: this.hasContainer,
    })

    // Trap keyboard focus on the overlay
    this.trap = FocusTrap(this.overlay.node)

    if (content) {
      this.content = content
    }

    this.overlay.on('show', this.didShow.bind(this))
    this.overlay.on('hide', this.didHide.bind(this))
  }

  /**
   * Shows the dialog by showing its containing overlay
   * @returns {boolean}
   */
  show() {
    return this.overlay.show()
  }

  /**
   * Sets the body of the dialog, creating a container if using one.
   * The content is directly appended to the overlay.
   * @param {(string|Element)} content
   */
  set content(content) {
    this.overlay.appendContent(this.hasContainer ? this.buildContainerElement(content) : content)
  }

  /**
   * Get the dialog’s root element
   * @returns {?Element}
   */
  get element() {
    return this.overlay.content ? this.overlay.content[0] : null
  }

  /**
   * Get all sibling elements of the overlay that aren't <script>
   * @returns {Element[]}
   */
  get overlaySiblingElements() {
    return [...this.overlay.node.parentElement.children].filter(
      (element) => element !== this.overlay.node && element.tagName.toLowerCase() !== 'script'
    )
  }

  /**
   * Build default dialog container
   * @param {(string|Element)} content - content for the dialog, should have just one root node
   */
  buildContainerElement(content) {
    const tagName = 'section'
    const props = {
      className: classNames('dialog', bemPrefixModifiers('dialog', this.containerModifiers)),
      'aria-role': 'dialog',
    }
    return typeof content === 'string' ? h(tagName, { ...props, innerHTML: content }) : h(tagName, props, content)
  }

  /**
   * Callback called when overlay is shown
   */
  didShow() {
    this.trap.activate()
    this.overlaySiblingElements.forEach((el) => el.setAttribute('aria-hidden', 'true'))
  }

  /**
   * Callback called when overlay is hidden
   */
  didHide() {
    this.trap.deactivate()
    this.overlaySiblingElements.forEach((el) => el.removeAttribute('aria-hidden'))
  }
}
