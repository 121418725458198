import { Controller } from 'stimulus'
import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper'
import mediaFrom from '../../_js/utils/media-from'

const AUTOPLAY_DELAY = 4200
const BULLET_ACTIVE_CLASS = 'update__title--active'

export default class UpdatesSlider extends Controller {
  static targets = ['updatesWrapper']

  static values = {
    viewportBreak: String,
  }

  updatesSlider

  connect() {
    const sliderOptions = {
      effect: 'fade',
      allowTouchMove: false,
      fadeEffect: { crossFade: true },
      direction: 'vertical',
      slidesPerView: 'auto',
      loop: false,
      autoplay: {
        delay: AUTOPLAY_DELAY,
        disableOnInteraction: false,
      },
      modules: [Autoplay, EffectFade, Pagination],
      breakpoints: {
        [this.viewportBreakValue]: {
          pagination: {
            el: '.updates-slider__update-list',
            clickable: true,
            renderBullet: function (index, className) {
              const parent = this.el.closest('.updates-slider')
              const titles = Array.from(parent.querySelectorAll('.update')).map((el) => el.getAttribute('data-title'))
              return `
                <div class="${className}">
                  <div class="update__title__circle">
                    <svg width="20" height="20">
                      <circle cx="10" cy="10" r="8" stroke="#F26725" stroke-width="4" fill="none" stroke-opacity="0.3" />
                      <circle class="update__title__circle__progress" cx="10" cy="10" r="8" stroke="#FF7843" stroke-width="4" fill="none" stroke-opacity="1" stroke-dasharray="75" stroke-dashoffset="0" />
                    </svg>
                  </div>
                  ${titles[index]}
                </div>
              `
            },
            bulletClass: 'update__title',
            bulletActiveClass: 'update__title--active',
          },
        },
      },
    }

    // Swiper restarts autoplay when user changes tab. We restart the css animation here.
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const activeElements = document.querySelectorAll(`.${BULLET_ACTIVE_CLASS}`)
        activeElements.forEach((activeElement) => {
          activeElement.classList.remove(BULLET_ACTIVE_CLASS)
          setTimeout(() => {
            activeElement.classList.add(BULLET_ACTIVE_CLASS)
          }, 1)
        })
      }
    })

    if (mediaFrom(this.viewportBreakValue)) {
      this.updatesWrapperTarget.classList.add('swiper-wrapper')
      this.updatesSlider = new Swiper('.updates-slider', sliderOptions)
    }

    window.addEventListener('resize', () => {
      if (mediaFrom(this.viewportBreakValue)) {
        if (!this.updatesSlider) {
          this.updatesWrapperTarget.classList.add('swiper-wrapper')
          this.updatesSlider = new Swiper('.updates-slider', sliderOptions)
        }
      } else {
        if (this.updatesSlider) {
          this.updatesSlider.destroy()
          this.updatesSlider = null
        }
        this.updatesWrapperTarget.classList.remove('swiper-wrapper')
      }
    })
  }
}
