import h from 'hyperscript'
import { getNumberOfAuths } from '../../_js/helpers/auth'
import { WEB_APP_URL } from '../../_js/config'

export default function userProfileView(userProfileData) {
  return h(
    'a.button.button--secondary.button--compact.user-profile',
    {
      href: WEB_APP_URL,
      title: 'Go to the web app',
    },
    h('img.user-profile__avatar', {
      alt: `Profile picture for ${userProfileData.name}`,
      src: userProfileData.avatar.small,
    }),
    h('div.user-profile__label', getNumberOfAuths() > 1 ? 'My Workspaces' : 'My Workspace')
  )
}
