import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../base/consts'
import mediaFrom from '../utils/media-from'

export default class LegalHomeController extends Controller {
  static targets = ['background', 'section', 'cardsWrapper', 'outerCardsWrapper']

  defaultColor = 'green'

  activeColor = this.defaultColor

  cardWidth = 0

  ACTIVE_BACKGROUND_CLASS = 'legal__background--active'

  connect() {
    this.sectionTargets.forEach((section) => {
      section.addEventListener('mouseenter', (event) => {
        this.setBackground(event.target)
      })
      section.addEventListener('mouseleave', () => {
        this.revertBackground()
      })
    })

    this.cardWidth = this.sectionTarget.clientWidth

    if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.cardsWrapperTarget.addEventListener('scroll', (event) => this.cardsScrollHandler(event))
    }
  }

  disconnect() {
    this.sectionTargets.forEach((section) => {
      section.removeEventListener('mouseenter', (event) => {
        this.setBackground(event.target)
      })
      section.removeEventListener('mouseleave', () => {
        this.revertBackground()
      })
    })

    this.cardsWrapperTarget.removeEventListener('scroll', (event) => this.cardsScrollHandler(event))
  }

  cardsScrollHandler(event) {
    const LEFT_SHADE = 'outer-cards-wrapper--shaded-left'
    const RIGHT_SHADE = 'outer-cards-wrapper--shaded-right'

    if (event.target.scrollLeft > this.cardWidth / 4) {
      this.outerCardsWrapperTarget.classList.add(LEFT_SHADE)
    }

    if (event.target.scrollLeft > this.cardWidth / 3) {
      this.outerCardsWrapperTarget.classList.add(RIGHT_SHADE)
    }

    if (event.target.scrollLeft > this.cardWidth / 2) {
      this.outerCardsWrapperTarget.classList.remove(RIGHT_SHADE)
    }

    if (event.target.scrollLeft == 0) {
      this.outerCardsWrapperTarget.classList.remove(LEFT_SHADE)
    }
  }

  setBackground(target) {
    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      document.querySelector(`.legal__background--${this.activeColor}`).classList.remove(this.ACTIVE_BACKGROUND_CLASS)
      this.activeColor = target.dataset.color
      document.querySelector(`.legal__background--${this.activeColor}`).classList.add(this.ACTIVE_BACKGROUND_CLASS)
    }
  }

  revertBackground() {
    document.querySelector(`.legal__background--${this.activeColor}`).classList.remove(this.ACTIVE_BACKGROUND_CLASS)
    this.activeColor = this.defaultColor
    document.querySelector(`.legal__background--${this.activeColor}`).classList.add(this.ACTIVE_BACKGROUND_CLASS)
  }
}
