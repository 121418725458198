import { Controller } from 'stimulus'

export default class HandoffController extends Controller {
  static targets = ['outerLine', 'innerLine']

  connect() {
    window.scroll_delay = 475
    window.localStorage.setItem('inspector_introduction_seen', true)

    this.pageLoaded = false
    window.addEventListener('load', () => {
      this.pageLoaded = true
      this.calcOuterLines()
      this.calcInnerLines()
    })
    window.addEventListener('resize', this.calcOuterLines.bind(this))
    window.addEventListener('resize', this.calcInnerLines.bind(this))
  }

  // This function calculates the inspect lines for lines that are outside the wrapper
  calcOuterLines() {
    this.outerLineTargets.forEach((target) => {
      const distance = target.offset().left
      const lines = [target.querySelector('.inspect-line--left'), target.querySelector('.inspect-line--right')]

      lines.forEach((line) => {
        if (line.classList.contains('inspect-line--static')) return

        const pill = line.querySelector('.inspect-line__pill-value')
        pill.innerHTML = distance
        line.style.width = distance + 'px'
        line.classList.remove('is-hidden')
      })
    })
  }

  // This function calculates the inspect lines for lines that are inside the wrapper and are relative to it
  calcInnerLines() {
    this.innerLineTargets.forEach((target) => {
      const parent = target.parentElement

      // Get target and parent's rects
      const targetRect = target.getBoundingClientRect()
      const parentRect = parent.getBoundingClientRect()

      // Calculate distance to left and right of wrapper
      const distanceToLeft = targetRect.left - parentRect.left
      const distanceToRight = parentRect.right - targetRect.right

      const lines = target.querySelectorAll('.inspect-line')

      lines.forEach((line) => {
        if (line.classList.contains('inspect-line--static')) return

        const pill = line.querySelector('.inspect-line__pill-value')

        const distance = Math.round(line.classList.contains('inspect-line--left') ? distanceToLeft : distanceToRight)

        pill.innerHTML = distance
        line.style.width = distance + 'px'
        line.classList.remove('is-hidden')
      })
    })
  }

  // eslint-disable-next-line class-methods-use-this
  displayIframe() {
    const iframeContainer = document.querySelector('.iframe-container')
    iframeContainer.classList.add('is-loaded')
  }

  static isElementAllInsideViewport(el) {
    const elementBounds = el.getBoundingClientRect()
    return (
      elementBounds.top + window.scroll_delay >= 0 &&
      elementBounds.left >= 0 &&
      elementBounds.bottom - window.scroll_delay <= (window.innerHeight || document.documentElement.clientHeight) &&
      elementBounds.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  static isSafari() {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') == -1) {
        return true
      }
    }
    return false
  }
}
