import { Controller } from "stimulus"

export default class FeatureTabs extends Controller {
  static targets = ["asset"]

  connect() {
    this.element.addEventListener("click", (event) => {
      if (event.target.matches(".tabs__button")) {
        this.onClickTabs(event)
      }
    })
  }

  onClickTabs(event) {
    const activeSlug = event.target.getAttribute("data-slug")
    const activeFeature = event.target.closest(`[data-feature]`)

    this.assetTargets.forEach((target) => {
      if (target.closest(`[data-feature]`) !== activeFeature) return

      if (target.getAttribute("data-slug") === activeSlug || target.classList.contains("is-active")) {
        target.classList.remove("is-hidden")
      } else {
        target.classList.add("is-hidden")
      }
      target.classList.remove("is-active")
    })
  }

  disconnect() {
    this.element.removeEventListener("click", (event) => {
      if (event.target.matches(".tabs__button")) {
        this.onClickTabs(event)
      }
    })
  }
}
