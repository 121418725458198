import { Controller } from 'stimulus'
import SnackBar from '../snackbar/snackbar'
import ElementState from '../../_js/utils/element-state'

export default class FormController extends Controller {
  static values = {
    remote: Boolean,
    success: String,
    validate: Boolean,
  }

  static targets = [
    'email', // email field
    'button', // submit button
    'error', // error message element
  ]

  // class names for different states
  static classNames = {
    WAITING: 'is-waiting',
    SUCCESS: 'is-successful',
    INVALID: 'is-invalid',
  }

  // Default messages for different states
  static strings = {
    SUCCESS: 'We’ve successfully received your submission. Thank you!',
    VALIDATION_ERROR: 'Whoops! Looks like something’s wrong with your submission. Please double check and try again.',
    SUBMIT_ERROR:
      'Whoops! An error ocurred. Please double check your submission and try again. If the error persists, please <a href="/support/contact/">contact us</a>.',
  }

  // Duration of visual feedback for users
  static FEEDBACK_DURATION = 2000
  static ALERT_DURATION = 8000

  /**
   * Finds the matching <label> element for a control.
   * This will be either the <label> with a 'for' attribute that matches the control’s id attribute, or the closest ancestor <label>.
   * @param {Element} control
   * @returns {?Element} the first
   */
  static findLabelForControl(control) {
    let label = null
    // Try explicit label first
    if (control.id && control.id !== '') {
      label = document.querySelector(`label[for='${control.id}']`)
    }
    // If not found try implicit
    if (!label) {
      label = control.closest('label')
    }

    return label
  }

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('Form controller can only be used in <form> elements')
    }

    // Default values
    if (!this.hasValidateValue) this.validateValue = true

    // Disable native browser validation in favor of our own
    if (this.validateValue) {
      this.element.setAttribute('novalidate', '')
    }

    this.errorMessage = ''
  }

  /**
   * All form controls
   * @returns {HTMLFormControlsCollection}
   */
  get controls() {
    return this.element.elements
  }

  /**
   * Form controls that are candidates for validation
   * @returns {Element[]}
   */
  get validatableControls() {
    return Array.from(this.controls).filter((control) => 'willValidate' in control && 'checkValidity' in control)
  }

  /**
   * Form controls that submit the form
   * @returns {Element[]}
   */
  get submissionControls() {
    return Array.from(this.controls).filter(
      (control) =>
        (control instanceof HTMLButtonElement || control instanceof HTMLInputElement) && control.type === 'submit'
    )
  }

  /**
   * Set the form’s error message
   * @param {string} message
   */
  // eslint-disable-next-line class-methods-use-this
  set errorMessage(message) {
    const hasErrors = message && message !== ''
    if (hasErrors) {
      // eslint-disable-next-line no-new
      new SnackBar(message, {
        modifiers: 'error',
        autoHide: true,
        duration: FormController.ALERT_DURATION,
      })
    }
  }

  /**
   * Process the form for submission
   * @param {Event} event
   */
  async process(event) {
    if (event) event.preventDefault()

    if (this.validateValue) {
      this.errorMessage = ''
      const { isValid } = this.validate()
      if (isValid) this.submit()
    } else {
      this.submit()
    }
  }

  /**
   * Perform validation
   * @returns {ValidationResult} a promise with an object of valid and invalid controls
   */
  validate() {
    /**
     * @typedef {Object} ValidationResult
     * @property {?boolean}  isValid          - whether validation passed or not
     * @property {Object}    controls         - object with valid and invalid controls
     * @property {Element[]} controls.valid   - list of valid controls
     * @property {Element[]} controls.invalid - list of invalid controls
     */

    /** @type {ValidationResult} */
    const result = {
      isValid: null,
      controls: {
        valid: [],
        invalid: [],
      },
    }

    Array.from(this.controls).forEach((control) => {
      if (control.willValidate && 'checkValidity' in control) {
        const label = FormController.findLabelForControl(control)

        if (control.checkValidity()) {
          control.removeAttribute('aria-invalid')
          ElementState([control, label]).unset(FormController.classNames.INVALID)
          result.controls.valid.push(control)
        } else {
          control.setAttribute('aria-invalid', 'true')
          ElementState([control, label]).set(FormController.classNames.INVALID)
          result.controls.invalid.push(control)
        }
      }
    })

    result.isValid = result.controls.invalid.length === 0

    if (result.isValid === false) {
      this.errorMessage = FormController.strings.VALIDATION_ERROR
      ElementState(this.submissionControls).set(FormController.classNames.INVALID, FormController.FEEDBACK_DURATION)
      result.controls.invalid[0].focus()
    }

    return result
  }

  /**
   * Submit the form
   */
  submit() {
    if (this.remoteValue) {
      this.submitRemotely()
    } else {
      this.element.submit()
    }
  }

  submitRemotely() {
    // Capture form data before any inputs are possibly disabled
    const body = new FormData(this.element)
    this.disableSubmission()

    fetch(this.element.action, {
      method: 'POST',
      body,
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else return this.remoteSubmissionSuccessful()
      })
      .catch(() => this.remoteSubmissionFailed())
      .finally(() => this.enableSubmission())
  }

  disableSubmission() {
    this.submissionControls.forEach((control) => {
      control.setAttribute('disabled', 'disabled')
      control.classList.add(FormController.classNames.WAITING)
    })
  }

  enableSubmission() {
    this.submissionControls.forEach((control) => {
      control.removeAttribute('disabled')
      control.classList.remove(FormController.classNames.WAITING)
    })
  }

  async remoteSubmissionSuccessful(showSnackBar = true) {
    if (showSnackBar) {
      // eslint-disable-next-line no-new
      new SnackBar(this.successValue || FormController.strings.SUCCESS, {
        autoHide: true,
        modifiers: 'success',
        duration: FormController.ALERT_DURATION,
      })
    }
    // Highlight all form controls as successful
    await ElementState(this.controls).set(FormController.classNames.SUCCESS, FormController.FEEDBACK_DURATION)
    this.element.reset()
  }

  remoteSubmissionFailed() {
    ElementState(this.element).set(FormController.classNames.INVALID, FormController.FEEDBACK_DURATION)
    this.errorMessage = FormController.strings.SUBMIT_ERROR
  }
}
