import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'
import { enableScroll, disableScroll } from '../../_js/utils/prevent-scroll'

import { BREAKPOINTS } from '../../_js/base/consts'

export default class ReleasesController extends Controller {
  static targets = ['versionPicker', 'heroSection', 'heroPickerBar']

  static values = {
    versionPickerHiddenClass: String,
    isStickyClass: String,
  }

  static sectionObserver

  connect() {
    this.observeHeroSection()

    window.addEventListener(
      'resize',
      throttle(() => this.observeHeroSection(), 60)
    )
  }

  toggleBodyScroll() {
    const isMobile = window.innerWidth < BREAKPOINTS.VIEWPORT_M
    const isPickerOpen = this.versionPickerTarget.classList.contains(this.versionPickerHiddenClassValue)

    if (isMobile) {
      if (!isPickerOpen) {
        disableScroll(document.body)
      } else {
        enableScroll(document.body)
      }
    }
  }

  toggleVersionPicker() {
    this.versionPickerTarget.classList.toggle(this.versionPickerHiddenClassValue)
    this.toggleBodyScroll()
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop() {
    window.scrollTo({ behavior: 'smooth', top: 0 })
    window.history.pushState('', document.title, window.location.pathname)
  }

  observeHeroSection() {
    const rootMargin = window.innerWidth >= BREAKPOINTS.VIEWPORT_L ? '-65px' : '-129px'

    ReleasesController.sectionObserver = new IntersectionObserver(
      ([entry]) => this.togglePickerBarStickiness(!entry.isIntersecting),
      { threshold: 0, rootMargin }
    )

    ReleasesController.sectionObserver.disconnect()
    ReleasesController.sectionObserver.observe(this.heroSectionTarget)
  }

  togglePickerBarStickiness(shouldBeSticky) {
    this.heroPickerBarTarget.classList.toggle(this.isStickyClassValue, shouldBeSticky)
    this.versionPickerTarget.classList.toggle(this.isStickyClassValue, shouldBeSticky)
  }

  handleGlobalClicks({ target }) {
    this.smartCloseVersionPicker(target)
  }

  smartCloseVersionPicker(target) {
    if (target.classList.contains('hero__picker-button')) {
      return
    }

    const isVersionPickerOpen = !this.versionPickerTarget.classList.contains(this.versionPickerHiddenClassValue)
    const isMobile = window.innerWidth < BREAKPOINTS.VIEWPORT_M

    const isClickOnVersionPicker = !!target.closest('.hero__version-picker')
    const isClickOnBackdrop = target.closest('.hero__version-picker') === target

    if ((isVersionPickerOpen && !isClickOnVersionPicker) || (isMobile && isClickOnBackdrop)) {
      this.toggleVersionPicker()
    }
  }
}
