import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'statements',
    'statement',
    'hero',
    'heroPagination',
    'pagination',
    'paginationItem',
    'paginationProgress',
  ]

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      rootMargin: '0px',
      threshold: Array.from({ length: 101 }, (_, i) => i / 100), // Generate thresholds from 0 to 1 (0%, 1%, ..., 100%)
    })

    this.observer.observe(this.heroTarget)
    this.observer.observe(this.statementsTarget)

    this.statementTargets.forEach((statement) => {
      this.observer.observe(statement)
    })
  }

  disconnect() {
    this.observer.disconnect()
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.target === this.heroTarget) {
        if (entry.intersectionRatio == 0) {
          this.paginationTarget.classList.add('is-active')
        } else {
          this.paginationTarget.classList.remove('is-active')
        }

        return
      }

      if (entry.target === this.statementsTarget) {
        if (entry.intersectionRatio < 0.1) {
          this.paginationTarget.classList.remove('is-active')
        } else {
          this.paginationTarget.classList.add('is-active')
        }
        return
      }

      const index = this.statementTargets.indexOf(entry.target)

      // Check how much of the statement is visible
      if (entry.intersectionRatio > 0.25) {
        entry.target.classList.add('is-active')

        // Calculate the distance from the bottom of the statement to the top of the viewport
        const rect = entry.target.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const distanceFromBottomToTop = Math.max(0, rect.bottom - viewportHeight)

        // Calculate the visible percentage based on the distance
        const totalHeight = rect.height
        const visiblePercentage = Math.round((1 - distanceFromBottomToTop / totalHeight) * 100)

        // Update the progress bar with the current visible percentage
        this.updateProgress(index, visiblePercentage)

        this.paginationItemTargets.forEach((item, i) => {
          if (i === index) {
            item.classList.add('is-active')
          } else {
            item.classList.remove('is-active')
          }
        })
      } else {
        entry.target.classList.remove('is-active')
        this.paginationItemTargets.forEach((item, i) => {
          if (i === index) {
            item.classList.remove('is-active')
          }
        })
        // Reset progress when statement is not visible
        this.updateProgress(index, 0)
      }
    })
  }

  updateProgress(index, percentage) {
    const progressElement = this.paginationProgressTargets[index]
    if (progressElement) {
      progressElement.style.setProperty('height', `${percentage}%`)
    }
  }
}
