import { Controller } from 'stimulus'

export default class LicenseController extends Controller {
  license = null

  static classes = ['entered', 'educational', 'expired']

  static targets = ['card']

  getCardControllers() {
    return this.cardTargets.map((target) =>
      this.application.getControllerForElementAndIdentifier(target, 'license-operation')
    )
  }

  /**
   * Triggered right after a successful fetch of a license that's not void.
   */
  onCheckerSuccess(event) {
    /** @type {License} */
    this.license = event.detail

    this.toggleEducational(this.license.isEducational)

    this.element.classList.add(this.enteredClass)
    this.element.classList.toggle(this.expiredClass, this.license.isExpired)
    document.body.classList.add(this.enteredClass)

    this.getCardControllers().map((controller) => (controller.license = this.license))
  }

  /**
   * Triggered every time the license key input changes
   */
  onCheckerClean(_event) {
    this.element.classList.remove(this.enteredClass)
    document.body.classList.remove(this.enteredClass);

    this.getCardControllers().map((controller) => (controller.license = null))
  }

  toggleEducational(isEducational) {
    this.element.classList.toggle(this.educationalClass, isEducational)
  }
}
