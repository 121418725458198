const SCROLL_DISABLED_CLASS = 'prevent-scroll'

const disableScroll = (target = document.body) => {
  target.classList.add(SCROLL_DISABLED_CLASS)
}

const enableScroll = (target = document.body) => {
  target.classList.remove(SCROLL_DISABLED_CLASS)
}

export { disableScroll, enableScroll }
