import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import Swiper, { Navigation, Grid, Pagination } from 'swiper'
import { BREAKPOINTS } from '../../_js/base/consts'

const RESOURCE_TITLE_MARGIN_TOP = 16

export default class ResourcesController extends Controller {
  static targets = ['swipers', 'shuffle']

  connect() {
    this.shuffleItems()

    this.swipersTargets.forEach((swiper) => {
      const defaultMaxSlidesPerView = parseInt(swiper.dataset.defaultMaxSlidesPerView) ?? 5
      const defaultGridRows = parseInt(swiper.dataset.defaultGridRows) ?? 5

      const viewportMMaxSlidesPerView = parseInt(swiper.dataset.viewportMMaxSlidesPerView) ?? 5
      const viewportMGridRows = parseInt(swiper.dataset.viewportMGridRows) ?? 5

      const viewportLMaxSlidesPerView = parseInt(swiper.dataset.viewportLMaxSlidesPerView) ?? 5
      const viewportLGridRows = parseInt(swiper.dataset.viewportLGridRows) ?? 5

      // If it's a templates layout, uses 24 instead of 16
      const defaultSpaceBetween = swiper.querySelector('.templates') ? 24 : 16

      return new Swiper(swiper, {
        modules: [Navigation, Grid, Pagination],
        spaceBetween: defaultSpaceBetween,
        slidesPerView: defaultMaxSlidesPerView,
        slidesPerGroup: defaultMaxSlidesPerView,
        grid: {
          rows: defaultGridRows,
          fill: 'row',
        },

        breakpoints: {
          [BREAKPOINTS.VIEWPORT_M]: {
            spaceBetween: 24,
            slidesPerView: viewportMMaxSlidesPerView,
            slidesPerGroup: viewportMMaxSlidesPerView,
            grid: {
              rows: viewportMGridRows,
            },
          },
          [BREAKPOINTS.VIEWPORT_L]: {
            spaceBetween: 24,
            slidesPerView: viewportLMaxSlidesPerView,
            slidesPerGroup: viewportLMaxSlidesPerView,
            grid: {
              rows: viewportLGridRows,
            },
          },
        },

        navigation: {
          nextEl: swiper.parent().querySelector('.swiper-button-next'),
          prevEl: swiper.parent().querySelector('.swiper-button-prev'),
        },

        pagination: {
          el: swiper.parent().querySelector('.swiper-pagination'),
          clickable: true,
        },

        on: {
          afterInit: debounce(() => {
            const resourceTitles = swiper.querySelectorAll('.resource__title')
            if (resourceTitles.length) {
              const buttonNext = swiper.parent().querySelector('.swiper-button-next')
              const buttonPrevious = swiper.parent().querySelector('.swiper-button-prev')

              let highestHeight = Math.max.apply(
                null,
                [...resourceTitles].map((title) => title.clientHeight)
              )
              highestHeight += RESOURCE_TITLE_MARGIN_TOP

              buttonNext.classList.add('has-height')
              buttonPrevious.classList.add('has-height')
              buttonNext.style.setProperty('--subtract-height', highestHeight / 2 + 'px')
              buttonPrevious.style.setProperty('--subtract-height', highestHeight / 2 + 'px')
            }
            swiper.parent().classList.remove('is-not-visible')
          }, 0),
        },
      })
    })
  }

  shuffleItems() {
    this.shuffleTargets.forEach((shuffle) => {
      const shuffleResourcesList = shuffle.querySelector('.resources-list')

      const temp = shuffleResourcesList.cloneNode(true) // clone the list
      for (var i = temp.children.length + 1; i--; ) {
        // eslint-disable-next-line no-bitwise
        temp.appendChild(temp.children[(Math.random() * i) | 0])
      }
      shuffleResourcesList.parentNode.replaceChild(temp, shuffleResourcesList) // copy shuffled back to 'ul'
    })
  }
}
