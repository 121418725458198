import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class RelatedFeaturesSectionController extends Controller {
  static targets = ['swiper']

  // eslint-disable-next-line class-methods-use-this
  connect() {
    return new Swiper(this.swiperTarget, {
      slidesPerView: 1,
      spaceBetween: 32,
      modules: [Navigation, Pagination],
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: this.swiperTarget.parent().querySelector('.swiper-button-next'),
        prevEl: this.swiperTarget.parent().querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        [BREAKPOINTS.VIEWPORT_S]: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        [BREAKPOINTS.VIEWPORT_L]: {
          slidesPerView: 4,
        },
      },
    })
  }
}
