import { Controller } from 'stimulus'
import { REGEX } from '../../_js/base/consts'
import scrollTo from '../../_js/helpers/scrollTo'

export default class Scrollable extends Controller {
  static targets = ['sticky']

  /**
   * Gets the element for a specific hash
   * @static
   * @param {string} hash - fragment hash in the #name format
   * @returns {Element} - the element
   */
  static elementForHash(hash) {
    const id = hash.match(REGEX.anchorHref)[1]
    return document.getElementById(id)
  }

  /**
   * Calculate the offset of the tallest top-stuck element that is covering the scrollable
   * @returns {number}
   */
  maxStickyOffset() {
    return this.stickyTargets.reduce((maxOffset, sticky) => {
      const css = window.getComputedStyle(sticky)

      // Skip if the sticky isn’t actually sticky
      if (['sticky', 'fixed'].includes(css.getPropertyValue('position')) == false || css.getPropertyValue('top') == '')
        return maxOffset

      // Two values affect the sticky’s offset
      // 1. add top as that pushes the sticky down
      // 2. subtract border-bottom to prevent stacked borders in case the target has a border-top
      const offset =
        sticky.getBoundingClientRect().height +
        parseFloat(css.getPropertyValue('top')) -
        parseFloat(css.getPropertyValue('border-bottom-width')) +
        parseFloat(css.getPropertyValue('margin-bottom'))

      return Math.max(offset, maxOffset)
    }, 0)
  }

  /**
   * Scroll the scrollable to the target, accounting for sticky elements
   * @async
   * @param {Element} element - element to be scrolled to
   * @param {Object} options - options to be passed to scrollTo
   */
  async scrollToTarget(element, options) {
    // Account for top margin when calcuating target element’s position
    const elementTop =
      this.element.scrollTop +
      element.getBoundingClientRect().top -
      parseFloat(window.getComputedStyle(element).getPropertyValue('margin-top'))

    const offset = this.maxStickyOffset()
    const scrollPosition = elementTop - offset

    return scrollTo(scrollPosition, this.element, options)
  }

  /**
   * Process click anywhere in the scrollable
   * @param {MouseEvent} event
   */
  navigate(event) {
    // event source element must have an href to a page fragment
    const href = event.target.closest('[href]')?.getAttribute('href')
    if (!href || !REGEX.anchorHref.test(href)) return

    // and the fragment must exist on the page
    const targetElement = Scrollable.elementForHash(href)
    if (!targetElement) return

    event.preventDefault()
    // Use history instead of location.hash so goToHash isn’t be needlessly called
    window.history.pushState(null, null, href)
    this.scrollToTarget(targetElement)
  }

  /**
   * Nudge the container’s scroll to the element specified in location.hash
   * This is called on dom ready or hash change
   */
  goToHash() {
    if (!window.location.hash || window.location.hash === '') return
    const targetElement = Scrollable.elementForHash(window.location.hash)
    if (!targetElement) return

    this.scrollToTarget(targetElement, { duration: 0 })
  }
}
