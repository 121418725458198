import { debounce } from 'lodash'
import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class PageNav extends Controller {
  isDesktop = true

  connect() {
    this.navHide = document.querySelector('.navHide')

    if (PageNav.isIOS()) {
      window.addEventListener(
        'scroll',
        debounce(() => {
          this.scrollEventHandler()
        }, 20),
        { passive: true }
      )
    } else {
      window.addEventListener('scroll', this.scrollEventHandler.bind(this), { passive: true })
    }

    /*
    / We delay the first scrollEventHandler to avoid a conflict with entrance controller
    / Not the best approach, but it is what it is, for now.
    */
    setTimeout(() => {
      this.scrollEventHandler()
    }, 200)
  }

  scrollEventHandler() {
    this.isDesktop = window.innerWidth >= BREAKPOINTS.VIEWPORT_L
    const elementTop = this.element.getBoundingClientRect().top
    const navHideTop = this.navHide.getBoundingClientRect().top

    if (this.isDesktop) {
      this.element.classList.toggle('is-stuck', elementTop <= 0)
    } else {
      this.element.classList.toggle('is-stuck', elementTop <= 64)
    }

    // removes entrance animation after it gets stuck a first time
    if (this.element.classList.contains('entrance--fadeUp') && elementTop <= 0) {
      this.element.classList.remove('entrance--fadeUp')
    }

    this.element.classList.toggle('page-nav-is-visible', !PageNav.isScrollAtBottom() && navHideTop > 0)
  }

  static isIOS() {
    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (iosPlatforms.indexOf(platform) !== -1) {
      return true
    }

    return false
  }

  static isScrollAtBottom() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight

    return scrollTop + clientHeight >= scrollHeight
  }
}
