import h from 'hyperscript'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { bemPrefixModifiers } from '../../_js/base/utils'
import { KEYS } from '../../_js/base/consts'

export default class Overlay {
  static ACTIVE_CLASS = 'is-active'

  /**
   * Creates an overlay and adds it to the page
   * @param {Object}  [opts]
   * @param {string}  [opts.modifiers]                  - BEM-style modifiers to add to the base class name
   * @param {string}  [opts.classes]                    - Extra classes to be added to the overlay container
   * @param {string}  [opts.content]                    - HTML content to be inserted into overlay
   * @param {boolean} [opts.shouldDestroyOnHide=false]  - Whether to destroy the overlay after hiding it
   * @param {boolean} [opts.shouldDismissOnClick=true]  - Whether the overlay can be dismissed by clicking on it
   * @param {boolean} [opts.shouldDismissOnEsc=false]   - Whether the overlay can be dismissed by hitting escape
   * @param {boolean} [opts.hasCloseButton=false]       - Whether a close button should be shown
   */
  constructor({
    modifiers,
    classes,
    content,
    shouldDestroyOnHide = false,
    shouldDismissOnClick = true,
    shouldDismissOnEsc = false,
    hasCloseButton = false,
  } = {}) {
    this.options = {
      shouldDestroyOnHide,
      shouldDismissOnClick,
      shouldDismissOnEsc,
      hasCloseButton,
    }
    /** @type {Object.<string,Function[]>} */
    this._listeners = {}

    this._createElements(modifiers, classes, content)
    this.isVisible = false

    this._boundClickHandler = this._boundClickHandler.bind(this)
    this._boundEscKeyHandler = this._boundEscKeyHandler.bind(this)
    this._boundHideTransitionEndHandler = this._boundHideTransitionEndHandler.bind(this)
  }

  _createElements(modifiers, classes, content) {
    const className = classNames('overlay', bemPrefixModifiers('overlay', modifiers), 'js-overlay', classes)
    // There can be multiple overlays, and we need a unique ID for aria-controls
    const id = `overlay-${uuid()}`

    this.node = h(
      'div',
      { className, id },
      this.options.hasCloseButton
        ? h(
            `div.overlay__sticky-navigation`,
            h(
              `button.overlay__hide.js-overlay-hide`,
              {
                attrs: {
                  'aria-controls': id,
                },
              },
              h('span.visuallyhidden', 'Close')
            )
          )
        : null
    )

    if (content) {
      this.appendContent(content)
    }

    document.body.appendChild(this.node)
  }

  /**
   * Gets the contents of the overlay, if any
   * @returns {?HTMLCollection}
   */
  get content() {
    return this.wrapperNode ? this.wrapperNode.children : null
  }

  /**
   * Adds HTML content to the overlay
   * @param {(string|Element)} html
   */
  appendContent(html) {
    // Create a wrapper node for content if it doesn’t exist
    if (typeof this.wrapperNode === 'undefined') {
      this.wrapperNode = h('div.overlay__wrapper')
      this.node.appendChild(this.wrapperNode)
    }

    if (typeof html === 'string') {
      this.wrapperNode.innerHTML += html
    } else {
      this.wrapperNode.appendChild(html)
    }
  }

  /**
   * Add a function to be called when the overlay is dismissed
   * @param {string} type - the method which, when called, will call the hook function
   * @param {function} handler - the hook function to be called
   * @returns {this}
   */
  on(type, handler) {
    if (!this._listeners[type]) {
      this._listeners[type] = []
    }
    this._listeners[type].push(handler)
    return this
  }

  /**
   * Toggle the overlay
   * @param {boolean} [force] - When true/false, force show/hide respectively
   * @returns {boolean}
   */
  toggle(force) {
    return this.isVisible || force === false ? this.hide() : this.show()
  }

  /**
   * Show the overlay
   * @returns {boolean}
   */
  show() {
    if (this.isVisible) return false

    this.isVisible = true

    this.node.addEventListener('click', this._boundClickHandler)
    if (this.options.shouldDismissOnEsc) {
      document.addEventListener('keydown', this._boundEscKeyHandler)
    }
    this.node.classList.add(Overlay.ACTIVE_CLASS)

    disableBodyScroll(this.node, {
      // Allow elements with .body-scroll-lock-ignore to be scrolled in mobile devices
      allowTouchMove: (el) => el.closest('.body-scroll-lock-ignore') !== null,
    })

    this._trigger('show')
    return true
  }

  /**
   * Hide the overlay
   * @returns {boolean}
   */
  hide() {
    if (!this.isVisible) return false

    this.isVisible = false

    this.node.removeEventListener('click', this._boundClickHandler)
    if (this.options.shouldDismissOnEsc) {
      document.removeEventListener('keydown', this._boundEscKeyHandler)
    }

    this.node.classList.remove(Overlay.ACTIVE_CLASS)
    enableBodyScroll(this.node)
    this._trigger('hide')

    if (this.options.shouldDestroyOnHide) {
      this.node.addEventListener('transitionend', this._boundHideTransitionEndHandler)
    }

    return true
  }

  /**
   * Triggers an internal event, calling its registered _listeners
   * @param {string} type - event type to be fired
   */
  _trigger(type) {
    if (!this._listeners[type]) return

    this._listeners[type].forEach((listener) => listener())
  }

  /**
   * Dismiss the overlay
   * This method is only called internally, by the click handler, used for events
   */
  _dismiss() {
    if (!this.isVisible) return false

    this._trigger('dismiss')
    this.hide()
    return true
  }

  /**
   * Handle Escape to close the dialog
   * @param {KeyboardEvent} event
   */
  _boundEscKeyHandler(event) {
    if (!this.isVisible) return

    // Use both methods of checking character for broader support
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === KEYS.escape) {
      event.preventDefault()
      this._dismiss()
    }
  }

  /**
   * Handle click to dismiss the overlay when background is clicked
   * @param {MouseEvent} event
   */
  _boundClickHandler(event) {
    if (!this.isVisible) return

    // There's a close button that was clicked
    // or background was clicked
    if (event.target instanceof Element && event.target.closest('.js-overlay-hide') !== null) {
      this._dismiss()
    } else if (
      this.options.shouldDismissOnClick &&
      (event.target == this.node || (this.wrapperNode && event.target == this.wrapperNode))
    ) {
      this._dismiss()
    }
  }

  /**
   * Handle transition event when overlay hides and will be destroyed after hiding
   * @param {TransitionEvent} event
   */
  _boundHideTransitionEndHandler(event) {
    /*
     * 1. Only if destroying
     * 2. TransitionEvent bubbles, only catch the overlay
     * 3. TransitionEvent fires once for each transition property, but IE might not have propertyName
     * 4. Only remove if it hasn’t been removed already
     */
    if (
      this.options.shouldDestroyOnHide && // [1]
      !this.isVisible &&
      event.target === this.node && // [2]
      (!('propertyName' in event) || event.propertyName == 'visibility') && // [3]
      this.node.parentNode // [4]
    ) {
      this.node.parentNode.removeChild(this.node)
    }
  }
}
