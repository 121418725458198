import { Controller } from 'stimulus'
import { parseQueryString } from '../base/utils'
import Dialog from '../../_components/dialog/dialog'

/**
 * Event Overlays/Dialogs
 *
 * This controller will enable the load of an event dialog from the URL.
 * Works by looking into the URL params, expecting a 'overlay' to be present, and uses it to
 * load a static page located at /event/value_of_overlay/, which is part of the _event-dialogs
 * site collection.
 *
 * At the time of this implementation, only the home page is able to load these. To enable it on any
 * other page we just need to use data-controller="event-dialog" on any other page.
 */

export default class EventDialog extends Controller {
  campaign = parseQueryString().overlay

  templateUrl = `/event/${this.campaign}/`

  connect() {
    if (!this.campaign) return
    this.load()
  }

  async load() {
    const serverResponse = await fetch(this.templateUrl)
    if (!serverResponse.ok) throw Error(`${serverResponse.status}: ${serverResponse.statusText}`)

    const template = await serverResponse.text()
    const dialog = new Dialog({ content: template, hasContainer: true, overlayModifiers: 'prism' })

    // Delay the show just enough so the CSS transition actually transitions, instead of flashing
    // straight to the end state; see https://stackoverflow.com/questions/24148403/trigger-css-transition-on-appended-element
    window.requestAnimationFrame(() => window.setTimeout(() => dialog.show()))
  }
}
