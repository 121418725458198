import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use'
import debounce from 'lodash/debounce'
import toggleFullscreen from '../../_js/utils/toggle-fullscreen'
import { enableScroll, disableScroll } from '../../_js/utils/prevent-scroll'
import { KEYS, BREAKPOINTS } from '../../_js/base/consts'
import mediaFrom from '../../_js/utils/media-from'

const TOUCH_CONTROLS_AUTOHIDE_DURATION_MS = 1500

export default class MediaPlayer extends Controller {
  static targets = [
    'video',
    'progressBar',
    'progressBarFill',
    'controls',
    'subtitles',
    'subtitlesWrapper',
    'time',
    'largePlay',
    'closeButton',
    'replayButton',
  ]

  static classes = ['active', 'ended', 'stopped', 'noSound']

  static formatTime(sec) {
    const min = Math.floor(sec / 60)
    const s = Math.floor(sec - min * 60)
    return `${('0' + min).slice(-2)}:${('0' + s).slice(-2)}`
  }

  isSeeking = false

  originalProgressBarTransitionDuration = null

  connect() {
    useDispatch(this)

    // Reset custom UI control auto-hiding timeout
    this.touchAutohideControlsTimeout = null

    // Hides all hover related css styles for touch friendly devices
    if (!('ontouchstart' in document.documentElement)) {
      this.element.classList.add('no-touch')
    }

    if (!this.videoTarget.hasAttribute('autoplay')) {
      // Show large play button if the 'autoplay' tag is not present
      this.largePlayTarget.classList.remove('is-hidden')

      this.element.classList.add(this.stoppedClass)
    }

    if (this.hasLightbox) {
      window.addEventListener(
        'resize',
        debounce(() => {
          this.centerVideo()
        }, 50)
      )
      window.addEventListener(
        'hashchange',
        debounce(() => {
          this.centerVideo()
        }, 50)
      )
    }

    this.originalProgressBarTransitionDuration = getComputedStyle(this.element).getPropertyValue(
      '--progress-bar-transition-duration'
    )

    if (this.hasNoSoundClass) {
      this.element.classList.add(this.noSoundClass)
    }
  }

  // For those videos that run with a lightbox
  get isActive() {
    return this.element.classList.contains(this.activeClass)
  }

  get isStopped() {
    return this.videoTarget.currentTime === 0 || this.videoTarget.currentTime === this.videoTarget.duration
  }

  get isPlaying() {
    return !this.element.classList.contains('is-paused')
  }

  get hasLightbox() {
    return this.element.classList.contains('media-player--has-lightbox')
  }

  initVideoCustomUI() {
    this.initSubtitles()
    this.updateProgress()
  }

  toggleCustomUIContols() {
    if (this.videoTarget.controls === true) {
      // Hide custom UI controls when default browser controls are present
      clearTimeout(this.touchAutohideControlsTimeout)
      this.element.classList.remove('cursor-hidden')
      this.element.classList.add('controls-hidden')
    } else {
      this.hideDefaultSubtitles()
    }
  }

  handleVideoMouseEnter() {
    if (!this.isPlaying) return

    this.element.classList.remove('controls-hidden')
  }

  handleVideoMouseLeave() {
    if (!this.isPlaying) return

    this.element.classList.add('controls-hidden')
  }

  touchAutohideControls() {
    if (!this.videoTarget.controls) {
      clearTimeout(this.touchAutohideControlsTimeout)
      this.element.classList.remove('controls-hidden', 'cursor-hidden')

      this.touchAutohideControlsTimeout = setTimeout(() => {
        if (this.videoTarget.paused || this.element.classList.contains('is-fullscreen')) {
          this.touchAutohideControls()
        } else {
          clearTimeout(this.touchAutohideControlsTimeout)
          this.element.classList.add('controls-hidden', 'cursor-hidden')
        }
      }, TOUCH_CONTROLS_AUTOHIDE_DURATION_MS)
    }
  }

  hideDefaultSubtitles() {
    Array.from(this.videoTarget.textTracks).forEach((track) => {
      track.mode = 'hidden'
    })
  }

  initSubtitles() {
    this.hideDefaultSubtitles()
    Array.from(this.videoTarget.textTracks).forEach((track) => {
      track.addEventListener('cuechange', (e) => {
        if (e.target.activeCues.length > 0) {
          this.subtitlesTarget.textContent = e.target.activeCues[0].text
          this.subtitlesTarget.classList.add('is-visible')
        } else {
          this.subtitlesTarget.classList.remove('is-visible')
        }
      })
    })
  }

  toggleComponentClasses() {
    // Toggle 'is-paused' class
    this.element.classList.toggle('is-paused', this.videoTarget.paused)

    // Toggle 'is-muted' class
    this.element.classList.toggle('is-muted', this.videoTarget.muted)

    // Toggle 'is-fullscreen' class
    this.element.classList.toggle(
      'is-fullscreen',
      document.fullscreenElement || document.webkitFullscreenElement || false
    )

    this.toggleCustomUIContols()
  }

  stopVideo() {
    this.videoTarget.pause()
    this.videoTarget.currentTime = 0
  }

  togglePlay() {
    const mediaToStop = document.querySelectorAll('.halt-if-another-video-is-playing')
    mediaToStop.forEach((element) => {
      const video = element.querySelector('video')

      if (this.videoTarget != video) {
        video.pause()
        element.classList.remove('controls-hidden')
      }
    })

    if (this.videoTarget.paused === true) {
      // Play the video
      this.videoTarget.play()
      this.largePlayTarget.classList.add('is-hidden')
    } else {
      // Pause the video
      this.videoTarget.pause()
    }

    this.toggleComponentClasses()
    this.handleVideoMouseEnter()
  }

  toggleVolume() {
    this.videoTarget.muted = !this.videoTarget.muted

    this.toggleComponentClasses()

    // Show subtitles when video is muted, hide subtitles when volume is turned on again
    this.element.classList.toggle('is-subtitles-on', this.videoTarget.muted)
  }

  toggleSubtitles() {
    this.element.classList.toggle('is-subtitles-on')
  }

  toggleFullscreen() {
    this.element.classList.toggle('is-fullscreen')
    const fullscreenElement = /iP(ad|od|hone)/i.test(window.navigator.userAgent) ? this.videoTarget : this.element
    toggleFullscreen(fullscreenElement)
  }

  controlVideoWithKeyboard(event) {
    if ((this.hasLightbox && !this.isActive) || this.isStopped) return

    if (document.activeElement === document.body && event.keyCode === KEYS.space) {
      event.preventDefault()
      this.togglePlay()
    }

    if (this.hasLightbox && event.keyCode === KEYS.escape) {
      this.closeVideo()
    }

    // Total hack to prevent the browser from cycling through the tab-able elements and "scroll"
    // the page, ending up messing with the video alignment
    if (this.hasLightbox && this.isActive && event.keyCode === KEYS.tab) {
      this.closeButtonTarget.focus()
    }
  }

  // Progress
  updateProgress() {
    // Calculate current progress
    const value = (100 / this.videoTarget.duration) * this.videoTarget.currentTime

    // Update the slider value
    this.progressBarFillTarget.style.width = `${value}%`

    // Update timer
    this.timeTarget.textContent = `${this.constructor.formatTime(
      this.videoTarget.currentTime
    )} / ${this.constructor.formatTime(this.videoTarget.duration)}`

    // Dispatching a custom updateProgress event
    const customEventDetail = {
      duration: this.videoTarget.duration,
      currentTime: this.videoTarget.currentTime,
    }
    this.dispatch('updateProgress', customEventDetail)

    // Toggle UI classes
    this.toggleComponentClasses()
  }

  handleProgressMouseDown(event) {
    this.isSeeking = true

    this.element.style.setProperty('--progress-bar-transition-duration', '0ms')

    if (!this.isStopped) {
      this.togglePlay()
    }
    this.seekVideo(event)
  }

  handleProgressMouseUp() {
    this.isSeeking = false

    this.element.style.setProperty('--progress-bar-transition-duration', this.originalProgressBarTransitionDuration)
    this.togglePlay()
  }

  seekVideo(event) {
    if (!this.isSeeking) return

    const progressBarBounds = event.target.getBoundingClientRect()
    const x = event.clientX - progressBarBounds.left

    const percentage = x / this.progressBarTarget.offsetWidth
    const jumpTo = percentage * this.videoTarget.duration

    this.videoTarget.currentTime = jumpTo
    this.updateProgress()
  }

  closeVideo() {
    this.element.classList.remove(this.activeClass)
    this.element.classList.remove('controls-hidden')
    this.stopVideo()
    enableScroll()
  }

  onCustomPlayClick(event) {
    this.element.classList.add(this.activeClass)
    this.togglePlay()
    disableScroll()

    this.centerVideo()
    this.dispatch('customVideoPlayButtonClick', event)
  }

  centerVideo() {
    if (this.isActive && this.hasLightbox) {
      // Vertical alignment — relies on scroll
      const videoPositionY = this.element.getBoundingClientRect().top + window.scrollY
      const videoHeight = this.videoTarget.offsetHeight
      const windowHeight = window.innerHeight
      const positionY = videoPositionY - (windowHeight - videoHeight) / 2

      window.scrollTo({ top: positionY, behavior: 'smooth' })

      if (mediaFrom(BREAKPOINTS.VIEWPORT_M)) {
        // Horizontal alignment — relies on translateX()
        const videoPositionX = this.element.getBoundingClientRect().left + window.scrollX
        const videoWidth = this.videoTarget.clientWidth
        const windowWidth = window.innerWidth
        let positionX = `${(videoPositionX - (windowWidth - videoWidth) / 2) * -1}px`

        // The following may be too complex for what it needs, but this was done right on top of our
        // deadline and I'm not willing to touch this anymore before launch <PedroPinto>
        const isReverse = this.element.classList.contains('media-player--reverse')
        if (isReverse) {
          const wrapperWidth = this.element.offsetWidth
          positionX = `${videoWidth - wrapperWidth - videoPositionX + (windowWidth - videoWidth) / 2}px`
        }

        this.element.style.setProperty('--active-video-offset-x', positionX)
      }
    }
  }

  handleVideoEnded() {
    this.element.classList.add(this.endedClass, this.stoppedClass)
  }

  handleVideoPlay() {
    this.element.classList.remove(this.endedClass, this.stoppedClass)
  }

  handleVideoClick() {
    if (this.isStopped) return

    this.togglePlay()
  }
}
