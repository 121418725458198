//
// Website configuration
//

const WEB_APP_URL = '/c'

/**
 * A group of link rules that should be matched for tracking link clicks
 * @typedef {Object} LinkRuleGroup
 * @property {string[]} hostnames                                - exact hostnames to match
 * @property {Object.<string, Array<(string|RegExp)>} categories - category name: pathnames as exact strings or regex patterns (w/o trailing slash)
 */

const PRICING = {
  SUBSCRIPTION: {
    MONTHLY: 12,
    YEARLY: 120,
  },
  // License prices are fetched from the backend through the License class with fetchLicensePricesAndDates(),
  // and thus not part of static configuration
}

export { PRICING, WEB_APP_URL }
