import { Controller } from 'stimulus'
import upperFirst from 'lodash/upperFirst'
import MediaDialog from './media-dialog'

export default class MediaDialogController extends Controller {
  static optionDataKeys = ['aspect-ratio', 'width', 'height']

  static values = {
    aspectRatio: String,
    width: String,
    height: String,
  }

  /**
   * Open a MediaDialog, usually on click
   * @param {MouseEvent} [event]
   */
  open(event) {
    try {
      this.dialog = new MediaDialog(this.element.href, { options: this.options, shouldAutoShow: true })
      if (event) event.preventDefault()
    } catch (error) {
      // Fail quietly
    }
  }

  /**
   * Get object of data-media-dialog-*-value properties
   * @readonly
   * @returns {Object.<string,string>}
   */
  get options() {
    return Object.keys(MediaDialogController.values).reduce((obj, key) => {
      return this[`has${upperFirst(key)}Value`] ? Object.assign(obj, { [key]: this[`${key}Value`] }) : obj
    }, {})
  }
}
