import { Controller } from 'stimulus'
import Dialog from './dialog'

export default class DialogController extends Controller {
  static values = {
    href: String,
  }

  /**
   * Open a dialog
   * @param {Event} event
   */
  open(event) {
    if (event) event.preventDefault()

    fetch(this.hrefValue)
      .then((response) => {
        if (!response.ok) throw Error()
        return response.text()
      })
      .then(function (html) {
        const dialog = new Dialog({ content: html })
        dialog.show()
      })
      .catch(() => {
        // Go to the original URL if anything wrong happened
        window.location.href = /** @type {HTMLAnchorElement} */ (this.element).href
      })
  }
}
