import { Controller } from 'stimulus'

import { PAYMENTS_LICENSES_URL, STRIPE_PUBLIC_KEY } from '../../_js/base/endpoints'
import { isEmail } from '../../_js/base/utils'

const fetchClientSecret = (url, bodyObj) => {
  const body = JSON.stringify(bodyObj)

  return async () => {
    const response = await fetch(url, {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })

    const { secret } = await response.json()

    const newUrl = new URL(window.location.href)
    newUrl.searchParams.set('session', secret)
    window.history.pushState({}, '', newUrl)

    return secret
  }
}

export default class StoreController extends Controller {
  static targets = [
    'buyerEmail',
    'customerEmail',
    'submitButton',
    'step1',
    'heading',
    'subHeading',
    'serial',
    'invoiceNumber',
    'navStep2',
    'checkbox',
  ]

  static classes = ['error', 'loading', 'active']

  static steps = {
    step1: {},
    step2: {},
  }

  currentStep = StoreController.steps.step1

  async resumePreviousSession(secret) {
    const stripe = await Stripe(STRIPE_PUBLIC_KEY)

    this.currentStep = StoreController.steps.step2
    this.updateContent()

    this.element.classList.add(this.loadingClass)
    const checkout = await stripe.initEmbeddedCheckout({ fetchClientSecret: () => secret })
    this.element.classList.remove(this.loadingClass)
    this.element.classList.add(this.activeClass)
    checkout.mount('#checkout')
  }

  async purchaseSession(customerEmail, buyerEmail) {
    const stripe = await Stripe(STRIPE_PUBLIC_KEY)

    const url = `${PAYMENTS_LICENSES_URL}/1/checkout/session/new_license`

    if (!buyerEmail) {
      buyerEmail = customerEmail
      customerEmail = null
    }

    const body = { email: buyerEmail, on_behalf_of: customerEmail }

    this.currentStep = StoreController.steps.step2
    this.updateContent()

    this.element.classList.add(this.loadingClass)

    try {
      const checkout = await stripe.initEmbeddedCheckout({ fetchClientSecret: fetchClientSecret(url, body) })
      checkout.mount('#checkout')
    } catch (e) {
      this.element.classList.add(this.errorClass)
    } finally {
      this.element.classList.remove(this.loadingClass)
      this.element.classList.add(this.activeClass)
    }
  }

  async addSeatsSession(serial, licenseRef, seats, buyer) {
    const stripe = await Stripe(STRIPE_PUBLIC_KEY)

    const url = `${PAYMENTS_LICENSES_URL}/1/checkout/session/add_seats`
    const bodyObject = { seats: seats }

    if (serial) {
      bodyObject.serial = serial
    }

    if (licenseRef) {
      bodyObject.license_ref = licenseRef
    }

    if (buyer) {
      bodyObject.buyer = buyer
    }

    this.currentStep = StoreController.steps.step2
    this.updateContent()

    this.element.classList.add(this.loadingClass)
    const checkout = await stripe.initEmbeddedCheckout({ fetchClientSecret: fetchClientSecret(url, bodyObject) })
    this.element.classList.remove(this.loadingClass)
    this.element.classList.add(this.activeClass)
    checkout.mount('#checkout')
  }

  async renewSession(serial, licenseRef, seats, buyer) {
    const stripe = await Stripe(STRIPE_PUBLIC_KEY)

    const url = `${PAYMENTS_LICENSES_URL}/1/checkout/session/renew_license`

    const body = { seats: seats }

    if (serial) {
      body.serial = serial
    }

    if (licenseRef) {
      body.license_ref = licenseRef
    }

    if (buyer) {
      body.buyer = buyer
    }

    this.currentStep = StoreController.steps.step2
    this.updateContent()

    this.element.classList.add(this.loadingClass)
    const checkout = await stripe.initEmbeddedCheckout({ fetchClientSecret: fetchClientSecret(url, body) })
    this.element.classList.remove(this.loadingClass)
    this.element.classList.add(this.activeClass)

    checkout.mount('#checkout')
  }

  updateContent() {
    if (this.currentStep === StoreController.steps.step1) {
      this.step1.classList.remove('is-hidden')
    } else {
      this.step1.classList.add('is-hidden')
      this.navStep2Target.classList.add('store__list-item--is-active')
    }
  }

  async connect() {
    const urlParams = new URLSearchParams(window.location.search)

    this.currentStep = StoreController.steps.step1
    this.updateContent()

    const license = urlParams.get('license')
    const licenseID = urlParams.get('licenseID')
    const seats = urlParams.get('seats')
    const operation = urlParams.get('operation')
    const buyer = urlParams.get('buyer')

    const session = urlParams.get('session')
    if (session) {
      await this.resumePreviousSession(session)
      return
    }

    if (license || licenseID) {
      switch (operation) {
        case 'add-seats':
          await this.addSeatsSession(license, licenseID, parseInt(seats), buyer)
          return
        case 'renew':
          await this.renewSession(license, licenseID, parseInt(seats), buyer)
          return

        default:
          break
      }

      return
    }

    // we're not resuming a previous session or going directly to an add/renew session,
    // so we need to show the email form
    this.step1.classList.remove('is-hidden')

    // and we want the email input ready for the user to type
    this.customerEmail.focus()

    this.validate()
  }

  async checkboxChange(event) {
    const { target } = event
    const { checked } = target

    if (checked) {
      this.buyerEmail.style.display = 'block'
    } else {
      this.buyerEmail.style.display = 'none'
    }

    this.validate()
  }

  async click(event) {
    event.preventDefault()

    if (this.submitButton.disabled) {
      return
    }

    await this.purchaseSession(this.customerEmail.value, this.buyerEmail.value)
  }

  validate() {
    this.submitButton.disabled = !isEmail(this.customerEmail.value)

    if (this.checkboxTarget.checked) {
      this.submitButton.disabled = this.submitButton.disabled || !isEmail(this.buyerEmail.value)
      this.buyerEmail.style.display = 'block'
    } else {
      this.buyerEmail.style.display = 'none'
    }
  }

  get buyerEmail() {
    return this.buyerEmailTarget
  }

  get submitButton() {
    return this.submitButtonTarget
  }

  get step1() {
    return this.step1Target
  }

  get heading() {
    return this.headingTarget
  }

  get subHeading() {
    return this.subHeadingTarget
  }

  get customerEmail() {
    return this.customerEmailTarget
  }
}
