import { Controller } from 'stimulus'
import { enableScroll, disableScroll } from '../utils/prevent-scroll'
import mediaFrom from '../utils/media-from'
import { BREAKPOINTS } from '../base/consts'

export default class BottomDrawerController extends Controller {
  static targets = ['bottomDrawer', 'bottomDrawerCancel', 'bottomDrawerContent', 'bottomDrawerToggle']

  static values = { bottomDrawerHiddenClass: String }

  bottomDrawerActiveClass = 'bottom-drawer--is-active'

  isDrawerOpen

  toggleBodyScroll() {
    if (this.isDrawerOpen) {
      disableScroll(document.body)
    } else {
      enableScroll(document.body)
    }
  }

  handleDrawerClicks(event) {
    if ((event.target.href && event.target.href.includes('#')) || event.target === this.bottomDrawerCancelTarget) {
      this.toggleDrawer()
    }
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen
    this.bottomDrawerTarget.classList.toggle(this.bottomDrawerActiveClass)
    this.toggleBodyScroll()
  }

  handleGlobalClicks({ target }) {
    if (target === this.bottomDrawerToggleTarget) {
      this.toggleDrawer()
    } else {
      this.smartCloseDrawer(target)
    }
  }

  smartCloseDrawer(target) {
    const isClickOnBackdrop = target.closest('.bottom-drawer__drawer') === target
    const isClickOnContent = this.bottomDrawerContentTarget.contains(target)

    if (mediaFrom(BREAKPOINTS.VIEWPORT_M) && this.isDrawerOpen && !isClickOnContent) {
      this.toggleDrawer()
    } else if (isClickOnBackdrop) {
      this.toggleDrawer()
    }
  }
}
