export default function toggleFullscreen(element) {
  if (element.requestFullscreen && document.fullscreenEnabled) {
    // if fullscreen is supported
    if (!document.fullscreenElement) {
      element.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  } else if (/iP(ad|od|hone)/i.test(window.navigator.userAgent)) {
    // for iOS
    // Currenly only works with <video> element
    // which opens up a separate view for the fullscreen video with native controls
    element.webkitEnterFullScreen()
  } else if (element.webkitRequestFullscreen && document.webkitFullscreenEnabled) {
    // for Safari desktop
    if (!document.webkitFullscreenElement) {
      element.webkitRequestFullscreen()
    } else {
      document.webkitExitFullscreen()
    }
  }
}
