import Splitting from 'splitting'
import { Controller } from 'stimulus'

export default class EntranceController extends Controller {
  static values = { split: String, isIntersecting: Boolean, threshold: Number }

  static viewportEntranceObserver

  connect() {
    EntranceController.viewportEntranceObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Data property for Stimulus use, classes for use in CSS as animation hooks
          entry.target.dataset.entranceIsIntersectingValue = entry.isIntersecting
        })
      },
      {
        threshold: this.thresholdValue,
      }
    )

    EntranceController.viewportEntranceObserver.observe(this.element)
  }

  isIntersectingValueChanged(isIntersecting) {
    // Only split text at the last moment, to viewport-dependent splits,
    // such as 'lines' reflect the currently visible flow of content or text
    if (isIntersecting && this.hasSplitValue) {
      this.split()
    }

    // Apply classes
    if (this.isIntersectingValue) {
      this.element.classList.add('is-visible', 'was-shown')
      this.element.classList.replace('was-visible', 'is-visible')
    } else {
      this.element.classList.replace('is-visible', 'was-visible')
    }

    // Autoplay video element in viewport
    if (this.element.tagName === 'VIDEO' && this.element.dataset.autoplayInViewport === 'true') {
      if (isIntersecting) {
        this.element.play()
      } else {
        this.element.pause()
      }
    }
  }

  split() {
    this.splittingResults = Splitting({ target: this.element, by: this.splitValue })
  }
}
