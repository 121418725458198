import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../base/consts'
import mediaFrom from '../utils/media-from'

const MOVING_CLASS = 'artboard--moving'
const SELECTED_CLASS = 'artboard--selected'
const ANNOTATION_CLASS = 'annotation__icon'

export default class extends Controller {
  eventMouseMove = this.handleMouseMove.bind(this)

  eventMouseUp = this.handleMouseUp.bind(this)

  canMove = false

  connect() {
    this.classApplied = true

    document.addEventListener('mousedown', (event) => {
      if (this.classApplied && !this.element.contains(event.target)) {
        this.element.classList.remove(SELECTED_CLASS)
        this.classApplied = false
      }
    })

    window.addEventListener('resize', () => {
      this.element.style.left = ''
      this.element.style.right = ''
      this.element.style.top = ''
    })
  }

  handleMouseDown(event) {
    if (mediaFrom(BREAKPOINTS.VIEWPORT_M)) {
      if (event.button == 0) {
        if (event.target.classList.contains(ANNOTATION_CLASS)) {
          return
        }
        this.canMove = true
        this.element.classList.add(SELECTED_CLASS)
        this.classApplied = true
        document.querySelectorAll('.artboard--selected').forEach(
          function (e) {
            if (e !== this.element) {
              e.classList.remove(SELECTED_CLASS)
            }
          }.bind(this)
        )
        this.initialMousePosition = { x: event.clientX, y: event.clientY }
        this.initialElementPosition = { x: this.element.offsetLeft, y: this.element.offsetTop }

        document.addEventListener('mousemove', this.eventMouseMove)
        document.addEventListener('mouseup', this.eventMouseUp)
      }
    }
  }

  handleMouseMove(event) {
    if (this.canMove) {
      event.preventDefault()

      if (!this.element.classList.contains(MOVING_CLASS)) {
        this.element.classList.add(MOVING_CLASS)
      }

      const deltaX = event.clientX - this.initialMousePosition.x
      const deltaY = event.clientY - this.initialMousePosition.y

      this.element.style.left = `${this.initialElementPosition.x + deltaX}px`
      this.element.style.right = 'auto'
      this.element.style.top = `${this.initialElementPosition.y + deltaY}px`
    }
  }

  handleMouseUp() {
    this.canMove = false
    this.element.classList.remove(MOVING_CLASS)
    document.removeEventListener('mousemove', this.eventMouseMove)
    document.removeEventListener('mouseup', this.eventMouseUp)
  }

  disconnect() {
    this.element.removeEventListener('mousedown', this.handleMouseDown)
  }
}
