import { Controller } from 'stimulus'
import License from '../../_js/classes/license'

export default class LicenseLinkController extends Controller {
  /** @type {LicenseLinkController[]} */
  static instances = []

  connect() {
    LicenseLinkController.instances.push(this)
  }

  disconnect() {
    LicenseLinkController.instances = LicenseLinkController.instances.filter((instance) => instance !== this)
  }

  /**
   * Update all instances with a new license
   * @param {?License} license
   */
  static updateAll(license) {
    this.instances.forEach((instance) => instance.update(license))
  }

  /**
   * Update the link's URL with a new license (or none)
   * @param {?License} license
   */
  update(license) {
    const linkParams = new URLSearchParams(this.element.search)
    if (license && license.urlParam) {
      for (const key in license.urlParam) {
        linkParams.set(key, license.urlParam[key])
      }
    } else {
      License.QUERY_STRING_KEYS.forEach((key) => linkParams.delete(key))
    }
    this.element.search = linkParams.toString()
  }
}
