import { Controller } from 'stimulus'
import Swiper, { Pagination, Navigation, Mousewheel } from 'swiper'

export default class FeatureCardsSlider extends Controller {
  static targets = ['swiper', 'pagination', 'swiperSlide']

  numberOfSlides = this.swiperSlideTargets.length

  connect() {
    const numberOfSlides = this.swiperSlideTargets.length

    return new Swiper(this.swiperTarget, {
      slidesPerView: 1,
      spaceBetween: 24,
      modules: [Pagination, Navigation, Mousewheel],
      loop: numberOfSlides > 1,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      on: {
        init: function () {
          // Check if navigation is enabled and add a class
          if (numberOfSlides > 3) {
            this.el.classList.add('swiper--has-navigation')
          }
        },
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          loop: numberOfSlides > 2,
        },
        1024: {
          slidesPerView: 3,
          loop: numberOfSlides > 3,
        },
        1220: {
          slidesPerView: 3,
          allowTouchMove: false,
          loop: numberOfSlides > 3,
          navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
          mousewheel: {
            enabled: true,
            forceToAxis: true,
          },
        },
      },
    })
  }
}
