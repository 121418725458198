import { Controller } from 'stimulus'

export default class ActivatableController extends Controller {
  static targets = ['stateful']

  static values = {
    active: Boolean,
    deactivateSiblings: Boolean,
  }

  activeValueChanged() {
    this.applyClassesOn(this.element)
    this.statefulTargets.forEach((target) => {
      target.setAttribute('aria-expanded', this.activeValue)
      // Apply class on the stateful elements too, for easier styling (e.g. buttons)
      this.applyClassesOn(target)
    })
  }

  /**
   * Apply active/inactive classes on an element based on controller state
   * @param {Element} el - element to apply classes on
   */
  applyClassesOn(el) {
    el.classList.toggle('is-active', this.activeValue)
    el.classList.toggle('is-inactive', !this.activeValue)
  }

  // eslint-disable-next-line class-methods-use-this
  deactivateSiblings(event) {
    const siblings = Array.from(event.currentTarget.parentNode.parentNode.children)

    siblings.forEach((sibling) => {
      if (sibling !== event.currentTarget.parentNode) {
        sibling.setAttribute('data-activatable-active-value', false)
        sibling.activeValue = false
      }
    })
  }

  /**
   * Activates the component
   * @returns {this}
   */
  activate(event) {
    if (event) event.preventDefault()
    this.activeValue = true
    return this
  }

  /**
   * Deactivates the component
   * @returns {this}
   */
  deactivate(event) {
    if (event) event.preventDefault()
    this.activeValue = false
    return this
  }

  /**
   * Toggles the component
   * @returns {this}
   */
  toggle(event) {
    if (event) event.preventDefault()
    if (this.deactivateSiblingsValue) {
      this.deactivateSiblings(event)
    }
    if (this.activeValue) {
      this.deactivate()
    } else {
      this.activate()
    }
    return this
  }
}
