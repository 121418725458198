import { Controller } from 'stimulus'

export default class Referral extends Controller {
  static classes = ['iphone']

  static REFERRALS = {
    IPHONE: 'sketch',
  }

  static SESSION_OBJECTS = {
    IPHONE: 'isForInAppPresentation',
  }

  currentReferral = ''

  connect() {
    this.saveReferralInSession()
    this.applyReferralClass()
  }

  saveReferralInSession() {
    const urlParams = new URLSearchParams(window.location.search)
    const referral = urlParams.get('source')

    if (!referral) return

    this.currentReferral = referral
    window.sessionStorage.setItem(Referral.SESSION_OBJECTS.IPHONE, referral === Referral.REFERRALS.IPHONE)
  }

  applyReferralClass() {
    if (
      this.currentReferral === Referral.REFERRALS.IPHONE ||
      !!window.sessionStorage.getItem(Referral.SESSION_OBJECTS.IPHONE)
    ) {
      this.element.classList.add(this.iphoneClass)
    }
  }
}
