import { Controller } from 'stimulus'

export default class DocsTabsController extends Controller {
  static targets = ['tabButton', 'tabContent']

  static classes = ['activeTab', 'activeContent']

  connect() {
    const defaultTab = this.data.get('default-tab')

    if (defaultTab) {
      this.showTab(defaultTab)
    } else {
      this.showTab(this.tabButtonTargets[0].getAttribute('data-tab'))
    }
  }

  switchTab(event) {
    event.preventDefault()
    const tab = event.currentTarget.getAttribute('data-tab')
    this.showTab(tab)
  }

  showTab(tab) {
    this.tabButtonTargets.forEach((button) => {
      button.classList.remove(this.activeTabClass)
    })
    this.tabContentTargets.forEach((content) => {
      if (content.getAttribute('data-tab') === tab) {
        content.classList.add(this.activeContentClass)
      } else {
        content.classList.remove(this.activeContentClass)
      }
    })
    const activeButton = this.element.querySelector(`[data-tab="${tab}"]`)
    activeButton.classList.add(this.activeTabClass)
  }
}
