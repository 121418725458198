/* eslint-disable max-classes-per-file */
import { Controller } from 'stimulus'

export default class DownloadsController extends Controller {
  static targets = ['downloadLink']

  hasDownloaded = false

  connect() {
    const downloadLink = this.downloadLinkTarget
    const videoPlayer = document.querySelector('.video-player')

    if (GLOBALS.ENV !== 'development') {
      videoPlayer.addEventListener(
        'play',
        () => {
          // Give it a small timeout to make sure video loads and then start the download
          window.setTimeout(() => downloadLink.click(), 1000)
        },
        { once: true }
      )
    }
  }
}
