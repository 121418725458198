import { Controller } from 'stimulus'
import Swiper, { Navigation, Autoplay, Mousewheel } from 'swiper'
import debounce from 'lodash/debounce'
import mediaFrom from '../../_js/utils/media-from'
import { BREAKPOINTS } from '../../_js/base/consts'
import shuffleChildren from '../../_js/utils/shuffle-children'

export default class HomeController extends Controller {
  static targets = ['canvas', 'testimonials', 'prototyping', 'testimonialControls', 'testimonialItems']

  testimonialsSlider = ''

  prototypeSlider = ''

  tiltAnimationCompleted = false

  connect() {
    shuffleChildren(this.testimonialItemsTarget)

    // Sliders

    Swiper.use(Navigation)
    Swiper.use(Autoplay)
    Swiper.use(Mousewheel)

    this.testimonialsSlider = new Swiper(this.testimonialsTarget, {
      spaceBetween: 24,
      centeredSlides: true,
      allowClick: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: false,
      allowTouchMove: true,
      effect: 'slide',
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 'auto',
        },
        1024: {
          slidesPerView: 'auto',
          allowTouchMove: false,
        },
      },
      mousewheel: {
        forceToAxis: true,
      },
    })

    this.testimonialsSlider.init()

    this.testimonialsSlider.on('init', this.setArrowMargin())
    this.testimonialsSlider.on(
      'resize',
      debounce(() => this.setArrowMargin())
    )

    this.prototypeSlider = new Swiper(this.prototypingTarget, {
      spaceBetween: 24,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      mousewheel: {
        forceToAxis: true,
      },
      effect: 'slide',
    })

    this.prototypeSlider.init()

    // Animations

    // if (this.hasCanvasTarget) {
    //   this.canvasTarget.addEventListener('animationend', () => {
    //     this.tiltAnimationCompleted = true
    //   })
    // }

    window.addEventListener('scroll', this.onPageScroll.bind(this))
    this.onPageScroll() // Initial call to set the correct rotation on load
  }

  onPageScroll() {
    if (this.hasCanvasTarget) {
      const rect = this.canvasTarget.getBoundingClientRect()

      // If we're already past the window, stop the calculations
      if (rect.top < 0) return

      const windowHeight = window.innerHeight
      const threshold = 200

      // Calculate the percentage of the canvas that is in view
      let inViewPercentage = 0

      if (rect.top <= threshold) {
        // Once the element is at or beyond the threshold, set inViewPercentage to 1
        inViewPercentage = 1
      } else {
        // Smoothly increase inViewPercentage as the element approaches the threshold
        inViewPercentage = (windowHeight - rect.top) / (windowHeight - threshold)
      }

      // Calculate the new rotateX value based on the inViewPercentage
      const rotateX = 3 - 3 * inViewPercentage

      // Apply the new rotateX value smoothly
      document.documentElement.style.setProperty('--rotationAmount', `${rotateX}deg`)
    }
  }

  setArrowMargin() {
    const testimonialControls = this.testimonialControlsTarget

    if (mediaFrom(BREAKPOINTS.VIEWPORT_M)) {
      const activeSlideElement = this.testimonialsSlider.slides[this.testimonialsSlider.realIndex]

      const rect = activeSlideElement.getBoundingClientRect()

      const distanceFromRight = window.innerWidth - rect.right

      testimonialControls.style.marginRight = distanceFromRight + 'px'
      testimonialControls.classList.remove('hidden')
    } else {
      testimonialControls.style.marginRight = '0'
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.onPageScroll.bind(this))
  }
}
