/**
 * Manage state class attributes in any element
 * @export
 * @param {(Element|Element[]|HTMLCollection|NodeListOf<Element>)} elementOrElements
 */
export default function ElementState(elementOrElements) {
  /** @type {(Element[]|HTMLCollection|NodeListOf<Element>)} */
  const elements = elementOrElements instanceof Element ? [elementOrElements] : elementOrElements

  /**
   * Callback for making changes in each element
   * @callback elementCallback
   * @param {Element} element - element in which to operate
   */

  /**
   * Iterate through all elements
   * @param {elementCallback} callback
   */
  const forEachElement = function (callback) {
    for (let i = 0; i < elements.length; i++) {
      callback(elements[i])
    }
  }

  /**
   * Set a state class
   * @param {String} newClass - the class to apply
   * @param {Number} [duration] - how long to apply the class for, in miliseconds
   * @returns {Promise}
   */
  async function set(newClass, duration = null) {
    return new Promise((resolve) => {
      forEachElement((element) => element && element.classList.add(newClass))
      if (duration) {
        window.setTimeout(() => {
          forEachElement((element) => element && element.classList.remove(newClass))
          resolve()
        }, duration)
      } else {
        resolve()
      }
    })
  }

  /**
   * Unset an existing state class
   * @param {String} oldClass - the class to remove
   * @returns {Promise}
   */
  async function unset(oldClass) {
    forEachElement((element) => element && element.classList.remove(oldClass))
    return Promise.resolve()
  }

  /**
   * Swap a state class for another for another
   * Note: unlike classList.replace(), the original class doesn't need to be set
   * @param {String} oldClass - the class to remove
   * @param {String} newClass - the class to apply
   * @returns {Promise}
   */
  async function swap(oldClass, newClass) {
    await unset(oldClass)
    await set(newClass)
    return Promise.resolve()
  }

  return {
    set,
    unset,
    swap,
  }
}
