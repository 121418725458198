import { Controller } from 'stimulus'

export default class BtcHeaderController extends Controller {
  static targets = ['btcMenu', 'btcHeader', 'btcBackdropFilter', 'btcSearch', 'btcSearchInput']

  startingOffset = 0

  isSmall = false

  connect() {
    window.addEventListener('scroll', () => this.handleScroll())
  }

  handleScroll() {
    const doc = document.documentElement
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

    if (top == 0) {
      this.btcHeaderTarget.classList.remove('btc-header--small')
      return
    }

    if (this.btcHeaderTarget.offsetTop > 0) {
      this.btcHeaderTarget.classList.add('btc-header--small')
    } else {
      this.btcHeaderTarget.classList.remove('btc-header--small')
    }
  }

  toggleSearch() {
    this.btcSearchTarget.classList.toggle('is-open')
    this.btcSearchInputTarget.focus()
    this.btcHeaderTarget.classList.toggle('search-is-open')
    this.btcBackdropFilterTarget.classList.toggle('search-is-open')

    if (this.btcSearchTarget.classList.contains('is-open')) {
      document.body.style.overflow = 'hidden'
      if (this.btcMenuTarget.classList.contains('is-open')) {
        this.toggleMenu()
      }
    } else {
      document.body.style.overflow = 'initial'
    }
  }

  toggleMenu() {
    this.btcMenuTarget.classList.toggle('is-open')
    this.btcHeaderTarget.classList.toggle('menu-is-open')
    this.btcBackdropFilterTarget.classList.toggle('menu-is-open')

    if (this.btcMenuTarget.classList.contains('is-open')) {
      document.body.style.overflow = 'hidden'

      if (this.btcSearchTarget.classList.contains('is-open')) {
        this.toggleSearch()
      }
    } else {
      document.body.style.overflow = 'initial'
    }
  }

  closeOpenMenus() {
    if (this.btcMenuTarget.classList.contains('is-open')) {
      this.toggleMenu()
    }

    if (this.btcSearchTarget.classList.contains('is-open')) {
      this.toggleSearch()
    }
  }
}
