/**
 * Tests whether the Web Storage API is available in the browser
 * From https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * @param {string} type - type of Storage to test (sessionStorage, localStorage)
 * @returns {boolean}
 */
export const storageAvailable = function (type) {
  var storage
  try {
    storage = window[type]
    var x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

/**
 * Tests whether the History API and history.pushState are available
 * From: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/history.js
 * @returns {boolean}
 */
export const historyAvailable = function () {
  // The stock browser on Android 2.2 & 2.3, and 4.0.x returns positive on history support
  // Unfortunately support is really buggy and there is no clean way to detect
  // these bugs, so we fall back to a user agent sniff :(
  var ua = navigator.userAgent

  // Some browsers allow to have empty userAgent.
  // Therefore, we need to check ua before using "indexOf" on it.
  if (!ua) {
    return false
  }

  // We only want Android 2 and 4.0, stock browser, and not Chrome which identifies
  // itself as 'Mobile Safari' as well, nor Windows Phone (issue #1471).
  if (
    (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
    ua.indexOf('Mobile Safari') !== -1 &&
    ua.indexOf('Chrome') === -1 &&
    ua.indexOf('Windows Phone') === -1 &&
    // Since all documents on file:// share an origin, the History apis are
    // blocked there as well
    window.location.protocol !== 'file:'
  ) {
    return false
  }

  // Return the regular check
  return window.history && 'pushState' in window.history
}
