import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../../_js/base/consts'
import mediaFrom from '../../_js/utils/media-from'
import * as userUtils from '../../_js/helpers/user'

export default class extends Controller {
  static targets = ['ribbon']

  static values = {
    visibilityTarget: String,
  }

  resizePageFooter() {
    const footer = document.querySelector('.main-footer')
    if (footer && !mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      const additionalHeight = this.element.offsetHeight
      footer.style.marginBottom = `${additionalHeight}px`
    }
  }

  showRibbon() {
    if (!userUtils.isLoggedIn()) {
      this.element.classList.remove('is-hidden')
    }
  }

  toggleVisibility() {
    const target = document.querySelector(this.visibilityTargetValue)
    if (!target) return

    const targetDistance = target.getBoundingClientRect().y
    this.ribbonTarget.classList.toggle('is-visible', targetDistance <= 0)
  }
}
