const BASE_URL_ENV_MAP = {
  local: 'http://localhost:7070/1/',
  development: 'http://localhost:7070/1/',
  dev: 'https://api.staging.sketch.com/1/',
  staging: 'https://licenses-payments.staging.sketch.com/1/',
  production: 'https://api.prod.sketch.com/1/',
}

const GRAPHQL_URL_ENV_MAP = {
  local: 'https://graphql-dev.sketch.cloud/api/graphql',
  dev: 'https://graphql-dev.sketch.cloud/api/graphql',
  development: 'https://graphql-dev.sketch.cloud/api/graphql',
  staging: 'https://graphql-staging.sketch.cloud/api/graphql',
  production: 'https://graphql.sketch.cloud/api/graphql',
}

const GRAPHQL_PAYMENTS_URL_ENV_MAP = {
  local: 'https://payments.dev.sketch.com/payments/graphql',
  dev: 'https://payments.dev.sketch.com/payments/graphql',
  development: 'https://payments.dev.sketch.com/payments/graphql',
  staging: 'https://payments.staging.sketch.com/payments/graphql',
  production: 'https://payments.prod.sketch.com/payments/graphql',
}

const PAYMENTS_LICENSES_URL_ENV_MAP = {
  local: 'http://localhost:7070',
  development: 'http://localhost:7070',
  dev: 'https://licenses-payments.dev.sketch.com',
  staging: 'https://licenses-payments.staging.sketch.com',
  production: 'https://licenses-payments.prod.sketch.com',
}

const STRIPE_PUBLIC_KEY_MAP = {
  local: 'pk_test_i61Ysu9k3wjXl5CqT0SbHcQ400729JNdg5',
  development: 'pk_test_i61Ysu9k3wjXl5CqT0SbHcQ400729JNdg5',
  dev: 'pk_test_i61Ysu9k3wjXl5CqT0SbHcQ400729JNdg5',
  staging: 'pk_test_rQAwUPfKQ0vJn5ur6v92wDMw',
  production: 'pk_live_tXM9ZuGnt6dLnW8AtSch5l5b',
}

export const GRAPHQL_API_URL = GRAPHQL_URL_ENV_MAP[GLOBALS.ENV]
export const GRAPHQL_PAYMENTS_API_URL = GRAPHQL_PAYMENTS_URL_ENV_MAP[GLOBALS.ENV]
export const BASE_API_URL = BASE_URL_ENV_MAP[GLOBALS.ENV]
export const PAYMENTS_LICENSES_URL = PAYMENTS_LICENSES_URL_ENV_MAP[GLOBALS.ENV]
export const STRIPE_PUBLIC_KEY = STRIPE_PUBLIC_KEY_MAP[GLOBALS.ENV]

export const SIGN_IN_ENDPOINT = GRAPHQL_API_URL.replace('/api/graphql', '/oauth/token')
