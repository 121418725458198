// The initial reveal has a slight delay
const REVEAL_INITIAL_DELAY = 300

/**
 * Reveal node with a delay
 * @param {Element} node
 */
const revealWithDelay = (node) => {
  // Account for 0 as a valid delay
  const customDelay = parseInt(node.dataset.revealDelay, 10)
  const delay = customDelay >= 0 ? customDelay : REVEAL_INITIAL_DELAY

  window.setTimeout(() => node.classList.add('show'), delay)
}

/**
 * Handle one of the reveal elements coming in/out of view
 * @param {IntersectionObserverEntry[]} entries
 */
const revealObserverCallback = function (entries) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      revealWithDelay(entry.target)
    } else if (entry.target.classList.contains('reveal-auto-hide')) {
      entry.target.classList.remove('show')
    }
  })
}

// Nodes are revealed whenever any part of them is in view
const revealNodeObserver =
  'IntersectionObserver' in window ? new IntersectionObserver(revealObserverCallback, { threshold: 0 }) : null

/**
 * Initial the reveal logic for a node
 * If IntersectionObserver isn't supported, show right away, accounting for delay
 * @param {Element} node
 */
const reveal = function (node) {
  if (revealNodeObserver) {
    revealNodeObserver.observe(node)
  } else {
    revealWithDelay(node)
  }
}

export default reveal
