import SnackBar from "../../_components/snackbar/snackbar"
import { historyAvailable } from "./feature-detect"
import { SITE_FUNCTIONALITY_PARAMS } from "../utils/private-data-params"

export const niceDateFromTimestamp = function (timestamp, opts) {
  var options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }

  options = Object.assign(options, opts)
  return new Date(timestamp * 1000).toLocaleDateString(window.navigator.language, options)
}

/**
 * Numeric difference of months between 2 dates
 * To avoid discrepancies, this follows the same logic as the BCAPI:
 * https://github.com/sketch-hq/Website-backend/blob/b9f1c0b86efd1216fe705f26aede11d8efca9f0f/BCAPI/BCLicense.php#L213-L216
 *
 * It returns a max of 12 months
 * @param  {date}    fromDate Starting date
 * @param  {date}    toDate   Ending date
 * @param  {number}  max      Cap the result at a specific max value
 * @return {number}           Amount of months between the 2 dates
 */
export const monthDiff = (fromDate, toDate, max) => {
  const maxDiff = max || Infinity
  return Math.min(maxDiff, Math.round((toDate.getTime() - fromDate.getTime()) / (30 * 86400000)))
}

/**
 * Numeric difference of days between 2 dates
 *
 * @param  {date}    fromDate Starting date
 * @param  {date}    toDate   Ending date
 * @return {number}           Amount of days between the 2 dates
 */
export const dayDiff = (fromDate, toDate) => {
  if (!fromDate || !toDate) return -1
  const oneDayMs = 1000 * 60 * 60 * 24
  return Math.ceil(Math.abs(toDate - fromDate) / oneDayMs)
}

/**
 * Format a number in currency, using the user's language setting
 * @param  {number}  value       Value to be formatted
 * @param  {boolean} hasDecimals Whether to return decimals or not
 * @return {string}              Formatted number, like $120
 */
export const currencyFormatter = (value = 0, hasDecimals = true) => {
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }

  if (!hasDecimals) options.maximumFractionDigits = 0

  const formatter = new Intl.NumberFormat(window.navigator.language, options)
  return formatter.format(value)
}

export const isEmail = function (email) {
  // eslint-disable-next-line no-useless-escape
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/**
 * Check if an element is in the viewport.
 * It's considered to be in the viewport if *any* part of it is currently in the viewport.
 * @param {Element} el - element to check visibility in viewport
 * @returns {boolean}
 */
export const isElementInViewport = function (el) {
  if (!el) return false

  const bounds = el.getBoundingClientRect()
  // Top edge of element must be above the bottom edge of the viewport,
  // AND bottom edge of the element must be below the top edge of the viewport.
  return bounds.top <= window.innerHeight && bounds.bottom > 0
}

export const isTouchDevice = function () {
  return "ontouchstart" in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0
}

export const calculateDistance = function (lat1, lng1, lat2, lng2, unit) {
  //  https://stackoverflow.com/questions/26836146/how-to-sort-array-items-by-longitude-latitude-distance-in-javascripts
  var radlat1 = (Math.PI * lat1) / 180
  var radlat2 = (Math.PI * lat2) / 180
  var theta = lng1 - lng2
  var radtheta = (Math.PI * theta) / 180
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  if (unit == "K" || !unit) {
    dist *= 1.609344
  }
  if (unit == "N") {
    dist *= 0.8684
  }
  return dist
}

/**
 * Call a function when the class attribute changes on a node
 * @param {Node} node - the node to detect class change on
 * @param {Function} callback - callback function
 */
export const onClassChange = function (node, callback) {
  const classObserver = new window.MutationObserver(() => {
    callback(node)
  })
  classObserver.observe(node, {
    attributes: true,
    attributeFilter: ["class"],
  })
}

/**
 * Delay execution of a function until the DOM is ready
 * @param {Function} fn - the function to call when the DOM is ready
 */
export const ready = function (fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    fn()
  } else if (document.attachEvent) {
    // Older IE browsers don't do DOMContentLoaded
    document.attachEvent("onreadystatechange", () => {
      if (document.readyState === "complete") fn()
    })
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

/**
 * Copy text to clipboard and show notification as a SnackBar
 * @param {string} textToCopy - text to copy to the clipboard
 */
export const copyToClipboard = function (textToCopy) {
  return navigator.clipboard.writeText(textToCopy) ? "Text has been copied to clipboard." : "Failed to copy text."
}

/**
 * Serialize an Object as an URL-encoded query string
 * @param {Object.<string,(string|number|boolean)>} params - Object to serialize
 */
export const serializeParams = function (params) {
  return Object.keys(params)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    })
    .join("&")
}

/**
 * @callback ajaxSuccessCallback
 * @param {(string|Object)} result - the response text or JSON
 * @param {number} status          - the response status
 */

/**
 * @callback ajaxErrorCallback
 * @param {number} response - the response status
 * @param {string} result   - the response text
 */

/**
 * Make AJAX requests
 * @param {Object} options                        - Ajax options
 * @param {string} [options.type='GET']           - request type
 * @param {string} [options.url='']               - request URL
 * @param {Object} [options.data=null]            - data for the request
 * @param {ajaxSuccessCallback} [options.success] - callback invoked after request succeeds
 * @param {ajaxErrorCallback} [options.error]     - callback invoked after request fails
 * @returns {XMLHttpRequest}
 */
export const ajax = function ({ url, type = "GET", data = null, ...options }) {
  // create the http request
  const xhr = new window.XMLHttpRequest()

  // Don't modify params
  let _url = url
  let _data = data

  if (_data) {
    if (type == "GET") _url += serializeParams(_data)
    else if (type == "POST") _data = serializeParams(_data)
  }

  xhr.open(type, _url)
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
  xhr.send(_data)

  // deal with the response
  xhr.onreadystatechange = function () {
    var DONE = 4 // done
    var ERROR = 400 // error
    var responseContentType = this.getResponseHeader("content-type")
    var result = null

    if (xhr.readyState === DONE) {
      if (xhr.status != 0 && xhr.status < ERROR && options.success) {
        if (responseContentType == "application/json") {
          result = JSON.parse(xhr.responseText)
        } else {
          result = xhr.responseText
        }
        options.success(result, xhr.status)
      } else if (options.error) {
        options.error(xhr.status, xhr.responseText)
      }
    }
  }

  return xhr
}

/**
 * Get parameters object from query string
 * @param {string} [string] - string to get params from, defaults to current location URL
 * @returns {Object.<string,string>}
 */
export const parseQueryString = function (string) {
  const queryString = typeof string === "string" ? string : window.location.search.substring(1)
  const paramsObject = {}
  const pairs = queryString.split("&")

  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=")
    // If first entry with this name
    if (typeof paramsObject[pair[0]] === "undefined") {
      paramsObject[pair[0]] = decodeURIComponent(pair[1])
      // If second entry with this name
    } else if (typeof paramsObject[pair[0]] === "string") {
      var arr = [paramsObject[pair[0]], decodeURIComponent(pair[1])]
      paramsObject[pair[0]] = arr
      // If third or later entry with this name
    } else {
      paramsObject[pair[0]].push(decodeURIComponent(pair[1]))
    }
  }
  return paramsObject
}

/**
 * Rewrites the page URL removing parameters from the query string without reloading
 * @param  {(...string|string[])} _keys - param names to remove from query string
 */
export const removeQueryStringParams = function (..._keys) {
  if (!historyAvailable() || !("replaceState" in window.history)) return
  const keys = _keys.flat()
  const params = new URLSearchParams(window.location.search)
  keys.forEach((k) => params.delete(k))

  let newPath = window.location.pathname
  if (params.toString() !== "") newPath += "?" + params.toString()
  if (!["", "#"].includes(window.location.hash)) newPath += window.location.hash

  window.history.replaceState(null, "", newPath)
}

//
// based on http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
//
export const numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/**
 *
 * @param {HTMLElement} element
 * @param {Object.<string,any>} options
 * @param {string} [options.visibleValue=block] - what to consider visible
 * @param {boolean} [force] - make the toggling one-way: `true` shows it, `false` hides it
 */
export const toggleDisplay = function (element, { visibleValue = "block", force } = {}) {
  const elementIsVisible = window.getComputedStyle(element).display === visibleValue
  if (elementIsVisible || force === false) {
    element.style.display = "none"
  } else if (!elementIsVisible || force === true) {
    element.style.display = visibleValue
  }
}

/**
 * Prefixes a list of BEM-style modifier strings with its base class
 *
 * @example
 * // returns ['box--wide', 'box--large']
 * bemPrefixModifiers('box', ['wide', 'large'])
 * bemPrefixModifiers('box', 'wide large')
 *
 * @param {string}              base                              - the base string
 * @param {(string[]|string)}   [modifiers=[]]                    - the list of modifiers, as an array or space-separated string
 * @param {Object.<string,any>} [options]                         - options
 * @param {string}              [options.separator=--]            - modifier separator
 * @param {boolean}             [options.includeBase=false] - whether return should include the base
 * @param {boolean}             [options.asString=false]          - return string instead of array
 * @returns {(string[]|string)}
 */
export const bemPrefixModifiers = function (
  base,
  modifiers = [],
  { separator = "--", includeBase = false, asString = false } = {}
) {
  const _base = base.trim(" ")
  if (/\s/.test(_base)) throw new Error("base must be a single class name and can’t contain spaces")

  /** @type {string[]} */
  const modifiersArray = Array.isArray(modifiers) ? modifiers : modifiers.trim().split(/\s+/)
  const prefixedModifiersArray = modifiersArray.map((_mod) => _base + separator + _mod)
  const returnArray = includeBase ? [base, ...prefixedModifiersArray] : prefixedModifiersArray

  return asString ? returnArray.join(" ") : returnArray
}

export const forceReflow = async function () {
  return new Promise((resolve) => {
    window.requestAnimationFrame(() => window.setTimeout(resolve))
  })
}

/**
 * Get a list of the private parameters present in the current URL
 * @param  {string[]} params List of private parameters to watch out for
 * @return {string[]} List of private parameters found in current URL
 */
export const getPrivateParamsFromLocation = (params) => {
  const locationParams = Object.keys(parseQueryString())
  return locationParams.filter((param) => params.indexOf(param) >= 0)
}

/**
 * Removes private parameters from the provided URL
 * @return {string} Current location without any private params
 */
export const getLocationWithoutPrivateParams = () => {
  const existingPrivateParams = getPrivateParamsFromLocation(SITE_FUNCTIONALITY_PARAMS)

  const params = new URLSearchParams(window.location.search)
  existingPrivateParams.forEach((param) => params.set(param, "-REDACTED-"))

  const cleanParams = params.toString() !== "" ? `?${params.toString()}` : null

  return `${document.location.pathname}${cleanParams || ""}`
}

/**
 * Get a plural string representation of the provided number
 * @param  {number} grammaticalNumber     The amount to base the string on
 * @param  {string} singularTerm          The singular term to be used
 * @param  {string} pluralTerm            The plural term to be used
 * @return {string}                       Whatever term that should be used for the count value
 */
export const pluralize = (grammaticalNumber, singularTerm, pluralTerm) =>
  grammaticalNumber === 1 ? singularTerm : pluralTerm

/**
 * Get a random number between provided min and max values. Both values are inclusive.
 * Based on: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values_inclusive
 * @param  {number} min The min value this function will return
 * @param  {number} max The max value this function will return
 * @return {number} A random number between min and max
 */
export const getRandomNumberBetween = (min, max) => {
  const minValue = Math.ceil(min)
  const maxValue = Math.floor(max)
  return Math.floor(Math.random() * (maxValue - minValue + 1) + minValue)
}

/**
 * Capitalize the provided string
 * @param {string} string   The string to be capitalized
 * @return {string}         The capitalized string
 */
export const capitalize = (string) => (string && string[0].toUpperCase() + string.slice(1)) || ""
