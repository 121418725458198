// @ts-check
import { Controller } from 'stimulus'

/**
 * Search Input Container
 * Toggles STATE_CLASS to when its [type='search'] descendant is :focus.
 *
 * @export
 * @class SearchInputContainer
 * @extends {Controller}
 */
export default class SearchInputContainer extends Controller {
  static STATE_CLASS = 'has-focused-search'

  initialize() {
    this._boundFocusEventHandler = this._boundFocusEventHandler.bind(this)
  }

  // The input can’t be set with data-target as its code might be unreachable
  get input() {
    return this.element.querySelector('input[type="search"]')
  }

  connect() {
    this.input.addEventListener('focus', this._boundFocusEventHandler)
    this.input.addEventListener('blur', this._boundFocusEventHandler)

    this.element.classList.add('js-is-connected')
  }

  /**
   * Handle both focus and blur events on the search field
   * @param {InputEvent} event
   */
  _boundFocusEventHandler(event) {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout)
    }

    if (event.type === 'focus') {
      this.element.classList.add(SearchInputContainer.STATE_CLASS)
    } else if (event.type === 'blur') {
      this.blurTimeout = setTimeout(() => {
        this.element.classList.remove(SearchInputContainer.STATE_CLASS)
      })
    }
  }

  disconnect() {
    this.input.addEventListener('focus', this._boundFocusEventHandler)
    this.input.addEventListener('blur', this._boundFocusEventHandler)

    this.element.classList.remove('js-is-connected')
  }
}
