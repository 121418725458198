import { Controller } from 'stimulus'
import * as user from '../../../../_js/helpers/user'
import FormController from '../../../../_components/form/form_controller'
import SnackBar from '../../../../_components/snackbar/snackbar'
import { GET_WORKSPACES_QUERY, GET_SUBSCRIPTION_INFO_QUERY } from '../gql'
import { executeQuery, executePaymentsQuery, SSO_ERROR } from '../../../../_js/base/graphql-api'

const STANDARD_WORKSPACE = 'STANDARD'
const TEAMS = 'TEAMS'
const STUDENT = 'STUDENT'

const getPaymentInfoByWorkspace = async (workspacesFiltered) =>
  Promise.all(
    workspacesFiltered.map(async (workspace) => {
      const { data: dataPayments } = await executePaymentsQuery({
        query: GET_SUBSCRIPTION_INFO_QUERY,
        variables: {
          customerId: workspace?.customerId,
        },
      })

      if (!dataPayments) return {}

      return {
        workspaceId: workspace.identifier,
        status: dataPayments?.customer?.subscription?.status,
        subscriptionEnd: dataPayments?.customer?.subscription?.subscriptionEnd,
        subscriptionPlan: dataPayments?.customer?.subscription?.currentPlan?.product,
      }
    })
  )

/**
 * The Workspace component renders the section and select input where the user
 * selects which workspace he wants to request an education license to.
 */

export default class extends Controller {
  static targets = [
    'userInfo',
    'selectWorkspaceInput',
    'selectWorkspaceSection',
    'selectWorkspaceContainer',
    'createWorkspaceContainer',
    'educationFormFieldset',
  ]

  static values = {
    isLoading: Boolean,
    hasError: Boolean,
    workspaces: Array,
  }

  async connect() {
    const isSignedIn = user.isLoggedIn()

    if (!isSignedIn) {
      this.disableWorkspaceSelect()
      return
    }

    await this.getWorkspaces()
  }

  // Query the BE for the user workspaces and filter workspaces
  async getWorkspaces() {
    try {
      this.isLoadingValue = true
      const { data, errors } = await executeQuery({ query: GET_WORKSPACES_QUERY })

      if (errors && errors.length > 0) {
        if (errors[0].message === SSO_ERROR) {
          this.userInfoController.showSSOWarning()
          this.disableWorkspaceSelect()
          return
        }

        this.handleQueryError(errors)
        return
      }

      const workspacesFiltered = data?.me?.workspaceMemberships?.entries
        ?.filter(
          (entry) =>
            entry.role === 'ADMIN' &&
            (entry?.workspace?.type === STANDARD_WORKSPACE ||
            entry?.workspace?.type === STUDENT) &&
            !entry?.workspace?.customer?.ssoEnabled
        )
        ?.map((entry) => ({
          identifier: entry?.workspace?.identifier,
          name: entry?.workspace?.name,
          customerId: entry?.workspace?.customer?.identifier,
          type: entry?.workspace?.type,
        }))

      // We do an initial load to avoid flickering when loading the whole page
      this.workspacesValue = workspacesFiltered

      const paymentsInfo = await getPaymentInfoByWorkspace(workspacesFiltered)
      const lastSelectedWorkspace = user.getLastWorkspace()

      this.workspacesValue = workspacesFiltered
        .filter((workspace) => {
          const pInfo = paymentsInfo.find((item) => workspace?.identifier === item?.workspaceId)

          if (!pInfo) return false
          if (workspace?.type === STUDENT) {
            const expiresIn30Days = (new Date(pInfo.subscriptionEnd) - new Date() < 30 * 24 * 60 * 60 * 1000) > 0
            if (expiresIn30Days) return expiresIn30Days;
            
            return pInfo.status === 'CANCELED'
          }

          return pInfo.status !== 'PAST_DUE' && [TEAMS, STUDENT].includes(pInfo.subscriptionPlan)
        })
        .map((item) => ({ ...item, selected: lastSelectedWorkspace === item.identifier }))
    } finally {
      this.isLoadingValue = false
    }
  }

  // Displays the snackbar for errors and, in case the
  // token expires (401), also signs the user out
  handleQueryError(errors) {
    this.hasErrorValue = !!errors?.length

    // Grab first error with a message
    const error = errors?.find((foundError) => foundError.message)

    // We check if the user is still signed in, in an attempt to keep the UI synched with the auth state
    // The token might expire before the query is executed throwing a 401 and sign the user out
    if (user.isLoggedIn()) {
      this.userInfoController.signOut()
      this.disableWorkspaceSelect()
    }

    // We don't have an error to render the SnackBar
    if (!error.length) return

    // eslint-disable-next-line no-new
    new SnackBar(error.message, {
      modifiers: 'error',
      autoHide: true,
      duration: FormController.ALERT_DURATION,
    })
  }

  // Populates the select with the Workspaces as options
  // and, if no workspaces are found, renders "create new workspace" UI
  workspacesValueChanged(workspaces) {
    const hasStudentWorkspace = !!workspaces.length
    const createWorkspaceUIElement = this.createWorkspaceContainerTarget
    const selectWorkspaceUIElement = this.selectWorkspaceContainerTarget
    let selectedWorkspaceValue = ''

    if (!hasStudentWorkspace) {
      // No workspaces found. Change UI to show "Create a new workspace"
      selectWorkspaceUIElement.classList.add('is-hidden')
      createWorkspaceUIElement.classList.remove('is-hidden')
    } else {
      // Workspaces found, add them to the select element
      const select = this.selectWorkspaceInputTarget
      const options = Array.from(select.options)

      workspaces.forEach((workspace) => {
        // Clear all options but the placeholder
        options.forEach((option) => !option.disabled && option.remove())

        // Add workspaces as options
        const newOption = document.createElement('option')

        if (workspace.selected) {
          newOption.selected = true
          this.formController.enableForm()
          selectedWorkspaceValue = workspace.identifier
        }
        newOption.value = workspace.identifier
        newOption.text = workspace.name
        select.appendChild(newOption)
      })

      // Switch from "Create workspace" UI to the "Select workspace" UI
      if (selectedWorkspaceValue) {
        this.formController.selectedWorkspaceValue = selectedWorkspaceValue
      }
      createWorkspaceUIElement.classList.add('is-hidden')
      selectWorkspaceUIElement.classList.remove('is-hidden')
    }
  }

  // Handles loading UI
  isLoadingValueChanged(isLoading) {
    this.selectWorkspaceSectionTarget.classList.toggle('is-loading', isLoading)
  }

  // Handles error UI
  hasErrorValueChanged(hasError) {
    this.selectWorkspaceInputTarget.classList.toggle('is-invalid', hasError)
  }

  // Disable workspace selection
  disableWorkspaceSelect() {
    this.selectWorkspaceSectionTarget.classList.add('is-disabled')
    this.selectWorkspaceInputTarget.setAttribute('disabled', true)
  }

  // Hide Workspace UI
  hideWorkspace() {
    this.selectWorkspaceSectionTarget.remove()
  }

  // Connect with the user-info controller
  get userInfoController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('.user-info'),
      'store--edu--user-info'
    )
  }

  // Connect with the user-info controller
  get formController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('.education-form'),
      'store--edu--form'
    )
  }
}
