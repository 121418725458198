/**
 * Tracks custom events on click and logs them to the console.
 * @param {Event} event - The click event.
 * @returns {void}
 */
// const saCustomEventClickHandler = (event) => {
//   const element = event.target.closest('[data-event-name]')
//   if (!element) return

//   const { eventName, eventProperty = 'Label', eventValue } = element.dataset

//   sa_event(eventName, { [eventProperty]: eventValue })
// }

/**
 * Track Simple Analytics events
 */
const setUpSimpleAnalytics = () => {
  // document.addEventListener('click', (event) => {
  //   saCustomEventClickHandler(event)
  //   saLinkClickHandler(event)
  // })
}

export default setUpSimpleAnalytics
