import upperFirst from 'lodash/upperFirst'
import FormController from '../../../_components/form/form_controller'
import appVersionData from '../../../_js/utils/app-version-data'
import * as userUtils from '../../../_js/helpers/user'

const HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HS_PORTAL_ID = 7692458
const HS_SUBSCRIPTION_ID = 9320643
const HS_SANDBOX_PORTAL_ID = 20854638
const HS_SANDBOX_FORM_GUID = 'bc45c13f-c55e-4910-82a0-0c8d415262e8'
const HS_SANDBOX_SUBSCRIPTION_ID = 24734259

const SUBMISSION_DELAY = 20000

export default class FormTopic extends FormController {
  static targets = ['topic', 'topicSelector', 'subject', 'subjectSelector', 'summary', 'email']

  searchTerm = ''

  connect() {
    this.isProd = ['production', 'staging'].indexOf(this.element.dataset.env) !== -1
    this.setFormFieldsFromURL()

    this.prepopulateFields()

    this.resetTimestamp()
  }

  resetTimestamp() {
    this.startTime = Date.now()
  }

  prepopulateFields(event) {
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null

    if (this.user) {
      this.emailTarget.value = this.user.email
    }
  }

  selectTopic(event) {
    const optionNode = event.target.querySelector(`option[value='${event.target.value}']`)
    this.element.dataset.hubspotFormGuid = optionNode.dataset.hubspotFormGuid
    this.subjectTarget.placeholder = optionNode.dataset.subjectPlaceholder
    this.summaryTarget.placeholder = optionNode.dataset.descriptionPlaceholder

    // Resize and set textarea content
    this.summaryTarget.style.height = null
    this.summaryTarget.value = optionNode.dataset.descriptionValue || ''
    this.summaryTarget.style.height = `${this.summaryTarget.scrollHeight}px`

    this.topicTargets.forEach((topicNode) => {
      topicNode.classList.toggle('is-hidden', topicNode.id !== `form-aside-${event.target.value}`)
    })

    this.subjectSelectorTarget.value = ''
    this.subjectTarget.value = ''

    // Convert options to spans and back for Safari compatibility
    this.subjectSelectorTarget.querySelectorAll('option, span.option').forEach((node) => {
      if (node.disabled) return // Skip disabled options

      const shouldBeHidden = node.dataset.topic !== event.target.value

      if (shouldBeHidden && node.tagName === 'OPTION') {
        // Convert option to span when hiding
        const span = document.createElement('span')
        span.className = 'option'
        // Copy all attributes
        Array.from(node.attributes).forEach((attr) => {
          span.setAttribute(attr.name, attr.value)
        })
        span.textContent = node.textContent
        node.parentNode.replaceChild(span, node)
      } else if (!shouldBeHidden && node.tagName === 'SPAN') {
        // Convert span back to option when showing
        const option = document.createElement('option')
        // Copy all attributes except class
        Array.from(node.attributes).forEach((attr) => {
          if (attr.name !== 'class') {
            option.setAttribute(attr.name, attr.value)
          }
        })
        option.textContent = node.textContent
        node.parentNode.replaceChild(option, node)
      }
    })

    if (optionNode.dataset.hasSubtopics === 'true') {
      // Hide subject field and remove required attribute
      this.subjectTarget.parentElement.classList.add('is-hidden')
      this.subjectTarget.required = false

      // Show subject selector and ensure it's required
      this.subjectSelectorTarget.parentElement.classList.remove('is-hidden')
      this.subjectSelectorTarget.required = true
    } else {
      // Hide subject selector and remove required attribute
      this.subjectSelectorTarget.parentElement.classList.add('is-hidden')
      this.subjectSelectorTarget.required = false

      // Show subject field and ensure it's required
      this.subjectTarget.parentElement.classList.remove('is-hidden')
      this.subjectTarget.required = true
    }
  }

  // Add resetSubjects method to convert all spans back to options when needed
  resetSubjects() {
    this.subjectSelectorTarget.querySelectorAll('span.option').forEach((span) => {
      const option = document.createElement('option')
      Array.from(span.attributes).forEach((attr) => {
        if (attr.name !== 'class') {
          option.setAttribute(attr.name, attr.value)
        }
      })
      option.textContent = span.textContent
      span.parentNode.replaceChild(option, span)
    })
  }

  selectSubject(event) {
    const optionNode = event.target.querySelector(`option[value='${event.target.value}']`)
    this.summaryTarget.placeholder = optionNode.dataset.descriptionPlaceholder

    // Resize and set textarea content
    this.summaryTarget.style.height = null
    this.summaryTarget.value = optionNode.dataset.descriptionValue || ''
    this.summaryTarget.style.height = `${this.summaryTarget.scrollHeight}px`

    this.topicTargets.forEach((topicNode) => {
      const formAsideNode = document.getElementById(`form-aside-${event.target.value}`)

      if (formAsideNode) {
        topicNode.classList.toggle('is-hidden', topicNode.id !== `form-aside-${event.target.value}`)
      }
    })
  }

  // Replace original function to include Sketch system info in the FormData and check for submission delay
  submitRemotely() {
    // Generate action URL on submit
    this.generateFormActionURL()

    // Check if the first submission happens after a certain delay (to prevent bot submissions that happens too fast)
    if (Date.now() - this.startTime < SUBMISSION_DELAY) return

    // Capture form data before any inputs are possibly disabled
    const formData = new FormData(this.element)

    // Get Sketch related system info from local storage
    const systemInfo = appVersionData.getReadableFromStorage()

    const postData = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formData.get('email'),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'subject',
          value: formData.get('headlineSelection') || formData.get('headline'),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'content',
          value: (() => {
            const id = formData.get('id')
            const text = formData.get('text')
            return id ? `[${id}] ${text}` : text
          })(),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'system_info',
          value: systemInfo || '',
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'search_term',
          value: this.searchTerm || '',
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: formData.has('gdpr'),
          text: "I confirm that I have read and agree to Sketch's Terms of Service and Privacy Statement",
          communications: [
            {
              value: formData.has('newsletter'),
              subscriptionTypeId: this.isProd ? HS_SUBSCRIPTION_ID : HS_SANDBOX_SUBSCRIPTION_ID,
              text: 'Consent to keep me updated on news and offers',
            },
          ],
        },
      },
    }

    this.disableSubmission()

    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else {
          this.resetTimestamp()
          return this.remoteSubmissionSuccessful()
        }
      })
      .catch(() => this.remoteSubmissionFailed())
      .finally(() => this.enableSubmission())
  }

  generateFormActionURL() {
    this.element.setAttribute(
      'action',
      `${HS_ENDPOINT_PREFIX}/${this.isProd ? HS_PORTAL_ID : HS_SANDBOX_PORTAL_ID}/${
        this.isProd ? this.element.dataset.hubspotFormGuid : HS_SANDBOX_FORM_GUID
      }`
    )
  }

  setFormFieldsFromURL() {
    const urlParams = new URLSearchParams(window.location.search)

    // Set topic dropdown from URL
    const topicValue = urlParams.get('topic') || null
    const subjectValue = urlParams.get('subject') || null

    if (topicValue) {
      this.topicSelectorTarget.value = topicValue
      this.topicSelectorTarget.dispatchEvent(new Event('change'))
    }

    if (subjectValue) {
      this.subjectSelectorTarget.value = subjectValue
      this.subjectSelectorTarget.dispatchEvent(new Event('change'))
    }

    // Iterate through text fields to set them from URL
    // ⚠️ The fields listed below will override whatever values the 'topic' from above may have
    // previously set.
    const textFieldTargetNames = ['email', 'subject', 'summary']
    textFieldTargetNames.forEach((textFieldTargetName) => {
      const paramValue = urlParams.get(textFieldTargetName) || null
      if (paramValue && this[`has${upperFirst(textFieldTargetName)}Target`]) {
        this[`${textFieldTargetName}Target`].value = paramValue
      }
    })

    if (urlParams.get('search_term')) this.searchTerm = decodeURIComponent(urlParams.get('search_term'))
  }
}
