import { Controller } from 'stimulus'
import * as user from '../helpers/user'

export default class LoginStateController extends Controller {
  static targets = ['loggedOut', 'loggedIn']

  static values = {
    isLoggedIn: Boolean,
  }

  connect() {
    this.checkLoginState()
  }

  /**
   *
   * @param {StorageEvent?} event
   */
  checkLoginState(event) {
    // if a storage event but not on the relevant keys, early return
    if (event && event.type === 'storage' && event.key !== null && event.key !== user.LOCALSTORAGE_USER_KEY) return
    this.isLoggedInValue = user.isLoggedIn()
  }

  isLoggedInValueChanged() {
    this.loggedInTargets.forEach((element) => {
      element.classList.toggle('is-hidden', !this.isLoggedInValue)
    })

    this.loggedOutTargets.forEach((element) => {
      element.classList.toggle('is-hidden', this.isLoggedInValue)
    })
  }
}
