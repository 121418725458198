import h from 'hyperscript'
import { bemPrefixModifiers, forceReflow } from '../../_js/base/utils'

/**
 * Snack Bar
 * A small notification bar that appears on the bottom of the page
 *
 * @param {(string|Array|HTMLElement)} label                        - content to be displayed
 * @param {object}                     [options]                    - options
 * @param {boolean}                    [options.autoHide=false]     - automatically hide the bar after `duration`
 * @param {boolean}                    [options.dismissable=false]  - show / hide close button
 * @param {number}                     [options.duration=6000]      - duration, in milliseconds, until bar is hidden if `autoHide` is `true`
 * @param {(string|string[])}          [options.modifiers='']       - BEM modifiers
 * @param {string}                     [options.testSelector]       - data attribute value for tests
 * @param {string}                     [options.position='bottom']  - top|bottom
 * @param {function}                   showCallback                 - function to be called after showing the snackbar
 */
class SnackBar {
  constructor(
    label,
    { autoHide = false, dismissable = false, duration = 6000, modifiers = '', testSelector, position = 'bottom' } = {},
    showCallback
  ) {
    if (typeof label !== 'string' && !Array.isArray(label) && !(label instanceof HTMLElement)) {
      throw new Error('`label` must be of type string, array or HTMLElement')
    }

    this.showCallback = showCallback
    this.label = label
    this.autoHide = autoHide
    this.dismissable = dismissable
    this.duration = duration
    this.modifiers = modifiers
    this.position = position
    this.testSelector = testSelector
    this.hide = this.hide.bind(this)
    this.remove = this.remove.bind(this)

    this.init()
  }

  init() {
    this.createElement()
    forceReflow().then(() => this.show())
  }

  createElement() {
    const hasTestSelector = this.testSelector && this.testSelector !== ''

    const attrs = {
      className: bemPrefixModifiers('snackbar', [this.modifiers, this.position], { includeBase: true, asString: true }),
      ...(hasTestSelector ? { 'data-test': this.testSelector } : {}),
    }

    // Build the body differently based on the label to allow strings to contain inline HTML
    const body = h('div.snackbar__body', typeof this.label === 'string' ? { innerHTML: this.label } : this.label)

    const closeButton =
      !this.autoHide || this.dismissable
        ? h('div.snackbar__close', {
            onclick: this.hide,
            ...(hasTestSelector ? { 'data-test': `${this.testSelector}_close` } : {}),
          })
        : null

    this.element = h('div', attrs, body, closeButton)

    document.body.appendChild(this.element)
  }

  show() {
    this.element.classList.add('is-visible')

    if (this.autoHide) {
      window.setTimeout(() => this.hide(), this.duration)
    }

    if (this.showCallback) {
      this.showCallback()
    }
  }

  hide() {
    this.element.addEventListener('transitionend', this.remove)
    this.element.classList.remove('is-visible')
  }

  remove() {
    this.element.removeEventListener('transitionend', this.remove)
    this.element.parentNode.removeChild(this.element)
  }
}

export default SnackBar
