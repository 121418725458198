/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { Controller } from 'stimulus'
import Swiper, { Autoplay, EffectFade, Pagination, Thumbs } from 'swiper'

const AUTOPLAY_DELAY = 20000
const BULLET_CLASS = 'customer-stories__paginator-item'
const BULLET_ACTIVE_CLASS = 'customer-stories__paginator-item--active'

// animation classes for customer-stories__content
const CUSTOMER_STORIES_CONTENT_SELECTOR = '.customer-stories__content'
const DO_NOT_RETURN_TO_POSITION_CLASS = 'do-not-return-to-starting-position'
const MOVE_ITEMS_TO_THE_RIGHT_CLASS = 'swiper--move-all-to-the-right'
const MOVE_ITEMS_TO_THE_LEFT_CLASS = 'swiper--move-all-to-the-left'

export default class CustomerStoriesController extends Controller {
  static targets = ['colorName', 'swiper']

  connect() {
    // eslint-disable-next-line no-unused-vars
    const mainSwiper = new Swiper(this.swiperTarget, {
      speed: 600,
      effect: 'fade',
      autoHeight: false,
      fadeEffect: { crossFade: true },
      modules: [Autoplay, Pagination, EffectFade, Thumbs],
      loop: false,
      allowTouchMove: false,
      autoplay: {
        delay: AUTOPLAY_DELAY,
        disableOnInteraction: false,
      },
      on: {
        beforeTransitionStart: (swiper) => {
          // restart do not return to starting position
          swiper.slides.forEach((slide) => {
            slide.querySelector(CUSTOMER_STORIES_CONTENT_SELECTOR).classList.remove(DO_NOT_RETURN_TO_POSITION_CLASS)
          })

          // if new slide (activeIndex) is bigger than current slide (previousIndex)
          // it means the animation runs to the left - adds swiper--move-all-to-the-left
          // and sets all slides that precede the new slide (activeIndex) to "not return to the starting position" (avoiding css transition reset)
          // meaning they all move to the left - that's when the current slide (previousIndex) animates to the left.
          // now, all slides are locked into position for the next animation
          // ------- else -------
          // if new slide (activeIndex) is smaller than current slide (previousIndex)
          // it means the animation runs to the right - adds swiper--move-all-to-the-right
          // and sets all slides that succeed the new slide (activeIndex) to "not return to the starting position" (avoiding css transition reset)
          // meaning they all move to the right - that's when the current slide (previousIndex) animates to the right.
          // now, all slides are locked into position for the next animation
          if (swiper.activeIndex > swiper.previousIndex) {
            swiper.el.classList.remove(MOVE_ITEMS_TO_THE_RIGHT_CLASS)
            swiper.el.classList.add(MOVE_ITEMS_TO_THE_LEFT_CLASS)

            swiper.slides.forEach((slide, index) => {
              if (index < swiper.activeIndex) {
                slide.querySelector(CUSTOMER_STORIES_CONTENT_SELECTOR).classList.add(DO_NOT_RETURN_TO_POSITION_CLASS)
              }
            })
          } else {
            swiper.el.classList.remove(MOVE_ITEMS_TO_THE_LEFT_CLASS)
            swiper.el.classList.add(MOVE_ITEMS_TO_THE_RIGHT_CLASS)

            swiper.slides.forEach((slide, index) => {
              if (index > swiper.activeIndex) {
                slide.querySelector(CUSTOMER_STORIES_CONTENT_SELECTOR).classList.add(DO_NOT_RETURN_TO_POSITION_CLASS)
              }
            })
          }
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: BULLET_CLASS,
        bulletActiveClass: BULLET_ACTIVE_CLASS,
        renderBullet: function (index, className) {
          const parent = this.el
          const images = Array.from(parent.querySelectorAll('.customer-stories__story')).map((el) => {
            const image = el.getAttribute('data-thumbnail')

            return image
          })

          return `
            <div class="${className}">
              <svg width="40" height="40">
                <circle class="customer-stories__paginator-circle" cx="20" cy="20" r="18" stroke="#F26725" stroke-width="4" fill="none" stroke-opacity="0.3" />
                <circle class="customer-stories__paginator-circle customer-stories__paginator-circle--progress" cx="20" cy="20" r="18" stroke="#FF7843" stroke-width="4" fill="none" stroke-opacity="1" stroke-dasharray="114" stroke-dashoffset="0" />
              </svg>
              <img src="${images[index]}">
            </div>
          `
        },
      },
    })

    // Swiper restarts autoplay when user changes tab. We restart the css animation here.
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        const activeElements = document.querySelectorAll(`.${BULLET_ACTIVE_CLASS}`)
        activeElements.forEach((activeElement) => {
          activeElement.classList.remove(BULLET_ACTIVE_CLASS)
          setTimeout(() => {
            activeElement.classList.add(BULLET_ACTIVE_CLASS)
          }, 1)
        })
      }
    })

    // There's a bit of delay between swiper changing the actual slide and when our animation ends, so adding a bit of time here to make up for that
    const swiperAnimationDelay = 200
    document.documentElement.style.setProperty('--animation-time', AUTOPLAY_DELAY + swiperAnimationDelay + 'ms')
  }
}
