import { Controller } from 'stimulus'
import Overlay from '../../_components/overlay/overlay'
import { BREAKPOINTS } from '../base/consts'
import mediaFrom from '../utils/media-from'

let sheetHeight

export default class BottomSheet extends Controller {
  static targets = ['sheet', 'draggable']

  connect() {
    if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.overlay = new Overlay({
        modifiers: `dark`,
      })
      this.touchEvents()
    }
  }

  setSheetHeight(value) {
    sheetHeight = Math.max(0, Math.min(100, value))
    this.sheetTarget.style.height = `${sheetHeight}vh`
  }

  touchEvents() {
    if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      let startY

      this.draggableTarget.addEventListener('touchstart', (event) => {
        startY = event.touches[0].clientY
        this.overlay.show()
      })

      this.draggableTarget.addEventListener('touchmove', (event) => {
        if (startY === undefined) return

        const clientHeight = document.documentElement.clientHeight

        const viewportHeight = clientHeight
        const touchY = event.touches[0].clientY
        const deltaY = ((viewportHeight - touchY) / viewportHeight) * 100

        this.setSheetHeight(deltaY)
      })

      this.draggableTarget.addEventListener('touchend', () => {
        startY = undefined
        if (sheetHeight > 50) {
          this.element.style.height = null
          this.sheetTarget.classList.add('is-expanded')
        } else if (sheetHeight < 50) {
          this.element.style.height = '101px'
          this.sheetTarget.classList.remove('is-expanded')
          this.overlay.hide()
        }
      })
    }
  }
}
