import { Controller } from 'stimulus'
import { copyToClipboard } from '../../_js/base/utils'

export default class IconsController extends Controller {
  static values = {
    successCopy: String,
  }

  defaultCopyMessage

  copyUrlToClipboard({ target }) {
    const defaultCopy = target.getAttribute('data-tooltip-copy')
    const shortcode = target.getAttribute('data-shortcode')
    const copy = `{% ui_icon "${shortcode}" %}`

    target.setAttribute('data-tooltip-copy-default', defaultCopy)
    target.setAttribute('data-tooltip-copy', this.successCopyValue)

    copyToClipboard(copy)
    target.addEventListener('mouseleave', this.bringBackDefaultCopyMessage, { once: true })
  }

  // eslint-disable-next-line class-methods-use-this
  bringBackDefaultCopyMessage({ target }) {
    const defaultCopy = target.getAttribute('data-tooltip-copy-default')
    target.setAttribute('data-tooltip-copy', defaultCopy)
    target.removeAttribute('data-tooltip-copy-default')
  }
}
