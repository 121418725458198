import * as user from '../../../../_js/helpers/user'
import FormController from '../../../../_components/form/form_controller'
import SnackBar from '../../../../_components/snackbar/snackbar'
import {
  CREATE_STUDENT_APPLICATION_MUTATION,
  CREATE_INSTITUTION_APPLICATION_MUTATION,
  CREATE_TEACHER_APPLICATION_MUTATION,
} from '../gql'
import { executeUploadMutation } from '../../../../_js/base/graphql-api'

const MAX_FILE_SIZE = 15000000 // 15MB
const ALLOWED_FILE_TYPES = ['pdf', 'jpg', 'jpeg', 'heic', 'png']

const splitFileName = (fileName) => {
  const [fullName, name, extension] = fileName.match(/^(.+)\.(.+)$/)

  return {
    fullName,
    name,
    extension,
  }
}

const truncateFileName = (fileName, maxLength = 20) => {
  const { name, extension } = splitFileName(fileName)

  return name.length > maxLength ? `${name.substring(0, maxLength)}...${extension}` : `${name}.${extension}`
}

const filterInvalidFileTypes = (files) => {
  const hasInvalidTypes = files.filter((file) => {
    const { extension } = splitFileName(file.name)

    return !ALLOWED_FILE_TYPES.map(type => type.toLowerCase()).includes(extension?.toLowerCase())
  })

  return !!hasInvalidTypes.length
}

/**
 * The Form controller handles the form fields and form submission
 */
export default class FormTopic extends FormController {
  static targets = [
    'form',
    'educationFormFieldset',
    'formSuccess',
    'countryFieldInstitution',
    'countryFieldStudent',
    'countryField',
    'submit',
    'selectWorkspaceInput',
    'proofOfEmployment',
    'studentTeacherStatus',
    'courseDocumentation',
    'studentTeacherFilesField',
  ]

  static values = {
    requestType: String,
    academicInstitutionRequest: Boolean,
    agreement: Boolean,
    selectedWorkspace: String,
    isLoading: Boolean,
  }

  connect() {
    this.disableForm()
  }

  async submitRemotely() {
    const requestType = this.requestTypeValue
    const isAcademicInstitutionRequest = this.academicInstitutionRequestValue
    const selectedWorkspace = this.selectedWorkspaceValue

    // Workspace must be selected
    if (!selectedWorkspace) {
      this.selectWorkspaceInputTarget.classList.add('is-invalid')
      this.selectWorkspaceInputTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    const institutionName = this.element.elements.institution_name.value
    const country = this.element.elements.country.value
    const courses = this.element.elements.courses.value
    const additionalComments = this.element.elements.additional_comments.value
    const institutionUrl = this.element.elements.institution_url.value

    // Academic Institution specific fields
    const city = this.element.elements.city.value
    const line = this.element.elements.institution_address.value
    const postCode = this.element.elements.zip_code.value
    const state = this.element.elements.state_region.value
    const contactInfo = this.element.elements.contact_details.value
    const seats = Number(this.element.elements.seats.value)

    this.disableSubmission()

    // Prepare files array according to type of request
    const proofFiles = isAcademicInstitutionRequest
      ? Array.from(this.proofOfEmploymentTarget.files)
      : Array.from(this.studentTeacherStatusTarget.files)
    const proofFilesNames = proofFiles.map(({ name }) => name)

    const courseDocumentationFiles = isAcademicInstitutionRequest
      ? Array.from(this.courseDocumentationTarget.files)
      : []
    const courseDocumentationFilesNames = courseDocumentationFiles.map(({ name }) => name)

    const variables = isAcademicInstitutionRequest
      ? {
          input: {
            workspaceIdentifier: selectedWorkspace,
            institutionName: institutionName,
            institutionUrl: institutionUrl,
            proof: proofFilesNames,
            courseDocumentation: courseDocumentationFilesNames,
            seats,
            contactInfo,
            address: {
              city,
              country,
              line,
              postCode,
              state,
            },
            courses,
            comments: additionalComments,
          },
        }
      : {
          input: {
            workspaceIdentifier: selectedWorkspace,
            institutionName: institutionName,
            institutionUrl: institutionUrl,
            proof: proofFilesNames,
            country,
            courses,
            comments: additionalComments,
          },
        }

    // Prepare FormData
    const formData = new FormData()

    // Append query to the formData
    switch (requestType) {
      case 'academic_institution':
        formData.append('query', CREATE_INSTITUTION_APPLICATION_MUTATION)
        break
      case 'student':
        formData.append('query', CREATE_STUDENT_APPLICATION_MUTATION)
        break
      case 'teacher':
        formData.append('query', CREATE_TEACHER_APPLICATION_MUTATION)
        break
      default:
        formData.append('query', CREATE_STUDENT_APPLICATION_MUTATION)
        break
    }

    // Prepare append files to the formData
    proofFiles.forEach((file) => {
      formData.append(file.name, file, file.name)
    })

    courseDocumentationFiles.forEach((file) => {
      formData.append(file.name, file, file.name)
    })

    // Append the mutation variables to the FormData
    formData.append('variables', JSON.stringify(variables))

    // Submit FormData
    try {
      this.isLoadingValue = true
      const { errors } = await executeUploadMutation({ formData })

      if (errors) {
        const error = errors.find((foundError) => foundError.message)

        // eslint-disable-next-line no-new
        new SnackBar(error.message, {
          modifiers: 'error',
          autoHide: true,
          duration: FormController.ALERT_DURATION,
        })

        this.enableSubmission()
        return
      }

      // We don't care about the data so just reset the form and show success message
      this.resetForm()
      this.showSuccessUI()
      this.enableSubmission()
    } catch {
      this.isLoadingValue = false
    } finally {
      this.isLoadingValue = false
    }
  }

  handleWorkspaceSelect(event) {
    this.selectedWorkspaceValue = event.target.value
  }

  // When the user selects a file to upload, we reveal the preview element
  // This will show the file name next to the "choose file" button
  // or if multiple files are selected, it shows "X files selected..."
  // eslint-disable-next-line class-methods-use-this
  handleFileChange(event) {
    const input = event.target
    const label = input.closest('label')
    const hasFiles = !!input.files.length
    const isFileTooBig = !!Array.from(input.files).filter((file) => file.size > MAX_FILE_SIZE).length
    const previewContainer = input.parentElement.querySelector('.form__custom-file-input__preview')
    const previewText = input.parentElement.querySelector('.form__custom-file-input__preview-text')
    const filePreviewIcons = input.parentElement.querySelectorAll('.form__custom-file-input__preview-icon')
    const [documentsIcon, documentIcon] = filePreviewIcons

    // Find invalid file types
    const hasInvalidFileTypes = filterInvalidFileTypes(Array.from(input.files))

    if (!hasFiles) {
      previewContainer.classList.add('is-hidden')
    }

    if (hasInvalidFileTypes) {
      // Set invalid states
      label.classList.add('is-invalid')

      input.setAttribute('aria-invalid', true)
      input.classList.add('is-invalid')

      // Clear input file value
      input.value = null

      // Hide preview container
      previewContainer.classList.add('is-hidden')

      // eslint-disable-next-line no-new
      new SnackBar(`Please select a valid file type: ${ALLOWED_FILE_TYPES.join(', ')}`, {
        modifiers: 'error',
        autoHide: true,
        duration: FormController.ALERT_DURATION,
      })

      return
    }

    // Clear possible invalid state
    label.classList.remove('is-invalid')
    input.setAttribute('aria-invalid', false)
    input.classList.remove('is-invalid')
    previewText.classList.remove('is-invalid')

    // Check if file exceeds max size
    if (isFileTooBig) {
      // Clear file from the input
      input.value = null

      // Hide preview icons
      filePreviewIcons.forEach((element) => element.classList.add('is-hidden'))

      // Add copy and show preview element
      previewText.textContent = 'One or more files exceed 15 MB'
      previewContainer.classList.remove('is-hidden')
      previewText.classList.add('is-invalid')

      return
    }

    // We have files and they are the correct size
    // Show the preview element with proper icon and copy
    if (hasFiles && !isFileTooBig) {
      const isSingleFile = input.files.length === 1

      // Add text to  preview element
      if (isSingleFile) {
        const fileName = truncateFileName(input.files[0].name)

        previewText.textContent = fileName
      } else {
        previewText.textContent = `${input.files.length} files selected…`
      }

      // Reveal preview element
      documentIcon.classList.toggle('is-hidden', isSingleFile)
      documentsIcon.classList.toggle('is-hidden', !isSingleFile)
      previewContainer.classList.toggle('is-hidden', !hasFiles)
    }
  }

  setRequestType(event) {
    this.requestTypeValue = event.target.value
  }

  requestTypeValueChanged(requestType) {
    switch (requestType) {
      case 'academic_institution':
        this.academicInstitutionRequestValue = true
        break
      case 'student':
        this.studentTeacherFilesFieldTarget.classList.remove('is-hidden')
        this.studentTeacherFilesFieldTarget.querySelector('input').readOnly = false
        document.querySelector('.student-status-label').classList.remove('is-hidden')
        document.querySelector('.teacher-status-label').classList.add('is-hidden')
        this.academicInstitutionRequestValue = false
        break
      case 'teacher':
        this.studentTeacherFilesFieldTarget.classList.remove('is-hidden')
        this.studentTeacherFilesFieldTarget.querySelector('input').readOnly = false
        document.querySelector('.student-status-label').classList.add('is-hidden')
        document.querySelector('.teacher-status-label').classList.remove('is-hidden')
        this.academicInstitutionRequestValue = false
        break
      default:
        break
    }
  }

  // Changes the form fields and UI to match the request type (Student vs Academic Institution)
  academicInstitutionRequestValueChanged(isAcademicInstitutionRequest) {
    this.clearFormErrors()
    const institutionFields = document.querySelectorAll('.institution-field')
    const inputFields = document.querySelectorAll('.institution-field input')

    // Switch between "Academic Institution Request" form fields and "Student/Teacher Request" form fields
    if (isAcademicInstitutionRequest) {
      institutionFields.forEach((field) => field.classList.remove('is-hidden'))
      this.countryFieldInstitutionTarget.appendChild(this.countryFieldTarget) // The country field needs to be moved when changing between the types of request
      this.studentTeacherFilesFieldTarget.classList.add('is-hidden') // Hides studentTeacher Files Field
      this.studentTeacherFilesFieldTarget.querySelector('input').readOnly = true
    } else {
      institutionFields.forEach((field) => field.classList.add('is-hidden'))
      this.countryFieldStudentTarget.appendChild(this.countryFieldTarget) // The country field needs to be moved when changing between the types of request
      this.studentTeacherFilesFieldTarget.classList.remove('is-hidden') // Shows studentTeacher Files Field
      this.studentTeacherFilesFieldTarget.querySelector('input').readOnly = false
    }

    inputFields.forEach((input) => {
      input.readOnly = !isAcademicInstitutionRequest
    })
  }

  showSuccessUI() {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    this.formSuccessTarget.classList.remove('is-hidden')
    this.workspaceController.hideWorkspace()
    this.hideForm()
  }

  toggleAgreement(event) {
    this.agreementValue = event.target.checked
  }

  selectedWorkspaceValueChanged(value) {
    this.selectWorkspaceInputTarget.classList.toggle('is-invalid', !value)

    const isSignedIn = user.isLoggedIn()
    if (value && isSignedIn) {
      this.enableForm()
    } else {
      this.disableForm()
    }
  }

  enableForm() {
    this.educationFormFieldsetTarget.disabled = false
  }

  disableForm() {
    this.educationFormFieldsetTarget.disabled = true
  }

  hideForm() {
    this.educationFormFieldsetTarget.classList.add('is-hidden')
  }

  showForm() {
    this.educationFormFieldsetTarget.classList.remove('is-hidden')
  }

  resetForm() {
    this.formTarget.reset()
  }

  clearFormErrors() {
    this.formTarget.querySelectorAll('.is-invalid').forEach((e) => {
      e.removeAttribute('aria-invalid')
      e.removeClass('is-invalid')
    })
  }

  isLoadingValueChanged(isLoading) {
    if (this.educationFormFieldsetTarget) {
      this.educationFormFieldsetTarget.disabled = isLoading
    }
  }

  // Connect to workspace controller
  // Used to hide the workspace when the form is submitted with success
  get workspaceController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('.workspace'),
      'store--edu--workspace'
    )
  }
}
