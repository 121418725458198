import { Controller } from 'stimulus'
import slugify from 'slugify'

const SIDEBAR_SCROLL_OFFSET = 80

export default class SidebarNav extends Controller {
  static values = { isMobile: Boolean, isLegal: Boolean }

  static targets = [
    'primaryItem',
    'secondaryItem',
    'tertiaryItem',
    'activeItem',
    'primaryGroup',
    'secondaryGroup',
    'tertiaryGroup',
  ]

  connect() {
    this.headingSelector = 'article h2'

    if (!this.isMobileValue) {
      this.initOnThisPage()
    }

    // Hide inactive secondary and tertiary items
    if (this.hasPrimaryGroupTarget && this.isLegalValue) {
      this.hideInactiveItems()
      this.highlightParentElement()
    }

    // Scroll the sidebar navigation to the primary or secondary active menuitem
    this.scrollToActiveItem()
  }

  disconnect() {
    if (this.headingsObserver) {
      this.headingsObserver.disconnect()
    }
  }

  hideInactiveItems() {
    const groups = this.tertiaryGroupTargets.concat(this.secondaryGroupTargets)
    groups.forEach((group) => {
      if (!group.querySelector('.is-active') && !group.previousElementSibling.classList.contains('is-active')) {
        group.classList.add('is-hidden')
      }
    })
  }

  highlightParentElement() {
    const secondaryAndTertiaryElements = this.tertiaryItemTargets.concat(this.secondaryItemTargets)
    const activeElement = secondaryAndTertiaryElements
      .filter((element) => element.classList.contains('is-active'))
      .pop()

    activeElement.closest('ul').previousElementSibling.classList.add('is-active')
  }

  initOnThisPage() {
    const observedHeadings = Array.from(document.querySelectorAll(this.headingSelector))

    if (observedHeadings.length > 1) {
      observedHeadings.forEach((heading) => {
        const item = document.createElement('li')
        item.className = 'on-this-page__item'
        const itemLink = document.createElement('a')
        itemLink.textContent = heading.textContent
        itemLink.className = 'on-this-page__link'
        if (!heading.id) heading.id = slugify(heading.textContent)
        itemLink.setAttribute('href', `#${heading.id}`)
        item.appendChild(itemLink)

        document.getElementById('on-this-page__list').append(item)
      })

      const leadParagraph = document.querySelector('.lead')

      if (leadParagraph) {
        leadParagraph.after(document.getElementById('on-this-page-wrapper'))
      }

      document.getElementById('on-this-page-wrapper').classList.remove('is-hidden')
    }
  }

  get activeItem() {
    return this.activeItemTargets.pop() || null
  }

  scrollToActiveItem() {
    if (this.activeItem) {
      if (this.isMobileValue) {
        this.element.parentElement.scroll({ top: this.activeItem.offsetTop - 40 })
      }
      this.element.scroll({ top: this.activeItem.offsetTop - SIDEBAR_SCROLL_OFFSET })
    }
  }
}
