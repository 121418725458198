import throttle from 'lodash/throttle'

const wrapWords = function (node) {
  const words = node.textContent.trim().split(' ')
  let html = ''
  words.forEach((word, i) => {
    html += '<span class="word-container"><span class="word">' + word + '</span></span>'
    if (i < words.length - 1) {
      html += '<span class="word-container"><span class="word space">&nbsp;</span></span>'
    }
  })
  node.innerHTML = html
}

/**
 * @param {Element} node
 */
const animate = function (node) {
  if (!node.classList.contains('ready')) {
    node.classList.add('ready')
    wrapWords(node)
  }

  const nodeStyle = window.getComputedStyle(node)
  const wordNodes = node.querySelectorAll('.word')
  const lineHeight = parseFloat(nodeStyle['line-height'])
  let posX = 0
  const delay = parseFloat(node.getAttribute('data-animation-delay')) || 0.2
  let d = 0
  let rowNum = 0

  wordNodes.forEach((wordNode) => {
    const wordParent = wordNode.parentElement
    const wordParentStyle = window.getComputedStyle(wordParent)
    const wordWidth = parseFloat(wordParentStyle.width)
    wordParent.style.lineHeight = lineHeight * 1.3 + 'px'
    if (posX + wordWidth > parseFloat(nodeStyle.width)) {
      rowNum += 1
      posX = 0
    }
    wordParent.setAttribute('data-row', rowNum)
    wordParent.style.top = rowNum * lineHeight + 'px'
    wordParent.style.left = posX + 'px'

    if (posX > 0 || !wordNode.classList.contains('space')) {
      posX += wordWidth
    }

    if (!wordNode.classList.contains('space')) {
      wordNode.style.transitionDelay = delay + d * 0.1 + 's'
      d += 1
    }
  })

  // centerize text if needed
  if (nodeStyle.textAlign == 'center') {
    for (let i = 0; i <= rowNum; i++) {
      const wordsInSameRow = node.find('span[data-row="' + i + '"]')
      let rowWidth = 0
      const rowWords = Array.from(wordsInSameRow)
      rowWords.forEach((wordNode, j) => {
        rowWidth +=
          j == rowWords.length - 1 && wordNode.childNodes[0].classList.contains('space') ? 0 : wordNode.offsetWidth
      })
      Array.from(wordsInSameRow).forEach((wordNode) => {
        wordNode.style.left = parseInt(wordNode.style.left) + Math.round((node.offsetWidth - rowWidth) / 2) + 'px'
      })
    }
  }
  node.style.height = (rowNum + 1) * lineHeight + 'px'
}

/**
 * Initialize the word animation in a document/element
 * @param {Element} node
 */
const animateWords = function (node) {
  document.fonts.onloadingdone = () => {
    animate(node)
  }

  window.addEventListener(
    'resize',
    throttle(() => animate(node), 100)
  )

  if (node.classList.contains('animate-words-auto-show')) {
    setTimeout(() => node.classList.add('show'), 200)
  }
}

export default animateWords
