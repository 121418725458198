import { Controller } from 'stimulus'
import { copyToClipboard } from '../../_js/base/utils'

export default class UpdatesController extends Controller {
  static values = {
    previouslySeenClass: String,
    allReadClass: String,
    activePlatform: String,
    successCopy: String,
  }

  static targets = ['timelineEntry', 'newUpdatesCount', 'dropdownCheckbox']

  static MAX_NEW_UPDATES_COUNTER = 10

  static STORAGE_KEY = 'updatesLastVisit'

  lastSeenUpdate

  today = new Date()

  defaultCopyMessage

  connect() {
    const isSingleUpdate = this.element.classList.contains('updates--single')
    if (isSingleUpdate) return

    this.findLastSeenUpdate()
    this.markLastSeenUpdate()
    this.renderNewUpdatesCounter()
    this.updateLastVisitDate()

    if (this.lastSeenUpdate) {
      this.showUnreads()
    }
  }

  selectActiveTab() {
    const tabs = this.application.getControllerForElementAndIdentifier(this.element.querySelector('.tabs'), 'tabs')
    const activeTab = tabs.tabTargets.find((tab) => tab.dataset.slug.toLowerCase() === this.activePlatformValue.toLowerCase()) || tabs.tabTargets[0]
    tabs.toggle(activeTab)
  }

  updateLastVisitDate() {
    window.localStorage.setItem(UpdatesController.STORAGE_KEY, this.today)
  }

  getLastVisitDate() {
    return new Date(window.localStorage.getItem(UpdatesController.STORAGE_KEY)) || this.today
  }

  showUnreads() {
    this.element.classList.remove(this.allReadClassValue)
  }

  findLastSeenUpdate() {
    const newUpdates = this.timelineEntryTargets.filter((entry) => {
      const entryDate = new Date(entry.querySelector('time')?.getAttribute('datetime').replace(/-/g, '/') || null)

      return entryDate > this.getLastVisitDate()
    })

    const oldestNewUpdate = newUpdates[newUpdates.length - 1]
    this.lastSeenUpdate = oldestNewUpdate
  }

  markLastSeenUpdate() {
    if (!this.lastSeenUpdate) return

    this.lastSeenUpdate.classList.toggle(this.previouslySeenClassValue)
  }

  getCountNewUpdates() {
    if (!this.lastSeenUpdate) return 0

    // Looks like an index instead of a count, but we're looking for the index to know how many
    // more elements are there before that one. Hence the + 1 at the end.
    const newUpdatesCount = [...this.lastSeenUpdate.parentElement.children].indexOf(this.lastSeenUpdate) + 1

    return Math.min(newUpdatesCount, UpdatesController.MAX_NEW_UPDATES_COUNTER)
  }

  renderNewUpdatesCounter() {
    const newUpdatesCount = this.getCountNewUpdates()
    // We can't tell the difference between 10 (MAX_NEW_UPDATES_COUNTER) and 11, since we're going
    // to have, at most, 10 updates on this page. This means it will jump from '9' to '10+'.
    const counterText =
      newUpdatesCount >= UpdatesController.MAX_NEW_UPDATES_COUNTER ? `${newUpdatesCount}+` : newUpdatesCount

    this.newUpdatesCountTargets.forEach((target) => {
      target.innerText = counterText
    })
  }

  copyUrlToClipboard({ target }) {
    const defaultCopy = target.getAttribute('data-tooltip-copy')
    const url = target.getAttribute('data-update-url')

    target.setAttribute('data-tooltip-copy-default', defaultCopy)
    target.setAttribute('data-tooltip-copy', this.successCopyValue)

    copyToClipboard(url)
    target.addEventListener('mouseleave', this.bringBackDefaultCopyMessage, { once: true })
  }

  // eslint-disable-next-line class-methods-use-this
  bringBackDefaultCopyMessage({ target }) {
    const defaultCopy = target.getAttribute('data-tooltip-copy-default')
    target.setAttribute('data-tooltip-copy', defaultCopy)
    target.removeAttribute('data-tooltip-copy-default')
  }

  hideDropdown({ target }) {
    const isTargetDropdown = target.parentElement.classList.contains('hero__dropdown')
    if (!isTargetDropdown && !this.dropdownCheckboxTarget.checked) return

    this.dropdownCheckboxTarget.checked = !this.dropdownCheckboxTarget.checked
  }
}
