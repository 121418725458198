// eslint-disable-next-line import/prefer-default-export
export const CREATE_STUDENT_APPLICATION_MUTATION = `
  mutation createStudentApplication($input: CreateStudentApplicationParams) {
    createStudentApplication(input: $input) {
      educationApplication {
        workspace {
          name
        }
      }
    }
  }
`

export const CREATE_TEACHER_APPLICATION_MUTATION = `
  mutation createTeacherApplication($input: CreateTeacherApplicationParams) {
    createTeacherApplication(input: $input) {
      educationApplication {
        workspace {
          name
        }
      }
    }
  }
`

export const CREATE_INSTITUTION_APPLICATION_MUTATION = `
  mutation createInstitutionApplication($input: CreateInstitutionApplicationParams) {
    createInstitutionApplication(input: $input) {
      educationApplication {
        workspace {
          name
        }
      }
    }
  }
`
