//
// Definitions and Utilities
//
import appVersionData from './utils/app-version-data'
import signupMetadata from './utils/signup-metadata'
import setUpSimpleAnalytics from './base/simple-analytics'
import { isLoggedIn } from './helpers/user'

// Force trigger a 'resize' event on page load
// Layout adjustments are triggered on resize, if we don't trigger this when the page is ready we'll
// have a bunch of misalignments. And yes, the setTimeout() is needed as well.

import { ready } from './base/utils'

function changeHomeLinks() {
  var user = isLoggedIn()
  if (user) {
    return
  }
  var linksToChange = document.querySelectorAll('a[href="/home/"]')
  // eslint-disable-next-line no-restricted-syntax
  for (const link of linksToChange) {
    link.setAttribute('href', '/')
  }
}

ready(() => {
  setTimeout(() => {
    window.dispatchEvent(new window.Event('resize'))
  }, 0)

  changeHomeLinks()
})

window.addEventListener('load', () => {
  // Removes is-loading class when DOM is ready
  // Combining with css, we can prevent transition animations to run before the DOM loads
  document.body.classList.remove('is-loading')
})

// Add 'js' class to <html> so we can style based on JS having loaded or not
document.documentElement.classList.replace('no-js', 'js')
// Non DOM-ready dependent initializations
appVersionData.storeFromQueryString()
signupMetadata.storeFromQueryString()

// Check for Do Not Track visitors
var dntActive = parseInt(navigator.msDoNotTrack || window.doNotTrack || navigator.doNotTrack, 10) === 1

if (window.sa_loaded && !dntActive) setUpSimpleAnalytics()

// Import Main scripts for its side-effects
// NOTE: uses require as 'import' would be hoisted, and this should run last
require('./stimulus')
require('./ready')
