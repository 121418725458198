import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'

export default class NativeToMac extends Controller {
  static targets = ['screenshots', 'darkTheme', 'lightTheme']

  connect() {
    this.wipeTransition = throttle(this.wipeTransition.bind(this), 20)
  }

  wipeTransition() {
    const top = this.screenshotsTarget.getBoundingClientRect().top
    const innerHeight = window.innerHeight

    // when the element reaches the bottom of the viewport
    if (top < innerHeight) {
      // set the position of the mask
      const pos = Math.round((100 * (innerHeight - top)) / (innerHeight / 3))

      // mask the screenshots
      this.darkThemeTarget.style.setProperty('--mask-pos-left', `${150 - pos}%`)
      this.darkThemeTarget.style.setProperty('--mask-pos-right', `${190 - pos}%`)

      // change section theme when scroll position reaches this value
      this.element.classList.toggle('theme-dark', pos > 150)
    }
  }
}
