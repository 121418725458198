import { Controller } from 'stimulus'

// eslint-disable-next-line no-restricted-properties
const easeOutExpo = (t, b, c, d) => (t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b)
export default class Counter extends Controller {
  endValue = Number(this.data.get('value')) || 0
  duration = 1200

  connect() {
    this.startValue = Number(this.element.innerHTML)

    const counterNodeObserver =
      'IntersectionObserver' in window
        ? new IntersectionObserver(this.counterObserverCallback.bind(this), { threshold: 1 })
        : null
    if (counterNodeObserver) {
      counterNodeObserver.observe(this.element)
    } else {
      this.startCounting()
    }
  }

  counterObserverCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!entry.target.classList.contains('counting')) {
          this.startCounting()
        }
      }
    })
  }

  startCounting() {
    let time = 0
    const step = 10
    const interval = setInterval(() => {
      time += step
      const val = Math.round(easeOutExpo(time, this.startValue, this.endValue, this.duration))
      this.element.innerHTML = val
      if (time >= this.duration || val == this.endValue) clearInterval(interval)
    }, step)

    this.element.classList.add('counting')
  }
}
