import { Controller } from 'stimulus'
import Overlay from '../../_components/overlay/overlay'
import { BREAKPOINTS } from '../base/consts'
import mediaFrom from '../utils/media-from'

let sheetHeight

const BOTTOM_SHEET_HEIGHT = '101px'
const SCREEN_MID_POINT = 50

export default class BottomSheet extends Controller {
  static targets = ['hero', 'sheet', 'draggable', 'switch', 'switchLabel']

  connect() {
    if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.overlay = new Overlay({
        modifiers: `dark`,
      })
      this.touchEvents()

      this.overlay.on('dismiss', () => {
        this.collapseSheet()
      })
    }

    this.getColorScheme()
  }

  getColorScheme() {
    let colorScheme

    if (localStorage.getItem('colorScheme')) {
      if (localStorage.getItem('colorScheme') == 'dark') {
        colorScheme = 'dark'
      }
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      colorScheme = 'dark'
    } else {
      colorScheme = 'light'
    }

    if (colorScheme == 'dark') {
      this.switchLabelTargets.forEach((switchLabel) => switchLabel.classList.toggle('is-hidden'))
    }

    this.setColorScheme(colorScheme)

    if (!localStorage.getItem('colorScheme')) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
        colorScheme = event.matches ? 'dark' : 'light'
        this.setColorScheme(colorScheme)
      })
    }
  }

  saveColorScheme(event) {
    let colorScheme

    if (event.target.checked) {
      localStorage.setItem('colorScheme', 'dark')
      colorScheme = 'dark'
    } else {
      localStorage.setItem('colorScheme', 'light')
      colorScheme = 'light'
    }

    this.setColorScheme(colorScheme)
  }

  setColorScheme(colorScheme) {
    if (colorScheme == 'dark') {
      this.heroTarget.classList.add('lesson__header--dark')
      this.switchTarget.checked = true
    } else {
      this.heroTarget.classList.remove('lesson__header--dark')
      this.switchTarget.checked = false
    }

    this.switchLabelTargets.forEach((switchLabel) => switchLabel.classList.toggle('is-hidden'))
  }

  setSheetHeight(value) {
    sheetHeight = Math.max(0, Math.min(100, value))
    this.sheetTarget.style.height = `${sheetHeight}vh`
  }

  expandSheet() {
    this.sheetTarget.style.height = null
    this.sheetTarget.classList.add('is-expanded')
  }

  collapseSheet() {
    this.sheetTarget.style.height = BOTTOM_SHEET_HEIGHT
    this.sheetTarget.classList.remove('is-expanded')
    this.overlay.hide()
  }

  touchEvents() {
    if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      let startY

      this.draggableTarget.addEventListener('touchstart', (event) => {
        startY = event.touches[0].clientY
        this.overlay.show()
      })

      this.draggableTarget.addEventListener('touchmove', (event) => {
        if (startY === undefined) return

        const clientHeight = document.documentElement.clientHeight

        const viewportHeight = clientHeight
        const touchY = event.touches[0].clientY
        const deltaY = ((viewportHeight - touchY) / viewportHeight) * 100

        this.sheetTarget.classList.add('is-expanded')
        this.setSheetHeight(deltaY)
      })

      this.draggableTarget.addEventListener('touchend', () => {
        startY = undefined

        if (sheetHeight > SCREEN_MID_POINT) {
          this.expandSheet()
        } else if (sheetHeight < SCREEN_MID_POINT) {
          this.collapseSheet()
        }
      })
    }
  }
}
