import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class DemosSection extends Controller {
  static targets = ['dummyPadding', 'demoElement', 'responsiveLink', 'demosWrapper']

  timer = null

  windowWidth = null

  scrollListener = null

  isDesktop = false

  connect() {
    this.windowWidth = window.innerWidth
    this.isDesktop = this.windowWidth >= BREAKPOINTS.VIEWPORT_XL

    window.addEventListener(
      'resize',
      debounce(() => {
        this.handleResize()
      }, 50)
    )

    if (!this.isDesktop) {
      this.setScrollListener()
      this.updateDummyPaddings()
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize)
    this.unsetScrollListener()
  }

  setScrollListener() {
    this.scrollListener = throttle(() => {
      this.handleSectionScroll()
    }, 50)

    this.demosWrapperTarget.addEventListener('scroll', this.scrollListener)
  }

  unsetScrollListener() {
    this.demosWrapperTarget.removeEventListener('scroll', this.scrollListener)
    this.scrollListener = null
  }

  handleResize() {
    this.windowWidth = window.innerWidth
    this.isDesktop = this.windowWidth >= BREAKPOINTS.VIEWPORT_XL

    if (this.isDesktop) {
      this.unsetScrollListener()
    } else {
      if (!this.scrollListener) {
        this.setScrollListener()
      }
      this.updateDummyPaddings()
    }
  }

  // Handle scroll in the mobile version of the section
  handleSectionScroll() {
    // Disable the button until the scroll is stopped
    if (this.timer !== null) {
      clearTimeout(this.timer)
      this.responsiveLinkTarget.setAttribute('href', '')
      this.responsiveLinkTarget.setAttribute('aria-disabled', true)
      this.responsiveLinkTarget.classList.add('is-disabled')
    }

    this.timer = setTimeout(this.updateCenteredItem.bind(this), 150)
  }

  // Add Padding to dummy elements to center first and last elements
  updateDummyPaddings() {
    const firstElementWidth = this.demoElementTargets[0].getBoundingClientRect().width
    const lastElementWidth = this.demoElementTargets[this.demoElementTargets.length - 1].getBoundingClientRect().width

    this.dummyPaddingTargets[0].style.paddingLeft = window.innerWidth / 2 - firstElementWidth / 2 + 'px'
    this.dummyPaddingTargets[1].style.paddingRight = window.innerWidth / 2 - lastElementWidth / 2 + 'px'
  }

  // Check which item is centered via scroll-snapping to display the corresponding link
  updateCenteredItem() {
    const halfOfScreen = this.windowWidth / 2

    this.demoElementTargets.forEach((element) => {
      const elementHalf = element.getBoundingClientRect().left + element.getBoundingClientRect().width / 2

      if (halfOfScreen - 10 < elementHalf && halfOfScreen + 10 > elementHalf) {
        this.responsiveLinkTarget.setAttribute('href', element.dataset.link)
        this.responsiveLinkTarget.setAttribute('aria-disabled', false)
        this.responsiveLinkTarget.classList.remove('is-disabled')
      }
    })
  }
}
