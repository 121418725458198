import { Controller } from 'stimulus'

export default class ComparisonTable extends Controller {
  static targets = ['container', 'blurToggle', 'blurToggleText']

  isOpen = false

  toggleBlur() {
    this.containerTarget.classList.toggle('is-expanded')
    this.blurToggleTarget.classList.toggle('comparison-table__blur-toggle--is-expanded')
    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.blurToggleTextTarget.textContent = 'See less'
    } else {
      this.blurToggleTextTarget.textContent = 'See more'
    }
  }
}
