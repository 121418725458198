// Function to shuffle the children of any given div

import { shuffle } from 'lodash'

const shuffleChildren = function (parent) {
  const childrenArray = Array.from(parent.children) // Convert children to an array

  const shuffledChildren = shuffle(childrenArray)

  // Re-append the shuffled children
  shuffledChildren.forEach((child) => parent.appendChild(child))
}

export default shuffleChildren
