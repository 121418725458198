import { Controller } from 'stimulus'
import { storageAvailable } from '../../_js/base/feature-detect'

const DEFAULT_OPTIONS = {
  closed: false,
}

export default class extends Controller {
  static targets = ['link']

  static values = {
    id: String,
  }

  connect() {
    const idValue = this.idValue || ''
    this.LOCALSTORAGE_BANNER_KEY = idValue ? `banner:${idValue}` : 'banner'

    // Initialize storage
    if (!storageAvailable('localStorage')) return
    this.bannerOptions = this.getFromStorage()
    if (!this.bannerOptions) {
      this.bannerOptions = DEFAULT_OPTIONS
      this.writeToStorage(this.bannerOptions)
    }

    // If this banner hasn't been closed before
    if (!this.bannerOptions.closed) {
      this.showBanner()
    }
  }

  getFromStorage = () => {
    if (!storageAvailable('localStorage')) return {}

    const stringData = window.localStorage.getItem(this.LOCALSTORAGE_BANNER_KEY)
    if (!stringData) return {}

    try {
      return JSON.parse(stringData)
    } catch (SyntaxError) {
      return {}
    }
  }

  writeToStorage = (objectData) => {
    if (!storageAvailable('localStorage')) return
    window.localStorage.setItem(this.LOCALSTORAGE_BANNER_KEY, JSON.stringify(objectData))
  }

  showBanner() {
    window.setTimeout(() => {
      this.element.classList.remove('js-visibility-hidden')
      this.element.removeAttribute('aria-hidden')

      // Add extra margin to the footer to not cover any elements in it  when the banner is displayed
      this.resizePageFooter()
    }, 100)
  }

  hideBanner() {
    this.bannerOptions.closed = true
    this.bannerOptions.lastClosed = Date.now() // Storing the last action's date will help us when further additional actions needed
    this.writeToStorage(this.bannerOptions)
    this.element.classList.add('js-visibility-hidden')
    this.element.setAttribute('aria-hidden', 'true')

    // Remove extra space from footer
    this.resizePageFooter()
  }

  resizePageFooter() {
    const footer = document.querySelector('.main-footer')
    if (footer) {
      const additionalHeight = this.element.classList.contains('js-visibility-hidden') ? 0 : this.element.offsetHeight
      footer.style.paddingBottom = `${additionalHeight}px`
    }
  }

  openLink() {
    this.bannerOptions.clicked = true
    this.writeToStorage(this.bannerOptions)
    this.hideBanner()
  }
}
