import { Controller } from 'stimulus'
import FocusTrap from '../../_js/utils/focus-trap'
import { KEYS } from '../../_js/base/consts'
/**
 * Dropdown Controller
 *
 * Actions:
 * - toggle - show/hide the dropdown
 *
 * Applies classes:
 * - element:
 *     - .is-active: when the dropdown is active
 *     - .was-active: when the dropdown isn't active, but once was
 */
export default class extends Controller {
  initialize() {
    this._boundEscKeyHandler = this._boundEscKeyHandler.bind(this)
    this._boundClickHandler = this._boundClickHandler.bind(this)
  }

  connect() {
    this.active = false
    this.trap = FocusTrap(this.element, { arrowsV: true })
    document.addEventListener('keydown', this._boundEscKeyHandler)
    document.addEventListener('click', this._boundClickHandler)

    this.element.classList.add('js-is-connected')
  }

  disconnect() {
    this.element.classList.remove('js')
    document.removeEventListener('keydown', this._boundEscKeyHandler)
    document.removeEventListener('click', this._boundClickHandler)
    this.trap.deactivate()

    this.element.classList.remove('js-is-connected')
  }

  _boundEscKeyHandler(event) {
    if (!this.active) return
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode === KEYS.escape) {
      event.preventDefault()
      this.hide()
    }
  }

  /**
   * If user clicked outside the dropdown, or in a link inside the dropdown, close it
   * @param {MouseEvent} event
   */
  _boundClickHandler(event) {
    if (!this.active) return
    if (!this.element.contains(event.target) || (event.target.tagName === 'A' && this.element.contains(event.target))) {
      this.hide()
    }
  }

  show() {
    this.active = true
    // Separate .remove() and .add() instead of .replace(), since
    // 'was-active' will only be present after the first hide()
    this.element.classList.remove('was-active')
    this.element.classList.add('is-active')
    this.trap.activate()
  }

  hide() {
    this.active = false
    this.element.classList.replace('is-active', 'was-active')
    this.trap.deactivate()
  }

  toggle() {
    if (this.active) {
      this.hide()
    } else {
      this.show()
    }
  }
}
