import { Controller } from 'stimulus'
import Overlay from '../overlay/overlay'

export default class QuickFactsModal extends Controller {
  static targets = ['modalContent']

  connect() {
    this.modal = new Overlay({
      modifiers: 'prism',
      shouldDismissOnEsc: true,
      hasCloseButton: true,
    })
    this.buildModal()
  }

  buildModal() {
    this.modalContentTarget.classList.remove('visuallyhidden')
    this.modal.appendContent(this.modalContentTarget)
  }

  showModal() {
    this.modal.show()
  }
}
