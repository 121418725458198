import { KEYS } from '../base/consts'
import { focusableElements, firstFocusableElement } from '../helpers/focus'

/**
 * Focus Trap factory function
 * @param {Element} node                                - the element to trap the focus in
 * @param {Object} options                              - options
 * @param {boolean} [options.grabFocusOnActivate=true]  - whether to grab focus on activation
 * @param {boolean} [options.arrowsH=false]                - whether to tab with left/right arrows
 * @param {boolean} [options.arrowsV=false]                - whether to tab with up/down arrows
 * @returns {{ activate: function(), deactivate: function()}}
 */
const FocusTrap = function(node, { grabFocusOnActivate = true, arrowsH = false, arrowsV = false } = {}) {
  /** @type {Element} - placeholder for element that is focused before trap is activated */
  let originallyFocused

  /**
   * Focus in the node, giving priority to any element with the autofocus property.
   * @param {boolean} saveCurrentFocus - whether to save the currently focused element to restore focus later
   */
  const grabFocus = function(saveCurrentFocus = false) {
    if (saveCurrentFocus) {
      originallyFocused = document.activeElement
    }

    const elementToFocus = node.querySelector('[autofocus]') || firstFocusableElement(node)

    if (elementToFocus) {
      elementToFocus.focus()
    }
  }

  /**
   * Restores focus to the element that was focused (if any) before the dialog was shown.
   */
  const restoreFocus = function() {
    if (!originallyFocused) return

    originallyFocused.focus()
    originallyFocused = undefined
  }

  /**
   * Handle tabbing in the node, so tabbing only cycles through elements
   * inside the node, and focus can't escape outside the node.
   * @param {KeyboardEvent} event - the keyboard event
   */
  const tabKeyHandler = function(event) {
    // Only handles tabs
    if (!(event.key == 'Tab' || event.keyCode == KEYS.tab)) return

    // Ignore modifier keys other than shift, to allow people to do
    // browser/OS shortcuts without side-effects on the page.
    if (event.altKey || event.ctrlKey || event.metaKey) return

    const tabDirection = event.shiftKey ? 'backward' : 'forward'
    const elements = focusableElements(node)
    const focusedElementIndex = elements.indexOf(document.activeElement)

    /**
     * Tabbing will work naturally, unless...
     * 1. We're on the first focusable element and want to go backward
     * 2. We're on the last focusable element and want to go forward
     * ...in which case we have to loop, trapping the tabbing in the dialog
     */
    if (focusedElementIndex == 0 && tabDirection === 'backward') {
      elements[elements.length - 1].focus()
      event.preventDefault()
    } else if (focusedElementIndex == elements.length - 1 && tabDirection === 'forward') {
      elements[0].focus()
      event.preventDefault()
    }
  }

  /**
   * Handles arrow keys in the node, so that up/left tab backwards,
   * and down/right move forwards, as typically used in menus.
   * @param {KeyboardEvent} event - the keyboard event
   */
  const arrowKeysHandler = function(event) {
    // Ignore modifier keys to allow people to do browser/OS
    // shortcuts without side-effects on the page.
    if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey) return

    // Build a keymap of keys to be detected, based on options
    let keymap = {}
    if (arrowsH) keymap = { ...keymap, ArrowLeft: 'backward', ArrowRight: 'forward' }
    if (arrowsV) keymap = { ...keymap, ArrowUp: 'backward', ArrowDown: 'forward' }

    const direction = keymap[event.key]

    // Bypass if keys pressed don’t match corresponding options
    if (typeof direction === 'undefined') return

    // Don’t go up/down the page
    event.preventDefault()

    const elements = focusableElements(node)
    const focusedElementIndex = elements.indexOf(document.activeElement)

    const nextFocusedElement = elements[focusedElementIndex + (direction === 'forward' ? 1 : -1)]
    if (typeof nextFocusedElement !== 'undefined') {
      nextFocusedElement.focus()
    }
  }

  /**
   * Handle the focus event, bringing back focus into the dialog if it goes outside
   * This shouldn't happen because tabKeyHandler is handling tabbing, but
   * it's used to prevent external code, possibly 3rd party code, from stealing focus
   *
   * @param {FocusEvent} event - the focus event
   */
  const focusHandler = function(event) {
    if (!node.contains(event.target)) {
      grabFocus()
    }
  }

  /**
   * Activate the focus trap:
   * 1. Grabs focus immediately
   * 2. Takes control of the tab key presses so focus remains in the node
   * 3. Restores focus to the node if it leaves for some other reason
   */
  const activate = function() {
    if (grabFocusOnActivate) {
      if (!node.contains(document.activeElement)) {
        grabFocus(true)
      }
    }
    document.addEventListener('keydown', tabKeyHandler)
    if (arrowsH || arrowsV) {
      document.addEventListener('keydown', arrowKeysHandler)
    }
    document.addEventListener('focusin', focusHandler)
  }

  /**
   * Deactivates the focus trap:
   * 1. Removes handling of the tab key and
   * 2. Removes handling of focus event
   * 3. Restores focus to the element that was previously focused
   */
  const deactivate = function() {
    document.removeEventListener('keydown', tabKeyHandler)
    if (arrowsH || arrowsV) {
      document.removeEventListener('keydown', arrowKeysHandler)
    }
    document.removeEventListener('focusin', focusHandler)
    restoreFocus()
  }

  return {
    activate,
    deactivate,
  }
}

export default FocusTrap
