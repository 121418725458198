import { Controller } from 'stimulus'

const DEFAULT_THRESHOLD = 12

export default class OverflowBlurController extends Controller {
  static targets = ['overflowDiv']

  static values = {
    threshold: Number,
  }

  availableScroll

  threshold

  connect() {
    this.threshold = this.thresholdValue ? this.thresholdValue : DEFAULT_THRESHOLD

    this.overflowDivTarget.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })

    this.availableScroll = this.overflowDivTarget.scrollWidth - this.overflowDivTarget.offsetWidth

    if (this.availableScroll > this.threshold) {
      this.overflowDivTarget.classList.add('overflow-blur')
    }
  }

  handleScroll() {
    if (this.overflowDivTarget.scrollLeft > this.threshold) {
      this.overflowDivTarget.classList.add('overflow-blur--scrolled')
    } else {
      this.overflowDivTarget.classList.remove('overflow-blur--scrolled')
    }
  }
}
