import { Controller } from 'stimulus'

export default class HeroController extends Controller {
  static targets = ['illustrations']

  connect() {
    this.randomizeIllustrations()
  }

  randomizeIllustrations() {
    const temp = this.illustrationsTarget.cloneNode(true) // clone the list
    for (var i = temp.children.length + 1; i--; ) {
      // eslint-disable-next-line no-bitwise
      temp.appendChild(temp.children[(Math.random() * i) | 0])
    }
    this.illustrationsTarget.parentNode.replaceChild(temp, this.illustrationsTarget) // copy shuffled back to 'div'

    this.illustrationsTarget.children[0].style.setProperty('--entrance-animation-delay', '700ms')
    this.illustrationsTarget.children[1].style.setProperty('--entrance-animation-delay', '600ms')
    this.illustrationsTarget.children[2].style.setProperty('--entrance-animation-delay', '800ms')
    this.illustrationsTarget.children[3].style.setProperty('--entrance-animation-delay', '900ms')

    setTimeout(() => {
      this.illustrationsTarget.classList.remove('no-opacity')
    }, 400)
  }
}
