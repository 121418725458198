import { Controller } from 'stimulus'

import Swiper, { Navigation, Grid, Pagination, Mousewheel, EffectFade } from 'swiper'

export default class DiscoverController extends Controller {
  static targets = ['link', 'swipers', 'navigation', 'horizontalLine']

  connect() {
    if (localStorage.getItem('theme') === 'dark') {
      document.body.classList.add('discover--dark')
    }

    /**
     * Represents the version of the Discover page
     * @type {string}
     */
    const version = window.location.href.includes('/app/') ? 'mac-app' : 'web-app'

    Swiper.use([Navigation, Grid, Pagination, Mousewheel, EffectFade])

    this.swipersTargets.forEach((swiper) => {
      const swiperId = swiper.getAttribute('id')

      let loop
      let effect
      let slidesPerView

      if (swiperId === 'updates') {
        slidesPerView = 1
        loop = true
        effect = 'fade'
      } else if (swiperId === 'videos') {
        if (version != 'web-app') {
          slidesPerView = 2
        }
      }

      const swiperInstance = new Swiper(swiper, {
        slidesPerView: slidesPerView || (version === 'web-app' ? 2 : 4), // Default to 2 if version is web-app, otherwise default to 4
        spaceBetween: version === 'web-app' ? 40 : 72, // If the version is web-app, set the space between slides to 40, otherwise set it to 72
        navigation: {
          nextEl: `#${swiperId} .swiper-button-next`,
          prevEl: `#${swiperId} .swiper-button-prev`,
        },
        pagination: swiperId === 'updates' ? { el: `#${swiperId} .swiper-pagination`, clickable: true } : false, // If the swiperId is updates, add pagination, otherwise set it to false
        loop: loop || false,
        effect: effect || 'slide',
        fadeEffect: {
          crossFade: true,
        },
        mousewheel: swiperId === 'updates' ? false : { forceToAxis: true }, // If the swiperId is updates, set mousewheel to false, otherwise set it to true
        breakpoints:
          version === 'web-app'
            ? {
                768: { slidesPerView: slidesPerView || 3 },
                1024: { slidesPerView: slidesPerView || 4 },
                1440: { slidesPerView: slidesPerView || 5 },
                1920: { slidesPerView: slidesPerView || 6 },
                2560: { slidesPerView: slidesPerView || 7 },
              }
            : {
                860: {
                  slidesPerView: slidesPerView || 5,
                },
                1040: {
                  slidesPerView: slidesPerView || 6,
                },
                1190: {
                  slidesPerView: swiperId === 'updates' ? slidesPerView : slidesPerView + 1 || 7,
                },
                1350: {
                  slidesPerView: swiperId === 'updates' ? slidesPerView : slidesPerView + 2 || 8,
                },
              },
      })

      swiperInstance.init()
    })

    this.originalTop = this.navigationTarget.offsetTop
    window.addEventListener('scroll', this.checkPosition.bind(this))

    if (this.hasHorizontalLineTarget) {
      window.addEventListener('scroll', this.addHorizontalLine.bind(this))
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.checkPosition.bind(this))

    if (this.hasHorizontalLineTarget) {
      window.removeEventListener('scroll', this.addHorizontalLine.bind(this))
    }
  }

  checkPosition() {
    const top = window.scrollY || window.scrollY

    // Add a class to the navigation target if the scroll position is greater than or equal to the original top position plus 96 pixels
    if (top >= this.originalTop + 80) {
      this.navigationTarget.classList.add('is-fixed')
    }
    // Remove the class from the navigation target if the scroll position is less than the original top position
    else if (top < this.originalTop) {
      this.navigationTarget.classList.remove('is-fixed')
    }
  }

  // As soon as the page is scrolled by at least 1px, add the class to the horizontal line target
  addHorizontalLine() {
    if (window.scrollY > 0) {
      this.horizontalLineTarget.classList.add('is-visible')
    } else {
      this.horizontalLineTarget.classList.remove('is-visible')
    }

    if (this.navigationTarget.classList.contains('is-fixed')) {
      this.horizontalLineTarget.classList.add('no-shadow')
    } else {
      this.horizontalLineTarget.classList.remove('no-shadow')
    }
  }
}
