import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class Parallax extends Controller {
  static values = {
    speedX: Number,
    speedY: Number,
    desktopOnly: Boolean,
  }

  connect() {
    this.scrollEventHandler = this.scrollEventHandler.bind(this)
    window.addEventListener('resize', this.scrollEventHandler)

    window.addEventListener('scroll', this.scrollEventHandler, { passive: true })
    // Trigger the scroll handler a first time when the page loads
    this.scrollEventHandler()
  }

  scrollEventHandler() {
    const distanceFromBottom = this.element.getBoundingClientRect().top - window.innerHeight
    const isDesktop = this.desktopOnlyValue && window.innerWidth >= BREAKPOINTS.VIEWPORT_L
    this.element.style.setProperty(
      '--parallax-y',
      !this.desktopOnlyValue || isDesktop ? `${distanceFromBottom * this.speedYValue}px` : 0
    )
    this.element.style.setProperty(
      '--parallax-x',
      !this.desktopOnlyValue || isDesktop ? `${distanceFromBottom * this.speedXValue}px` : 0
    )
  }
}
