import pick from 'lodash/pick'

import { storageAvailable } from '../base/feature-detect'

export const LOCALSTORAGE_USER_KEY = 'user:profile'
export const LOCALSTORAGE_USER_AUTHORIZATION_KEY = 'user:allAuthorizations'
export const LOCALSTORAGE_LAST_WORKSPACE_ID = 'lastWorkspaceId'

// Props to select from data
const PROFILE_KEYS = [
  'id',
  'name',
  'email',
  'isVerified',
  'hasAvatar',
  'avatar',
  'hasPersonalIdentity',
  'hasUnreadNotifications',
  'hasUnseenNotifications',
  'featureFlags',
]

// Checks if any user is currently logged in
export const isLoggedIn = () => {
  if (!storageAvailable('localStorage')) return false

  // If we are missing the "allAuthorizations" keys in localStorage the user
  // is not signed in and we make sure the profile is also cleared
  if (!localStorage.getItem(LOCALSTORAGE_USER_AUTHORIZATION_KEY)) {
    localStorage.removeItem(LOCALSTORAGE_USER_KEY)
    return false
  }

  try {
    const data = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_KEY))
    return typeof data === 'object' && 'me' in data
  } catch {
    return false
  }
}

// Grab current logged in user profile from localstorage
export const currentUser = () => {
  if (!isLoggedIn()) {
    return undefined
  }

  const userData = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_KEY)).me

  return pick(userData, PROFILE_KEYS)
}

// Grab all feature flag array from localstorage
export const getFeatureFlags = () => currentUser()?.featureFlags ?? []

export const getLastWorkspace = () => {
  if (!storageAvailable('localStorage')) return false
  if (!isLoggedIn()) return undefined

  return localStorage.getItem(LOCALSTORAGE_LAST_WORKSPACE_ID)
}

// Finds a particular feature flag
export const findFeatureFlag = (flag) => {
  const flags = getFeatureFlags()

  return flags.includes(flag)
}
