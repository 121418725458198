import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import Swiper, { Navigation, Pagination } from 'swiper'
import { copyToClipboard } from '../base/utils'
import mediaFrom from '../utils/media-from'
import { BREAKPOINTS } from '../base/consts'

export default class BTC extends Controller {
  static targets = [
    'collapsedTags',
    'moreTagsButton',
    'swiperWrapper',
    'heroSlider',
    'navButtonPrev',
    'navButtonNext',
    'featuredPosts',
  ]

  static values = {
    active: Boolean,
  }

  currentHeroClassName

  currentHeaderClassName

  currentInnerWidth

  defaultCopy

  connect() {
    if (this.hasHeroSliderTarget) {
      this.initHeroSlider()
    }

    if (this.hasFeaturedPostsTarget && !mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.initFeaturedPostsSlider()
    }
  }

  handleFeaturedPostsSliderLifecycle() {
    if (this.featuredPostsSlider && !mediaFrom(BREAKPOINTS.VIEWPORT_L)) return

    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      if (this.featuredPostsSlider) {
        this.featuredPostsSlider.destroy()
        this.featuredPostsSlider = null
      }
    } else if (!this.featuredPostsSlider) {
      this.initFeaturedPostsSlider()
    }
  }

  handleWindowResize() {
    debounce(this.handleFeaturedPostsSliderLifecycle.bind(this), 100)()
  }

  initFeaturedPostsSlider() {
    this.featuredPostsSlider = new Swiper('.btc-featured-posts__slider', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      modules: [Pagination, Navigation],
      mousewheel: true,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }

  calculateNavigationPositions() {
    const activeSliderImageSize = this.heroSlider.el
      .querySelector('.swiper-slide-active .btc-article-image')
      .getBoundingClientRect()

    let distanceMargin = -28
    if (window.innerWidth >= BREAKPOINTS.VIEWPORT_2XL) {
      distanceMargin = 100
    }

    // Calculate Navigation Prev Button position
    this.navButtonPrevTarget.style.top = activeSliderImageSize.height / 2 + 'px'
    this.navButtonPrevTarget.style.left = activeSliderImageSize.left - distanceMargin + 'px'
    this.navButtonPrevTarget.classList.remove('opacity-0')

    // Calculate Navigation Next Button position
    this.navButtonNextTarget.style.top = activeSliderImageSize.height / 2 + 'px'
    this.navButtonNextTarget.style.right = activeSliderImageSize.left - distanceMargin + 'px'
    this.navButtonNextTarget.classList.remove('opacity-0')
  }

  initHeroSlider() {
    const swiperWrapper = this.swiperWrapperTarget
    const heroSlider = this.heroSliderTarget
    this.currentInnerWidth = window.innerWidth

    this.heroSlider = new Swiper('.btc-hero-slider__slider', {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 32,
      slideToClickedSlide: true,
      modules: [Pagination, Navigation],
      loop: true,
      autoHeight: true,
      breakpoints: {
        1220: {
          threshold: 50,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChange: function () {
          const activePostCategory = swiperWrapper.children[this.activeIndex].dataset.postCategory
          const activePostTheme = swiperWrapper.children[this.activeIndex].dataset.postTheme
          const btcHeader = document.querySelector('.blog-homepage')

          heroSlider.dataset.category = activePostCategory
          heroSlider.dataset.theme = activePostTheme
          btcHeader.dataset.category = activePostCategory
          btcHeader.dataset.theme = activePostTheme
        },

        afterInit: debounce(() => {
          this.calculateNavigationPositions()
        }, 0),

        beforeResize: debounce(() => {
          if (this.currentInnerWidth !== window.innerWidth) {
            if (this.navButtonPrevTarget.classList.contains('opacity-0')) {
              return
            }

            this.navButtonPrevTarget.classList.add('opacity-0')
            this.navButtonNextTarget.classList.add('opacity-0')
          }
        }, 0),

        resize: debounce(() => {
          if (this.currentInnerWidth !== window.innerWidth) {
            this.currentInnerWidth = window.innerWidth
            this.calculateNavigationPositions()
          }
        }, 200),
      },
    })
  }

  // eslint-disable-next-line class-methods-use-this
  showTags(event) {
    const parent = event.currentTarget.parentNode
    const collapsedTags = parent.querySelectorAll('.btc-tag--is-collapsed')
    collapsedTags.forEach((tag) => {
      tag.classList.remove('btc-tag--is-collapsed')
    })
    event.currentTarget.classList.add('is-hidden')
  }

  copyUrlToClipboard({ currentTarget }) {
    const url = currentTarget.getAttribute('data-update-url')

    currentTarget.setAttribute('aria-label', currentTarget.getAttribute('data-tooltip-copy-success'))

    copyToClipboard(url)
    currentTarget.addEventListener('mouseleave', this.bringBackDefaultCopyMessage, { once: true })
  }

  // eslint-disable-next-line class-methods-use-this
  bringBackDefaultCopyMessage({ target }) {
    const defaultCopy = target.getAttribute('data-tooltip-copy-default')
    target.setAttribute('aria-label', defaultCopy)
  }
}
