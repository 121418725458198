import { Controller } from 'stimulus'

export default class PressInformationController extends Controller {
  static targets = ['select']

  download(event) {
    event.preventDefault()
    console.log('test')
    window.location.href = this.selectTarget.value
  }
}
