/**
 * This file holds initialization code that's layout-dependent.
 * Whereas script.js should run regardless of the layout in use, this doesn't need to.
 */

import { ready } from './base/utils'

import animateWords from './components/animate-words'
import reveal from './components/reveal'
import Toggleable from './components/toggleable'

// Non-Stimulus components
const domInitializers = {
  // Toggleables
  '.js-toggleable': (n) => new Toggleable(n),

  // Reveal elements when scrolled to
  '.reveal': (n) => reveal(n),

  // Word-by-word animations
  '.animate-words': (n) => animateWords(n),
}

ready(function () {
  // All initializers
  Object.keys(domInitializers).forEach((selector) => {
    document.querySelectorAll(selector).forEach(domInitializers[selector])
  })
})
