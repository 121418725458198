import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class HeroGradient extends Controller {
  static values = {
    speedX: Number,
    speedY: Number,
    desktopOnly: Boolean,
  }

  connect() {
    this.handleParallax = this.handleParallax.bind(this)
    window.addEventListener('scroll', this.handleParallax, { passive: true })

    this.handleParallax(true)
  }

  handleParallax(addOpacity = false) {
    const parentHeight = document.querySelector(`.hero-gradient__illustration`).getBoundingClientRect().height
    const distanceFromBottom = this.element.getBoundingClientRect().top - parentHeight
    const isDesktop = this.desktopOnlyValue && window.innerWidth >= BREAKPOINTS.VIEWPORT_L

    this.element.style.setProperty(
      '--parallax-y',
      !this.desktopOnlyValue || isDesktop ? `${distanceFromBottom * this.speedYValue}px` : 0
    )
    this.element.style.setProperty(
      '--parallax-x',
      !this.desktopOnlyValue || isDesktop ? `${distanceFromBottom * this.speedXValue}px` : 0
    )

    if (addOpacity) {
      this.element.classList.add('hero-gradient__illustration__element__image--visible')
    }
  }
}
