const SITE_FUNCTIONALITY_PARAMS = [
  'add',
  'auth',
  'buildNumber',
  'buildVersion',
  'bundleIdentifier',
  'cpuType',
  'email', // This is needed in the /store/payment-complete/ page
  'expiration_date',
  'isRosetta',
  'license_id',
  'orderid',
  'osVersion',
  'seats',
  'serial',
]

const PERSONAL_IDENTIFIABLE_INFORMATION = [
  'access-token',
  'address',
  'city',
  'contact-description',
  'contact-email',
  'contact-summary',
  'country',
  'course-institution',
  'course',
  'file',
  'institution',
  'license-email',
  'license-key',
  'name',
  'poll-comment',
  'postal-code',
  'region-state',
  'school-contact-info',
  'vat-id',
]

export { SITE_FUNCTIONALITY_PARAMS, PERSONAL_IDENTIFIABLE_INFORMATION }
