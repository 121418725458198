import h from 'hyperscript'
import { forceReflow } from '../../_js/base/utils'
import Dialog from '../dialog/dialog'

export default class MediaDialog extends Dialog {
  /**
   * Media options keys accepted by the constructor
   */
  static OPTION_DEFAULTS = {
    'aspect-ratio': '16:9', // Used when intrinsic aspect ratio is impossible
    height: '720', // Used as a source for calculating width based on aspect ratio
  }

  /**
   * List of recognized media types
   */
  static TYPES = {
    youtube: [
      /youtube.com\/watch\?(?:.*&)?v=([^&# ]+)/,
      /youtube.com\/(?:v|embed)\/([^/?# ]+)/,
      /youtube-nocookie.com\/embed\/([^/?# ]+)/,
      /youtu.be\/([^/?# ]+)/,
    ],
    video: [/(https?:\/\/cdn.sketch.com\/.+\.mp4)/],
    // image: [/(\.jpg|jpeg|png|gif)$/],
  }

  /**
   * Create a new MediaDialog
   * @param {string}  url                         - URL of the media to show
   * @param {Object}  [opts]
   * @param {boolean} [opts.shouldAutoShow=false] - whether to show the dialog on instantiation
   * @param {Object}  [opts.options={}]           - misc. display/configuration options for the media
   */
  constructor(url, { shouldAutoShow = false, options = {} } = {}) {
    super({ modifiers: 'media' })
    this.media = {
      url,
      options: { ...MediaDialog.OPTION_DEFAULTS, ...options },
      ...MediaDialog.findMediaTypeAndRef(url),
    }

    if (!this.media.type) {
      throw new Error('MediaDialog url doesn’t match any media type')
    }

    this.content = this.render()

    if (shouldAutoShow) {
      forceReflow().then(() => this.show())
    }
  }

  /**
   * Finds the media type and reference for a given URL
   * @param {string} url
   * @returns {{type?: string, key?: string}}
   */
  static findMediaTypeAndRef(url) {
    for (let i = 0; i < Object.keys(MediaDialog.TYPES).length; i++) {
      const type = Object.keys(MediaDialog.TYPES)[i]
      const pattern = MediaDialog.TYPES[type].find((p) => new RegExp(p).test(url))
      if (pattern) {
        const ref = new RegExp(pattern).exec(url)[1]
        return {
          type,
          ref,
        }
      }
    }
    return {}
  }

  /**
   * Calculates width and height based on options
   * @readonly
   * @returns {{width: number, height: number}}
   */
  get dimensionsFromOptions() {
    const [ratioW, ratioH] = this.media.options['aspect-ratio']
      .match(/(\d+):(\d+)/)
      .slice(1)
      .map((i) => parseFloat(i))

    let width
    let height

    if (this.media.options.width) {
      width = Math.round(parseFloat(this.media.options.width))
      height = (width * ratioH) / ratioW
    } else if (this.media.options.height) {
      height = Math.round(parseFloat(this.media.options.height))
      width = (height * ratioW) / ratioH
    }

    return {
      width,
      height,
    }
  }

  /**
   * Renders the media as an HTMLElement
   * @returns {HTMLElement}
   */
  render() {
    switch (this.media.type) {
      case 'youtube':
        return h(
          'figure.aspect-ratio',
          { 'data-aspect-ratio-ratio-value': this.media.options['aspect-ratio'] },
          h('iframe.aspect-ratio__fill', {
            ...this.dimensionsFromOptions,
            src: `https://www.youtube-nocookie.com/embed/${this.media.ref}?autoplay=1&modestbranding=1&rel=0`,
            frameborder: 0,
            allow: 'autoplay; fullscreen; encrypted-media; picture-in-picture',
          })
        )
      case 'video':
        return h(
          'figure',
          h(
            'video.dialog__video',
            {
              controls: true,
              playsinline: true,
              controlslist: 'nodownload',
              autoplay: true,
            },
            h('source', { src: this.media.ref })
          )
        )
      default:
        throw new Error('Can’t render MediaDialog with unknown media type')
    }
  }
}
