import camelCase from 'lodash/camelCase'
import { subDays } from 'date-fns'

import API from '../base/api-fetch'
import { REGEX } from '../base/consts'
import { niceDateFromTimestamp, currencyFormatter } from '../base/utils'

/**
 * @typedef {Object} LicenseOverviewData
 * @property {string} key - license key for the license
 * @property {number} current_seats
 * @property {number} current_update_expiration
 * @property {number} days_left
 * @property {number} monthsLeft
 * @property {number} purchase_date - unix timestamp of purchase date
 * @property {number} used_seats
 * @property {boolean} can_renew
 * @property {boolean} can_add_seats
 * @property {boolean} expired
 * @property {boolean} institution
 * @property {boolean} student
 * @property {boolean} void
 */

/**
 * @typedef {Object} LicensePaymentData
 * @property {number} status
 * @property {string} time - unix timestamp
 * @property {number} data.matches
 * @property {boolean} data.expired
 * @property {number} data.current_seats
 * @property {number} data.extra_seats
 * @property {number} data.new_number_of_seats
 * @property {number} data.current_update_expiration - unix timestamp
 * @property {number} data.used_seats
 * @property {number} data.normal_total
 * @property {number} data.months_left
 * @property {boolean} data.student
 * @property {number} data.price_per_seat
 * @property {number} data.total
 */

export default class License {
  static MIN_VOLUME_LICENSE = 2

  static MAX_DAYS_BEFORE_RENEWAL = 90

  static QUERY_STRING_KEYS = ['serial', 'license_id'] // Used by controllers

  renewalSeatPrice = null

  isExpired = true

  renewalNewExpiryDate = null

  proratedAddSeatPrice = null

  monthsLeft = null

  static stateIds = {
    ACTIVE: 0,
    EXPIRING: 1,
    EXPIRED: 2,
  }

  static statesDict = {
    0: 'Active',
    1: 'Expires',
    2: 'Expired',
  }

  static strings = {
    WARNING_REDEMPTIONS_BLOCKED:
      'This license has been blocked for redemptions and is not eligible for credit towards a subscription.',
  }

  constructor({ registration, key }) {
    if (typeof registration !== 'undefined') {
      this.registrations = [registration]
    }
    if (typeof key !== 'undefined') {
      this.key = key
    }
    if (!this.key && !this.registrations) {
      throw new Error('One of registration id or license key is required.')
    }
  }

  /**
   * Create a new License from URL params, if possible
   * @returns {?License}
   */
  static newFromURL() {
    const params = new URLSearchParams(window.location.search)

    if (params.has('license_id') && params.get('license_id') !== '') {
      return new License({ registration: params.get('license_id') })
    }

    if (params.has('serial')) {
      const key = License.serialToKey(params.get('serial'))
      if (License.isKeyFormatValid(key)) {
        return new License({ key })
      }
    }

    return null
  }

  /**
   * Checks whether a license key has a valid format
   * @param {string} key
   * @returns {boolean}
   */
  static isKeyFormatValid(key) {
    return REGEX.licenseKey.test(key)
  }

  /**
   * Converts license key to encoded "serial" format
   * @param {string} key - license key, starting with SK3-
   * @returns {string} base64-encoded license key
   */
  static keyToSerial(key) {
    return window.btoa(key)
  }

  /**
   * Decodes license in "serial" format into SK3- key format
   * @param {string} serial - base64 encoded license key
   * @returns {string} license key, in SK3-* format
   */
  static serialToKey(serial) {
    return window.atob(serial)
  }

  set key(newKey) {
    if (License.isKeyFormatValid(newKey)) {
      this._key = newKey
    } else {
      const decodedNewKey = License.serialToKey(newKey)
      if (License.isKeyFormatValid(decodedNewKey)) {
        this._key = decodedNewKey
      } else {
        throw new Error('License key doesn’t match expected format')
      }
    }
  }

  get key() {
    return this._key
  }

  /**
   * Base64 encrypted version of the license key, for using as a parameter
   * @returns {string}
   */
  get serializedKey() {
    return License.keyToSerial(this.key)
  }

  /**
   * Whether the license is a volume license
   * @returns {boolean}
   */
  get isVolumeLicense() {
    return this.maxSeats >= License.MIN_VOLUME_LICENSE
  }

  /**
   * Param object for identifying the license in API calls
   * @returns {Object.<string, string>}
   */
  get apiQueryParam() {
    return this.registrations && this.registrations[0]
      ? { license_id: this.registrations[0] }
      : { 'license-key': this.key }
  }

  /**
   * Key/value for identifying the license in frontend URLs
   * @returns {({license_id: string}|{serial: string})}
   */
  get urlParam() {
    if (this.registrations && this.registrations[0]) {
      return { license_id: this.registrations[0] }
    }
    if (this.key) {
      return { serial: this.serializedKey }
    }
    return undefined
  }

  /**
   * Query string for identifying the license in frontend URLs
   * @returns {string}
   */
  get urlQueryString() {
    return new URLSearchParams(this.urlParam).toString()
  }

  /**
   * Fetches data for a license
   * @returns {Promise<LicenseOverviewData>}
   */
  async fetchData() {
    const response = await API.post('/licensemanager/license/overview/', this.apiQueryParam)
    const body = await response.json()

    if (!body.status || Number(body.status) !== 1) {
      throw new Error('Failed to fetch data')
    }

    const dataRenameMap = {
      current_seats: 'maxSeats',
      current_update_expiration: 'expirationTimestamp',
      purchase_date: 'purchaseTimestamp',
      expired: 'isExpired',
      institution: 'isInstitution',
      student: 'isStudent',
      void: 'isVoid',
      can_use_cloud: 'hasCloudRights',
      can_add_seats: 'canAddSeats',
      obfuscated_key: 'obfuscatedKeyDigits',
      has_redemptions_blocked: 'hasRedemptionsBlocked',
      new_update_expiration: 'renewalNewExpiryDate',
      renew_seat_price: 'renewalSeatPrice',
      prorated_add_seat_price: 'proratedAddSeatPrice',
      months_left: 'monthsLeft',
    }

    // Rename props if in map, or camelcase
    Object.entries(body.data).forEach(([key, value]) => {
      const newKey = Object.prototype.hasOwnProperty.call(dataRenameMap, key) ? dataRenameMap[key] : camelCase(key)
      this[newKey] = value
    })

    return this
  }

  /**
   * Whether the license is about to expire
   * @returns {boolean}
   */
  get isExpiring() {
    return this.daysLeft <= License.MAX_DAYS_BEFORE_RENEWAL
  }

  /**
   * Total amount of seats in the license
   * @returns {number}
   */
  get totalSeats() {
    return this.maxSeats
  }

  /**
   * Amount of unused seats in the license
   * @returns {number}
   */
  get unusedSeats() {
    return this.maxSeats - this.usedSeats
  }

  /**
   * Expiration date
   * @returns {Date}
   */
  get expirationDate() {
    return new Date(this.expirationTimestamp * 1000)
  }

  /**
   * Expiration date formatted according to the user's location
   * @returns {string}
   */
  get expirationDateFormatted() {
    return niceDateFromTimestamp(this.expirationTimestamp)
  }

  /**
   * Whether the license is active (not expiring nor expired)
   * @returns {boolean}
   */
  get isActive() {
    return !this.isExpiring && !this.isExpired
  }

  /**
   * Whether the license is either from an institution or a student
   * @returns {boolean}
   */
  get isEducational() {
    return this.isInstitution || this.isStudent
  }

  /**
   * Renewal date according to the license expiry date
   * @returns {string}
   */
  get renewalDate() {
    return subDays(this.expirationDate, License.MAX_DAYS_BEFORE_RENEWAL)
  }

  /**
   * Renewal date formatted according to the user's location
   * @returns {string}
   */
  get renewalDateFormatted() {
    return niceDateFromTimestamp(this.renewalDate.getTime() / 1000)
  }

  /**
   * Whether the license has workspace credit or not
   * @return {boolean}
   */
  get hasWorkspaceCredit() {
    return this.monthsLeft > 0
  }

  /**
   * Get descriptive string for current state id
   * @returns {string}
   */
  get stateString() {
    return License.statesDict[this.stateId]
  }

  /**
   * State of license
   * @returns {number}
   */
  get stateId() {
    if (this.isActive) {
      return License.stateIds.ACTIVE
    }
    if (this.isExpired) {
      return License.stateIds.EXPIRED
    }

    return License.stateIds.EXPIRING
  }

  /**
   * Annual cost of provided amount of seats in USD
   * @param {number} seatsValue         - Amount of seats
   * @param {number} renewalCostPerYear - Renewal cost per seat for this specific license
   * @returns {string}
   */
  static annualSeatsCost(seatsValue, renewalCostPerYear) {
    return currencyFormatter(seatsValue * renewalCostPerYear, false)
  }

  /**
   * Cost of new seats for remaining time in license
   * @param {number} seatsValue   - Amount of seats
   * @param {number} proratedAddSeatPrice  - Cost for each new seat for the months left until expiry in this specific license
   * @returns {string}
   */
  static seatsCostForTimeLeft(seatsValue, proratedAddSeatPrice) {
    const annualCost = proratedAddSeatPrice * seatsValue

    return currencyFormatter(annualCost, true)
  }

  /**
   * Get obfuscated key in the format of SK3-****-****-****-****-0123
   * @returns {string}
   */
  get obfuscatedKey() {
    const separator = '-'
    const obfuscatedChar = '∗'
    const group = `${obfuscatedChar.repeat(4)}${separator}`

    return `SK3${separator}${group.repeat(4)}${this.obfuscatedKeyDigits}`
  }
}
