import { Controller } from 'stimulus'

const HEIGHT_LIMIT = 56
const ALIGNED_CLASS = 'thumbnail-title-link--aligned'

export default class ThumbnailTitleLink extends Controller {
  static targets = ['link', 'title']

  connect() {
    this.updateClass()
  }

  updateClass() {
    this.linkTarget.classList.toggle(ALIGNED_CLASS, this.titleTarget.offsetHeight < HEIGHT_LIMIT)
  }
}
