import { Controller } from 'stimulus'
import { KEYS } from '../../_js/base/consts'

export default class GridLayoutController extends Controller {
  static targets = ['res']

  toggleVisibility(e) {
    const isMac = navigator.userAgent.indexOf('Mac') != -1
    if (
      e.type == 'grid-layout-toggle' ||
      (((isMac && e.ctrlKey && e.keyCode === KEYS.L) || (!isMac && e.shiftKey && e.ctrlKey && e.keyCode === KEYS.L)) &&
        ['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) === -1)
    ) {
      this.element.classList.toggle('is-visible')
    }
  }

  updateResolution() {
    this.resTarget.textContent = `${window.innerWidth}x${window.innerHeight}`
  }
}
