import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use'

export default class TabsController extends Controller {
  static values = {
    activeClass: String,
    tabClass: String,
  }

  static targets = ['tab']

  connect() {
    useDispatch(this)
    const activeTab = this.tabTargets.find((tab) => tab.dataset.slug === location.hash.slice(1)) || this.tabTargets[0]
    this.toggle(activeTab)
    this.dispatch('onReady')
    window.addEventListener('hashchange', () => {
      const activeTab = this.tabTargets.find((tab) => tab.dataset.slug === location.hash.slice(1))
      this.toggle(activeTab)
    })
  }

  toggle(tab) {
    if (!tab) return

    const activeTab = this.getActiveTab()
    activeTab?.classList.remove(this.activeClassValue)

    tab.classList.toggle(this.activeClassValue)

    const tabSlug = tab.dataset.slug
    this.dispatch('onToggle', tabSlug)
  }

  handleTabClick({ target }) {
    this.toggle(target.closest(`.${this.tabClassValue}`))
  }

  getActiveTab() {
    return this.tabTargets.find((tab) => tab.classList.contains(this.activeClassValue)) || null
  }
}
