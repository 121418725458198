import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'

export default class PageNavPrism extends Controller {
  static targets = ['nav', 'navItemsWrapper', 'navItem', 'navItemTemplate', 'navVisibilityTrigger']

  connect() {
    if (this.hasNavItemTarget && this.hasNavVisibilityTriggerTarget) {
      // Start watching the position of the nav visibility trigger element
      this.toggleNavVisibility = throttle(this.toggleNavVisibility.bind(this), 200)
      window.addEventListener('scroll', this.toggleNavVisibility)

      // Render menu items
      this.renderMenuItem = this.renderMenuItem.bind(this)
      this.navItemTargets.forEach(this.renderMenuItem)

      // Show element
      this.navTarget.classList.remove('is-hidden')
    }
  }

  renderMenuItem(navItemTarget) {
    const templateClone = this.navItemTemplateTarget.cloneNode(true)

    const link = templateClone.content.querySelector('.page-nav-prism__link')
    link.setAttribute('href', `#${navItemTarget.id}`)
    link.innerHTML = link.innerHTML.replace('%label%', navItemTarget.dataset.navItemLabel)

    this.navItemsWrapperTarget.insertAdjacentHTML('beforeend', templateClone.innerHTML)
  }

  // Toggle the sticky navigation's visibility based on the position of the nav visibility trigger element in the viewport
  toggleNavVisibility() {
    const navRectY = this.navVisibilityTriggerTarget.getBoundingClientRect().y
    const navHeight = this.navTarget.getBoundingClientRect().height
    const navMarginTop = parseFloat(
      window.getComputedStyle(this.navVisibilityTriggerTarget).getPropertyValue('margin-top')
    )
    this.navTarget.classList.toggle('is-visible', navRectY - navHeight - navMarginTop <= 0)
  }
}
