import { Controller } from 'stimulus'

import * as auth from '../../../../_js/helpers/auth'
import * as user from '../../../../_js/helpers/user'

/**
 * The User Info component renders a section with
 * - Sign in / Sign up links
 * - Current logged in user details and Sign out
 */
export default class extends Controller {
  static targets = [
    'form',
    'signedOutContainer',
    'signedInContainer',
    'ssoWarning',
    'signOutWarningText',
    'ssoWarningSignedIn',
    'userName',
    'userEmail',
    'userAvatar',
    'selectWorkspaceSection',
    'formSuccess',
  ]

  static values = {
    userProfile: Object,
  }

  connect() {
    this.setUserState()
  }

  showSSOWarning() {
    this.ssoWarningTarget.classList.remove('is-hidden')
    this.signOutWarningTextTarget.classList.add('is-hidden')
    this.ssoWarningSignedInTarget.classList.remove('is-hidden')
  }

  // Grab user details and set the user profile data in the state
  setUserState() {
    const currentUserData = user.isLoggedIn() ? user.currentUser() : {}

    if (auth.isSsoOnly()) {
      this.showSSOWarning()
    }

    this.userProfileValue = {
      name: currentUserData.name,
      email: currentUserData.email,
      avatar: currentUserData.avatar,
    }

    this.renderUI()
  }

  // Renders and populates the UI with the user profile
  renderUI() {
    if (!this.hasSignedOutContainerTarget) return

    const hasUserProfile = !!Object.keys(this.userProfileValue).length

    // User has profile data
    if (hasUserProfile) {
      this.signedOutContainerTarget.classList.toggle('is-hidden')
      this.signedInContainerTarget.classList.toggle('is-hidden')

      // Update avatar source
      this.userAvatarTarget.src = this.userProfileValue.avatar.small

      // Update user information
      this.userNameTarget.textContent = this.userProfileValue.name
      this.userEmailTarget.textContent = this.userProfileValue.email
    } else {
      // User is not signed in and has no profile, render the sign in UI
      this.signedOutContainerTarget.classList.remove('is-hidden')
      this.signedInContainerTarget.classList.add('is-hidden')
    }
  }

  // Remove auth from localstorage, reset user profile and disable fields
  signOut() {
    const successMessage = document.querySelector('.form-success')

    auth.removeAuthAndProfileFromLocalStorage()

    this.userProfileValue = {}
    this.formController.resetForm()
    this.formController.disableForm()
    this.formController.showForm()

    // Workspace controller might not be there (success page)
    if (this.workspaceController) {
      this.workspaceController.disableWorkspaceSelect()
    }

    // Hide the success message if its visible when the user clicks to sign out
    if (successMessage) {
      successMessage.classList.add('is-hidden')
    }
  }

  userProfileValueChanged() {
    this.setUserState()
  }

  // Triggered when there is a localStorage event
  // Checks user auth and disables UI if needed
  checkStorage(event) {
    if (event && event.type === 'storage' && event.key !== null && event.key !== user.LOCALSTORAGE_USER_KEY) return
    // If we can't find local storage auth keys they have been removed, so lets disable the UI
    if (!user.isLoggedIn()) {
      this.userProfileValue = {}

      this.formController.resetForm()
      this.formController.disableForm()
      this.formController.showForm()
      this.workspaceController.disableWorkspaceSelect()
    }
  }

  // Connect to workspace controller
  // Used to disable the workspace on sign out
  get workspaceController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('.workspace'),
      'store--edu--workspace'
    )
  }

  // Connect to form controller
  // Used to disable the form on sign out
  get formController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('.education-form'),
      'store--edu--form'
    )
  }
}
