import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

/**
 * Automatically require():
 * 1. *.js files in _js/controllers
 * 2. *{_-}controller.js files in _components/
 */
const application = Application.start()
const definitions = [
  ...definitionsFromContext(require.context('./controllers', true, /\.js$/)),
  ...definitionsFromContext(require.context('../_components/', true, /[_-]controller\.js$/)),
  ...definitionsFromContext(require.context('../_pages/', true, /[_-]controller\.js$/)),
]

/**
 * When mapping controller files to identifiers, Stimulus replaces
 * forward slashes in the controller’s path with two dashes, such as:
 *   users/list_item_controller.js -> users--list-item
 *
 * For controllers in _components subfolders, we don’t want this:
 *   dropdown/dropdown_controller.js -> dropdown--dropdown
 *
 * Instead, we want:
 *   dropdown/dropdown_controller.js -> dropdown
 */
definitions.forEach((definition) => {
  const parts = definition.identifier.split('--')
  if (parts.length >= 2 && parts[parts.length - 1] == parts[parts.length - 2]) {
    definition.identifier = parts.slice(0, -1).join('--')
  }
})

application.load(definitions)
