import { Controller } from 'stimulus'

export default class InPageNav extends Controller {
  static targets = ['link', 'cta']

  static classes = ['active']

  connect() {
    this.targetSections = this.getTargetSections()

    this.observeTargetSections()
    this.observeNav()
  }

  getTargetSections() {
    return this.linkTargets.map((link) => {
      const sectionId = link.getAttribute('href')
      return document.body.querySelector(sectionId)
    })
  }

  observeNav() {
    const wrapper = document.querySelector('.in-page-nav-wrapper')

    const observer = new IntersectionObserver(
      ([e]) =>
        e.target.classList.toggle(
          'in-page-nav-wrapper--is-sticky',
          e.intersectionRatio < 1 && e.intersectionRect.y === 1
        ),
      {
        threshold: [1],
        rootMargin: '-1px 0px 0px 0px',
      }
    )

    observer.observe(wrapper)
  }

  observeTargetSections() {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        const entryId = entries[0].target.getAttribute('id')
        const lastSectionId = this.linkTargets[this.linkTargets.length - 1].getAttribute('href').substr(1)
        const isEntryPastScroll = !entries[0]?.isIntersecting && entries[0]?.boundingClientRect.y < 0

        this.element
          .closest('.in-page-nav-wrapper')
          .classList.toggle('is-hidden', isEntryPastScroll && entryId === lastSectionId)

        const intersectingSection = entries.find((entry) => entry.isIntersecting)
        if (!intersectingSection) return

        const linkForSection = this.linkTargets.find(
          (link) => link.getAttribute('href').substr(1) === intersectingSection.target.getAttribute('id')
        )
        this.linkTargets.forEach((link) => link.classList.remove(this.activeClass))

        linkForSection.classList.add(this.activeClass)
      },
      {
        threshold: 0.25,
      }
    )

    this.targetSections.forEach((section) => sectionObserver.observe(section))
  }
}
