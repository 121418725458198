import { Controller } from 'stimulus'

const MOVING_CLASS = 'annotation--moving'
const ACTIVE_CLASS = 'annotation--active'
const WAS_ACTIVE_CLASS = 'annotation--was-active'

export default class Annotation extends Controller {
  static targets = ['annotation', 'icon']

  isMoving = false
  hasMoved = false

  activeClassApplied = false

  initialMousePosition = { x: 0, y: 0 }

  initialElementPosition = { x: 0, y: 0 }

  eventMouseMove = this.handleMouseMove.bind(this)

  eventMouseUp = this.handleMouseUp.bind(this)

  connect() {
    document.addEventListener('mouseup', this.handleClick.bind(this))
  }

  handleClick(event) {
    if (event.button === 0) {
      if (!event.target.closest('.annotation__popup')) {
        this.removeActiveAnnotations()
      }
    }
  }

  handleMouseDown(event) {
    if (event.button === 0) {
      event.preventDefault()
      this.isMoving = true
      this.hasMoved = false
      this.initialMousePosition = { x: event.clientX, y: event.clientY }
      this.initialElementPosition = { x: this.element.offsetLeft, y: this.element.offsetTop }
      document.addEventListener('mousemove', this.eventMouseMove)
      document.addEventListener('mouseup', this.eventMouseUp, { once: true })
    }
  }

  handleMouseMove(event) {
    if (this.isMoving) {
      event.preventDefault()
      this.removeActiveAnnotations()
      this.element.classList.add(MOVING_CLASS)

      const deltaX = event.clientX - this.initialMousePosition.x
      const deltaY = event.clientY - this.initialMousePosition.y

      // Check if the distance between the initial mouse position and current mouse position is >= 4px
      if (Math.sqrt(deltaX * deltaX + deltaY * deltaY) >= 4) {
        this.hasMoved = true
      } else {
        this.hasMoved = false
      }

      this.element.style.left = `${this.initialElementPosition.x + deltaX}px`
      this.element.style.right = 'auto'
      this.element.style.top = `${this.initialElementPosition.y + deltaY}px`
    }
  }

  handleMouseUp(event) {
    this.isMoving = false
    this.element.classList.remove(MOVING_CLASS)

    if (event.target.parentNode == this.element && !this.hasMoved && !this.activeClassApplied) {
      this.activeClassApplied = true
      this.element.classList.add(ACTIVE_CLASS)
      this.element.classList.add(WAS_ACTIVE_CLASS)
    } else {
      this.activeClassApplied = false
    }

    document.removeEventListener('mousemove', this.eventMouseMove)
    document.removeEventListener('mouseup', this.eventMouseUp)
  }

  removeActiveAnnotations() {
    const annotations = document.querySelectorAll('.annotation:not(.annotation--do-not-close)')
    annotations.forEach((e) => {
      if (e !== this.element) {
        e.classList.remove(ACTIVE_CLASS)
      }
    })

    // Reset the active class for the current annotation
    if (!this.element.classList.contains('annotation--do-not-close')) {
      if (!this.activeClassApplied) {
        this.element.classList.remove(ACTIVE_CLASS)
      }
      this.activeClassApplied = false
    }
  }

  disconnect() {
    document.removeEventListener('mouseup', this.handleClick)
    this.element.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
  }
}
