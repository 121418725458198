import { storageAvailable } from '../base/feature-detect'
import { removeQueryStringParams } from '../base/utils'

const STORAGE_KEY = 'app:version'
const PARAM_KEYS = [
  'app',
  'buildNumber',
  'buildVersion',
  'bundleIdentifier',
  'cpuType',
  'isRosetta',
  'model',
  'osVersion',
]
const PARAM_READABLE_NAMES = {
  app: 'Source App',
  buildNumber: 'Build Number',
  buildVersion: 'Build Version',
  bundleIdentifier: 'Bundle Identifier',
  cpuType: 'CPU Type',
  isRosetta: 'Rosetta',
  model: 'Device model',
  osVersion: 'OS Version',
  retrievedOn: 'Retrieved On',
}
const MANDATORY_PARAMS = {
  utm_source: 'sketch_macos',
  app: 'iphone',
}

// A valid query string must have at least one of the MANDATORY_PARAMS key:value pairs
const isValidQueryString = function (queryString) {
  return !!Object.keys(MANDATORY_PARAMS).find(
    (key) => queryString.has(key) && queryString.get(key) === MANDATORY_PARAMS[key]
  )
}

// check window.location string to get some Sketch app related data if passed
const storeFromQueryString = function () {
  if (!storageAvailable('localStorage')) return

  const urlParams = new URLSearchParams(window.location.search)
  if (!isValidQueryString(urlParams)) return

  // Build data object from params
  const versionData = {}
  PARAM_KEYS.forEach((prop) => {
    if (urlParams.has(prop)) versionData[prop] = urlParams.get(prop)
  })
  // Include date so CS knows when data was retrieved
  versionData.retrievedOn = new Date().toString()

  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(versionData))

  // Remove data-related query string params so data isn’t re-set if user navigates back to this page
  removeQueryStringParams(PARAM_KEYS.concat('utm_source'))
}

const getFromStorage = function () {
  if (!storageAvailable('localStorage')) return {}

  const stringData = window.localStorage.getItem(STORAGE_KEY)
  if (!stringData) return {}

  try {
    return JSON.parse(stringData)
  } catch (SyntaxError) {
    return {}
  }
}

const getReadableFromStorage = function () {
  if (!storageAvailable('localStorage')) return ''

  const data = getFromStorage()

  return Object.keys(data)
    .map((key) => `- ${PARAM_READABLE_NAMES[key]}: ${data[key]}`)
    .join('\n')
}

export default { storeFromQueryString, getFromStorage, getReadableFromStorage }
