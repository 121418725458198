import { Controller } from 'stimulus'
import slugify from 'slugify'

export default class extends Controller {
  connect() {
    this.selector = this.data.get('selector') || 'h3,h4'
    this.collectHeadings()
  }

  collectHeadings() {
    this.element.querySelectorAll(this.selector).forEach((el) => {
      el.classList.add('heading-with-anchor')
      if (!el.id) el.id = slugify(el.textContent)
      const anchor = document.createElement('a')
      anchor.setAttribute('href', `#${el.id}`)
      anchor.setAttribute('class', 'heading-with-anchor__anchor')
      anchor.setAttribute('aria-hidden', 'true')
      el.appendChild(anchor)
    })
  }
}
