import { Controller } from 'stimulus'
import h from 'hyperscript'

export default class DropcapController extends Controller {
  static NODES_TO_SEARCH = ['div', 'p', 'strong', 'em']

  connect() {
    this.applyDropcap()
  }

  applyDropcap() {
    const textNode = this.findTextNodeWithFirstWord()
    if (!textNode) return

    // Find closest non-text node to apply class
    let closestElement = textNode.parentNode
    while (closestElement !== null && closestElement.nodeType !== Node.ELEMENT_NODE) {
      closestElement = closestElement.parentNode
    }
    if (closestElement) {
      closestElement.classList.add('has-dropcap')
    }

    // Extract individual elements needed to assemble the dropcap
    // "Our mission with Sketch" => "O" + "ur" + " mission with Sketch"
    const [_match, firstLetter, restOfFirstWord, otherWords] = textNode.textContent.match(/^\s*(\S)(\S*)(.*)$/)

    // Reassemble elements as such:
    // <span>
    //   <span><span>O</span>ur</span>  <- aria-hidden, for styling
    //   <span>Our</span>               <- visuallyhidden, for screen readers
    // </span>
    ///  mission with Sketch
    textNode.replaceWith(
      h('span', { role: 'text' }, [
        h('span.dropcap-outer', { attrs: { 'aria-hidden': true } }, [h('span.dropcap', firstLetter), restOfFirstWord]),
        h('span.visuallyhidden', firstLetter + restOfFirstWord),
      ]),
      otherWords
    )
  }

  /**
   * Finds the text Node that is suitable for a dropcap
   * @param {Element} [container=this.element] - element to search
   * @returns {Node|undefined}
   */
  findTextNodeWithFirstWord(container = this.element) {
    if (!container.hasChildNodes()) return

    for (const node of container.childNodes) {
      if (node.nodeType === Node.TEXT_NODE && /\S+/.test(node.textContent)) {
        return node
      }
      if (DropcapController.NODES_TO_SEARCH.includes(node.nodeName.toLowerCase())) {
        const foundNode = this.findTextNodeWithFirstWord(node)
        if (foundNode) return foundNode
      }
    }
  }
}
