const ACTIVE_CLASS = 'is-active'

export default class Toggleable {
  /**
   * @param {(string|Element)} selectorOrNode - HTML Element or selector for the Toggleable
   */
  constructor(selectorOrNode) {
    this.container = typeof selectorOrNode === 'string' ? document.querySelector(selectorOrNode) : selectorOrNode

    // A data-toggleable attribute on the container marks it as already having a Toggleable instance
    this.container.setAttribute('data-toggleable', '')
    /** @type Object.<string,function[]> */
    this._listeners = {}
    // All Toggleables are inactive by default
    this.isActive = false

    // Bind event handlers to the instance
    this._boundToggleClickHandler = this._boundToggleClickHandler.bind(this)

    /*
      The `expandedWhenActive` preference:
      - Reads from [data-toggleable-is-expanded-when], which can be 'inactive', 'active', or ommited entirely.
      - It reflects whether the isActive state corresponds to the component being visually expanded.
      - This is used for accessibility purposes, to set [aria-expanded] to its correct value when the state changes.
      - If not specified, we consider the active state to mean the component is visually "expanded"
    */
    this.expandedWhenActive = this.container.getAttribute('data-toggleable-is-expanded-when') !== 'inactive'

    // Triggers are elements that toggle the instance's active state
    this.triggers = this.container.querySelectorAll('.js-toggleable-toggle')

    // Initialize
    // 1. Make toggleable toggle when triggers are clicked
    this.triggers.forEach(el => el.addEventListener('click', this._boundToggleClickHandler))
    // 2. Set any DOM properties on instantiation
    this.updateDOM()
  }

  _boundToggleClickHandler() {
    this.toggle()
  }

  /**
   * Updates the DOM
   * - Called on instantiation and state changes
   */
  updateDOM() {
    this.container.classList.toggle(ACTIVE_CLASS, this.isActive)
    const isExpanded = this.expandedWhenActive === this.isActive ? 'true' : 'false'
    this.triggers.forEach(trigger => {
      trigger.setAttribute('aria-expanded', isExpanded)
      // Add ACTIVE_CLASS on the triggers for easier styling
      trigger.classList.toggle(ACTIVE_CLASS, this.isActive)
    })
  }

  /**
   * Adds callbacks to be called when an internal method runs
   * @param {string} method - name of the method whose invocation will fire the callback
   * @param {function()} callback - callback to be called
   * @returns {this}
   */
  on(method, callback) {
    if (!this._listeners[method]) {
      this._listeners[method] = []
    }
    this._listeners[method].push(callback)
    return this
  }

  /**
   * Fire any callbacks for a certain method
   * @param {string} method - name of the internal method
   */
  _fire(method) {
    if (!this._listeners[method]) return
    this._listeners[method].forEach(callback => callback())
  }

  /**
   * Activates this Toggleable instance
   * @returns {this}
   */
  activate() {
    if (this.isActive) return this
    this.isActive = true

    this.updateDOM()

    this._fire('activate')
    return this
  }

  /**
   * Deactivates this Toggleable instance
   * @returns {this}
   */
  deactivate() {
    if (!this.isActive) return this
    this.isActive = false

    this.updateDOM()

    this._fire('deactivate')
    return this
  }

  /**
   * Toggles this Toggleable instance
   * @returns {this}
   */
  toggle() {
    return this.isActive ? this.deactivate() : this.activate()
  }
}
