/* global algoliasearch, autocomplete */
import { Controller } from 'stimulus'
import h from 'hyperscript'
import throttle from 'lodash/throttle'
import Algolia from '../algolia'

/**
 * Search Controller
 *
 * Values:
 * - index-name              - Index name to pass Agolia
 * - filters                 - Filters to pass to Algolia
 * - show-recommendations    - Whether to show recommendations ("true"|"false")
 * - recommendations-heading - Heading for the recommendations list
 */
export default class Search extends Controller {
  static targets = ['input']

  static values = {
    indexName: String,
    filters: String,
    showRecommendations: Boolean,
    recommendationsHeading: String,
    limitResults: Number,
    showHeadings: Boolean,
    dropdownHeading: String,
    dropdownPosition: String,
  }

  static AUTOCOMPLETE_DEFAULTS = {
    hint: false,
    tabAutocomplete: false,
    openOnFocus: true,
    minLength: 0,
    debug: true, // keep results open
  }

  connect() {
    this.client = algoliasearch(Algolia.applicationID, Algolia.searchAPIKey)
    this.index = this.client.initIndex(this.indexNameValue)
    this.hitsSource = autocomplete.sources.hits(this.index, {
      hitsPerPage: this.limitResultsValue,
      filters: this.filtersValue,
    })
    this._initAutocomplete()
  }

  // disconnect() {
  //   this.overlay.destroy()
  // }

  _initAutocomplete() {
    const autocompleteOptions = Search.AUTOCOMPLETE_DEFAULTS

    if (!this.showRecommendationsValue) {
      autocompleteOptions.minLength = 1
    }

    this.autocomplete = autocomplete(this.inputTarget, autocompleteOptions, {
      source: this._sortHits.bind(this),
      templates: Search.templates,
    })

    // Ability to hit enter to go to URL on selected
    this.autocomplete.on('autocomplete:selected', function (_event, suggestion) {
      window.location.href = suggestion.url
    })

    this.autocomplete.on('autocomplete:updated', () => {
      this._toggleDropdownHeading(this.inputTarget.value.length)
      this.resize()
    })

    // add dropdown heading if set
    this._injectDropdownHeading()

    // set dropdown position
    this.element.querySelector('.aa-dropdown-menu').style.position = this.dropdownPositionValue
  }

  static templates = {
    suggestion: function (suggestion) {
      // highlight keywords in the description fields
      var highlightedContent = Search.highlightKeywords(
        suggestion.content || suggestion.description || '',
        suggestion._highlightResult
      )

      const date = new Date(suggestion.date * 1000)
      const options = { day: 'numeric', month: 'short', year: 'numeric' }
      const formatedDate = date.toLocaleDateString('en-UK', options)

      let postImage
      if (suggestion.cover_image) {
        postImage = suggestion.cover_image
      } else if (suggestion.cover_video_still) {
        postImage = suggestion.cover_video_still
      } else {
        postImage = 'https://cdn.sketch.com/assets/blog/default@2x.jpg'
      }

      return h(
        'a',
        {
          href: suggestion.url,
        },
        [
          h('img.aa-suggestion__cover', {
            src: postImage,
            loading: 'lazy',
          }),
          h('div.aa-suggestion__details', [
            h('time.aa-suggestion__date', formatedDate),
            h('h4.aa-suggestion__title', suggestion.title),
            h('p.aa-suggestion__excerpt', { innerHTML: highlightedContent }),
          ]),
        ]
      )
    },
    empty: function () {
      return h('div.algolia-search__no-results', [
        h('h4.aa-suggestion__title', 'No results found'),
        h('p', 'Make sure all words are spelled correctly or try with different keywords.'),
      ])
    },
  }

  static highlightKeywords(text, hres) {
    if (hres && hres.content && hres.content.matchedWords) {
      Object.keys(hres.content.matchedWords).forEach(function (el, key) {
        var word = hres.content.matchedWords[key]
        if (word.length > 3) {
          // This is a bit convoluted, but fixes an issue with the matchedWords that Algolia sends us: they're all lowercase,
          // so doing a basic regex replace will turn 'Sketch' into 'sketch', and we can't have that :)
          var regex = new RegExp(word, 'gi')
          var matches = text.match(regex)
          if (matches) {
            for (var i = 0; i < matches.length; i++) {
              var match = matches[i]
              text = text.replace(match, '<span class="algolia__result-highlight">' + match + '</span>')
            }
          }
        }
      })
    }
    return text
  }

  // Add a heading above search dropdown
  _injectDropdownHeading() {
    if (!this.hasDropdownHeadingValue || this.dropdownHeadingValue === '') return

    this.dropdownHeading = h(`h5.aa-dropdown-heading.is-hidden`, this.dropdownHeadingValue)
    const dropdownNode = this.element.querySelector('.aa-dropdown-menu')
    dropdownNode.parentNode.insertBefore(this.dropdownHeading, dropdownNode)
  }

  _toggleDropdownHeading(inputValueLength) {
    if (!this.hasDropdownHeadingValue || this.dropdownHeadingValue === '') return
    // this.dropdownHeading.classList.toggle('is-hidden', inputValueLength === 0)
  }

  /**
   * Sort suggestions by category
   * @param {String} query
   * @param {Function} callback
   */
  _sortHits(query, callback) {
    this.hitsSource(query, function (suggestions) {
      const suggestionsSortedByDate = suggestions.sort((a, b) => {
        const dateA = a.date
        const dateB = b.date
        if (dateA < dateB) return 1
        if (dateA > dateB) return -1
        return 0
      })
      callback(suggestionsSortedByDate)
    })
  }

  // showOverlay() {
  //   this.overlay.show()
  //   this.element.classList.add('is-above-overlay')
  // }

  // hideOverlay() {
  //   this.overlay.hide()
  //   this.element.classList.remove('is-above-overlay')
  // }

  /**
   * Blur the input field if ESC key was hit and the input field is empty
   *

   * @param {KeyboardEvent} event
   */
  keyPressed(event) {
    if (event.key == 'Escape' && this.inputTarget.value == '') {
      this.inputTarget.blur()
    }
  }

  /**
   * Resize dropdown results window so it always fits in the browser window
   */
  resize = throttle(() => {
    if (this.dropdownPositionValue === 'absolute') {
      const dropdownMenuNode = this.element.querySelector('.aa-dropdown-menu')
      const menuConstraints = dropdownMenuNode.getBoundingClientRect()
      const elementsSpacing = 24

      const height = window.visualViewport ? window.visualViewport.height : window.innerHeight

      dropdownMenuNode.style.maxHeight = `${height - menuConstraints.top - elementsSpacing}px`

      // The Overlay component blocks all touchevents on mobile devices but this extra class allows scrolling of these elements
      dropdownMenuNode.classList.add('body-scroll-lock-ignore')
    }
  }, 100)
}
