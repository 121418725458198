import { Controller } from 'stimulus'
import isFinite from 'lodash/isFinite'
import h from 'hyperscript'

export default class AspectRatio extends Controller {
  static targets = ['intrinsic']

  static values = {
    ratio: String,
  }

  connect() {
    if (!this.hasRatioValue && this.hasIntrinsicTarget) {
      this.applyIntrinsicRatio()
    }
  }

  get intrinsicRatio() {
    const sourceEl = this.intrinsicTarget

    const widthAttr = parseInt(sourceEl.getAttribute('width'), 10)
    const heightAttr = parseInt(sourceEl.getAttribute('height'), 10)

    const width = isFinite(widthAttr) ? widthAttr : sourceEl.clientWidth
    const height = isFinite(heightAttr) ? heightAttr : sourceEl.clientHeight
    return width / height
  }

  applyIntrinsicRatio() {
    const ratioElement = h('div', {
      style: {
        display: 'block',
        width: '1px',
        'margin-left': '-1px',
        float: 'left',
        height: '0',
        'padding-top': `${(1 / this.intrinsicRatio) * 100}%`,
      },
    })
    this.element.insertAdjacentElement('afterbegin', ratioElement)
  }
}
