import { storageAvailable } from '../base/feature-detect'
import { dayDiff } from '../base/utils'

const STORAGE_KEY = 'signUpMetadata'
const PARAM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
const NOW_DATE = new Date()
const MAX_DATA_EXPIRY_DAYS = 30
const SEARCH_ENGINES = [
  'google',
  'baidu',
  'bing',
  'duckduckgo',
  'naver',
  'ecosia',
  'yahoo',
  'yandex',
  'qwant',
  'sogou',
  'so',
  'seznam',
  '360',
  'daum',
  'ask',
  'aol',
  'najdi',
  'rambler',
]

const getFromStorage = () => {
  if (!storageAvailable('localStorage')) return {}

  const stringData = window.localStorage.getItem(STORAGE_KEY)
  if (!stringData) return {}

  try {
    return JSON.parse(stringData)
  } catch (SyntaxError) {
    return {}
  }
}

// Check if stored data is beyond the max expiry date
const isStoredDataExpired = () => {
  const storedData = getFromStorage()
  // We consider non-existent data expired so we can get the new data if available
  if (Object.keys(storedData).length === 0 || !storedData.retrieved_on) return true

  const dataDate = new Date(storedData.retrieved_on).getTime()
  const dataDaysOld = dayDiff(dataDate, NOW_DATE.getTime())

  return dataDaysOld >= MAX_DATA_EXPIRY_DAYS
}

// Check for filled properties in provided data object
const hasData = (data = {}) => {
  const propsWithData = Object.keys(data).filter((prop) => data[prop] !== null)
  // The date the data was retrieved on doesn't count as data
  return propsWithData.length > 1 || (propsWithData.length === 1 && propsWithData[0] != 'retrieved_on')
}

const searchEngineRegExp = (searchEngine) => new RegExp(`^(?:https?://)?(?:www.)?${searchEngine}\\..+$`)

const isReferrerSearchEngine = (referrer) =>
  SEARCH_ENGINES.some((searchEngine) => searchEngineRegExp(searchEngine).test(referrer))

const isDirectTraffic = (referrer) => referrer === ''

const isReferralTraffic = (referrer) => !isReferrerSearchEngine(referrer) && !isDirectTraffic(referrer)

const storeFromQueryString = () => {
  if (!storageAvailable('localStorage')) return

  const urlParams = new URLSearchParams(window.location.search)

  const data = {
    retrieved_on: NOW_DATE.toDateString(),
  }
  PARAM_KEYS.forEach((prop) => {
    data[prop] = urlParams.get(prop) || null
  })

  // Organic traffic
  // That's traffic coming from search engines (defined at the top). It's only relevant when
  // no utm_params are present in the URL.
  if (!hasData(data) && isReferrerSearchEngine(document.referrer)) {
    data.utm_medium = 'organic'
    data.utm_source = document.referrer
  }

  // Direct traffic
  // That's traffic coming directly to the website. It's only relevant when
  // no utm_params are present in the URL.
  if (!hasData(data) && isDirectTraffic(document.referrer)) {
    data.utm_medium = 'direct'
  }

  // Referral traffic
  // That's traffic that's neither direct nor organic. Meaning, these users come from a website
  // that's not a search engine.
  if (!hasData(data) && isReferralTraffic(document.referrer)) {
    data.utm_medium = 'referral'
    data.utm_source = document.referrer
  }

  if (isStoredDataExpired() || !hasData(getFromStorage())) {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
  }
}

export default { storeFromQueryString, getFromStorage }
