import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../base/consts'
import mediaFrom from '../utils/media-from'

export default class BtcLightBoxController extends Controller {
  static targets = ['mainWrapper', 'expandable', 'image', 'imageWrapper', 'bg']

  imageWidth = null

  imageHeight = null

  isOpen = null

  oldScroll = 0

  initialResize = true

  connect() {
    this.imageLoadedHandler = this.imageLoaded.bind(this)
    this.imageTarget.addEventListener('load', this.imageLoadedHandler)

    this.resize = this.resize.bind(this)
    window.addEventListener('resize', this.resize)

    if (this.imageTarget.complete) {
      // If the image is already loaded by the time connect runs
      this.imageLoaded()
    }
  }

  resize() {
    if (this.initialResize) {
      // Skip the first call
      this.initialResize = false
      return
    }

    this.imageWidth = this.imageTarget.offsetWidth
    this.imageHeight = this.imageTarget.offsetHeight

    this.adjustWrapperPadding()
  }

  imageLoaded() {
    requestAnimationFrame(() => {
      const imageWidth = this.imageTarget.offsetWidth
      const imageHeight = this.imageTarget.offsetHeight

      if (imageWidth > 0 && imageHeight > 0) {
        // Remove the event listener to prevent it from firing multiple times
        this.imageTarget.removeEventListener('load', this.imageLoadedHandler)

        this.imageWidth = imageWidth
        this.imageHeight = imageHeight

        // Add padding-top to the wrapper of the image so that it shows up
        this.adjustWrapperPadding()
      }
    })
  }

  adjustWrapperPadding() {
    const aspectRatioPercentage = (this.imageHeight / this.imageWidth) * 100

    this.imageTarget.style.position = 'absolute'
    this.imageWrapperTarget.style.paddingTop = `${aspectRatioPercentage}%`
  }

  disconnect() {
    // Cleanup the event listener when the controller disconnects
    this.imageTarget.removeEventListener('load', this.imageLoaded.bind(this))
    window.removeEventListener('resize', this.resize)
  }

  activateScrollHandler() {
    this.handleScroll = this.handleScroll.bind(this)
    document.addEventListener('scroll', this.handleScroll)
  }

  deactivateScrollHandler() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const currentScroll = window.scrollY
    const scrollDifference = Math.abs(currentScroll - this.oldScroll)

    if (scrollDifference >= 100) {
      this.closeLightbox()
    }

    this.oldScroll = currentScroll // Update old scroll position for the next event
  }

  openLightbox() {
    this.mainWrapperTarget.classList.add('btc-blog-post__lightbox--active')

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    let horizontalPadding = 24
    let verticalPadding = 20

    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      horizontalPadding = 72
      verticalPadding = 60
    } else if (mediaFrom(BREAKPOINTS.VIEWPORT_XL)) {
      horizontalPadding = 120
      verticalPadding = 100
    }

    // Calculate available space considering margins
    const maxWidth = windowWidth - horizontalPadding * 2
    const maxHeight = windowHeight - verticalPadding * 2

    // Calculate scale factors for width and height
    const scaleWidth = maxWidth / this.imageWidth
    const scaleHeight = maxHeight / this.imageHeight

    // Use the smallest scale factor to ensure the image fits within both constraints
    const scale = Math.min(scaleWidth, scaleHeight)

    // Get original image distance from top
    const rect = this.mainWrapperTarget.getBoundingClientRect()

    // Get distance from top with the scaled dimensions
    const rectTop = -rect.top / scale

    // Get new height of the scaled image
    const newHeight = this.imageHeight * scale

    // Use that height to determine the distance from the top it should have to be centered
    const verticalMargin = (windowHeight - newHeight) / 2 / scale

    // Add the vertical margin to the distance to the top of the viewport to know how much we have to translateY the image
    const translateDistance = rectTop + verticalMargin

    // Apply the CSS transformations
    this.expandableTarget.style.transform = `scale(${scale}) translateY(${translateDistance}px)`

    // Make the original div the same width and height as the image to occupy that space on the page
    this.mainWrapperTarget.style.width = `${this.imageWidth}px`
    this.mainWrapperTarget.style.height = `${this.imageHeight}px`

    this.isOpen = true

    this.activateScrollHandler()
  }

  closeLightbox() {
    this.mainWrapperTarget.classList.remove('btc-blog-post__lightbox--active')
    this.expandableTarget.style.transform = 'none'
    this.mainWrapperTarget.style.width = 'initial'
    this.mainWrapperTarget.style.height = 'initial'

    this.isOpen = false

    this.deactivateScrollHandler()
  }

  handleClick() {
    if (this.isOpen) {
      this.closeLightbox()
    } else if (mediaFrom(BREAKPOINTS.VIEWPORT_M)) {
      this.openLightbox()
    }
  }
}
