import { Controller } from 'stimulus'

const WORDS_PER_MINUTE = 320 // Controls carousel speed (higher value means faster)
const WORDS_PER_SECOND = WORDS_PER_MINUTE / 60

export default class Testimonials extends Controller {
  static targets = ['quotesWrapper', 'quote', 'navItem']

  connect() {
    this.selectedNavItemIndex = 0
    this.rotationTimeout = 0

    this.showQuote()
  }

  get _nextNavItemIndex() {
    return this.selectedNavItemIndex + 1 < this.navItemTargets.length ? this.selectedNavItemIndex + 1 : 0
  }

  get _timeToReadQuote() {
    const wordCount = this.quoteTargets[this.selectedNavItemIndex].textContent.split(' ').length
    return Math.round((wordCount / WORDS_PER_SECOND) * 1000)
  }

  startRotation() {
    this.stopRotation()
    this.selectedNavItemIndex = this._nextNavItemIndex
    this.rotationTimeout = setTimeout(() => this.showQuote(), this._timeToReadQuote)
  }

  stopRotation() {
    clearTimeout(this.rotationTimeout)
  }

  navItemClickHandler(event) {
    this.selectedNavItemIndex = [...Array.from(event.currentTarget.parentElement.children)].indexOf(event.currentTarget)
    this.showQuote()
  }

  showQuote() {
    let wrapperHeight = 0

    this.quoteTargets.forEach((el, i) => {
      el.classList.toggle('is-active', this.selectedNavItemIndex == i)
      wrapperHeight = Math.max(wrapperHeight, el.offsetHeight)
    })

    // set height of carousel
    this.quotesWrapperTarget.style.height = `${wrapperHeight}px`

    if (this.quoteTargets.length > 1) {
      this.navItemTargets.forEach((el, i) => {
        el.classList.toggle('is-active', this.selectedNavItemIndex == i)
      })

      this.startRotation()
    }
  }
}
