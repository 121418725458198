import { Controller } from 'stimulus'

import * as userUtils from '../../_js/helpers/user'

export default class SignUpDisclaimer extends Controller {
  static targets = ['signUpDisclaimer']

  connect() {
    if (!userUtils.isLoggedIn()) {
      this.signUpDisclaimerTarget.classList.remove('is-hidden')
    }
  }
}
