import FormController from '../../form/form_controller'
import * as userUtils from '../../../_js/helpers/user'

const HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HS_PORTAL_ID = 7692458
const HS_SUBS_MARKETING_INFORMATION_ID = 9320643
const HS_SUBS_PRODUCT_UPDATES_ID = 11923354
const HS_SANDBOX_PORTAL_ID = 20854638
const HS_SANDBOX_FORM_GUID = 'b8f73d82-f757-41f3-8ab0-bf3c58035e54'
const HS_SANDBOX_SUBS_MARKETING_INFORMATION_ID = 24734259
const HS_SANDBOX_SUBS_PRODUCT_UPDATES_ID = 42055791

const SUBMISSION_DELAY = 20000

export default class EventForm extends FormController {
  static targets = ['firstName', 'lastName', 'email', 'fields', 'options', 'actions', 'success']

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('Form controller can only be used in <form> elements')
    }

    // Default values
    if (!this.hasValidateValue) this.validateValue = true

    // Disable native browser validation in favor of our own
    if (this.validateValue) {
      this.element.setAttribute('novalidate', '')
    }

    this.errorMessage = ''

    this.checkLoginState()

    this.resetTimestamp()
  }

  resetTimestamp() {
    this.startTime = Date.now()
  }

  checkLoginState(event) {
    // If a storage event but not on the relevant keys, early return
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null

    // Fill out form with auth data
    if (this.user) {
      this.emailTarget.value = this.user.email

      const nameArray = this.user.name.split(' ')
      this.firstNameTarget.value = nameArray[0] || this.user.name

      // The Sketch signup process requested the full name of the user in the past, so there's a chance that the last name is also defined in the auth data
      if (nameArray.length > 1) {
        this.lastNameTarget.value = nameArray.slice(1).join(' ')
      }
    }
  }

  // Replace original function to include Sketch system info in the FormData
  submitRemotely() {
    // Generate action URL on submit
    this.generateFormActionURL()

    // Check if the first submission happens after a certain delay (to prevent bot submissions that happens too fast)
    if (Date.now() - this.startTime < SUBMISSION_DELAY) return

    // Capture form data before any inputs are possibly disabled
    const formData = new FormData(this.element)

    const postData = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: formData.get('firstname'),
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: formData.get('lastname'),
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formData.get('email'),
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: formData.get('company'),
        },
        {
          objectTypeId: '0-1',
          name: 'jobtitle',
          value: formData.get('jobtitle'),
        },
        {
          objectTypeId: '0-1',
          name: 'numemployees',
          value: formData.get('numemployees') || '',
        },
        {
          objectTypeId: '0-1',
          name: 'design_team_size',
          value: formData.get('design-team-size') || '',
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I confirm that I have read and agree to Sketch’s Terms of Service and Privacy Statement',
          communications: [
            {
              value: formData.get('marketing-events') === 'yes',
              subscriptionTypeId:
                GLOBALS.ENV === 'development'
                  ? HS_SANDBOX_SUBS_MARKETING_INFORMATION_ID
                  : HS_SUBS_MARKETING_INFORMATION_ID,
              text: 'I would like to receive marketing emails from Sketch, including information about future events, inspiration from the Sketch community and more.',
            },
            {
              value: formData.get('product-updates') === 'yes',
              subscriptionTypeId:
                GLOBALS.ENV === 'development' ? HS_SANDBOX_SUBS_PRODUCT_UPDATES_ID : HS_SUBS_PRODUCT_UPDATES_ID,
              text: 'I would like to receive product information and updates.',
            },
          ],
        },
      },
    }

    this.disableSubmission()

    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else {
          this.resetTimestamp()
          this.showSuccessPanel()
          return this.remoteSubmissionSuccessful(false)
        }
      })
      .catch(() => this.remoteSubmissionFailed())
      .finally(() => this.enableSubmission())
  }

  showSuccessPanel() {
    this.fieldsTarget.classList.add('js-hidden')
    this.optionsTarget.classList.add('js-hidden')
    this.actionsTarget.classList.add('js-hidden')
    this.successTarget.classList.remove('js-hidden')
    this.successTarget.classList.add('animated', 'animated--lay-in')
  }

  generateFormActionURL() {
    this.element.setAttribute(
      'action',
      `${HS_ENDPOINT_PREFIX}/${GLOBALS.ENV === 'development' ? HS_SANDBOX_PORTAL_ID : HS_PORTAL_ID}/${
        GLOBALS.ENV === 'development' ? HS_SANDBOX_FORM_GUID : this.element.dataset.hubspotFormGuid
      }`
    )
  }
}
