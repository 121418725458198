import FormController from '../../../_components/form/form_controller'

const HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HS_PORTAL_ID = '7692458'
const HS_FORM_GUID = '430da346-e15f-4fa0-ab98-f18f4fbb9643'

export default class FeedbackController extends FormController {
  static targets = ['form', 'feedback', 'email', 'submit', 'feedbackType', 'response']

  connect() {
    this.getParamsFromURL()
    this.generateFormActionURL()
  }

  submitRemotely() {
    const formData = new FormData(this.element)

    // Disable submissions
    this.disableSubmission()

    /**
     * Prepate the data for the newsletter registration
     * based on this guideline provided by HubSpot:
     * https://legacydocs.hubspot.com/docs/methods/forms/submit_form
     */
    const postJSON = `{
      "submittedAt": "${new Date().getTime()}",
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "did_you_like_the_issue_",
          "value": "${formData.get('feedback-type')}"
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": "${formData.get('email')}"
        },
        {
        "objectTypeId": "0-1",
          "name": "message",
          "value": "${formData.get('feedback')}"
        }
      ]
    }`

    // Send postData to HubSpot
    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: postJSON,
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else return this.goToSuccessScreen()
      })
      .catch(() => {
        this.goToSuccessScreen() // Temporarily goToSuccessScreen() instead of remoteSubmissionFailed() for testing purposes
      })
      .finally(() => this.enableSubmission())
  }

  toggleSubmission() {
    this.submitTarget.toggleAttribute('disabled')
  }

  generateFormActionURL() {
    this.element.setAttribute('action', `${HS_ENDPOINT_PREFIX}/${HS_PORTAL_ID}/${HS_FORM_GUID}`)
  }

  getParamsFromURL() {
    const urlParams = new URLSearchParams(window.location.search)
    const typeValue = urlParams.get('type') || null
    if (typeValue) {
      // Make it nice before assigning to the hidden field
      this.feedbackTypeTarget.value = typeValue.charAt(0).toUpperCase() + typeValue.slice(1)
    }
    const emailValue = urlParams.get('email') || null
    if (emailValue) {
      // Make it nice before assigning to the hidden field
      this.emailTarget.value = emailValue
    }
  }

  goToSuccessScreen() {
    this.formTarget.classList.add('js-hidden')
    this.responseTarget.classList.remove('js-hidden')
  }
}
