import FormController from '../form/form_controller'
import * as userUtils from '../../_js/helpers/user'

export default class WaitingList extends FormController {
  static targets = ['email', 'action', 'gdprConfirm', 'fields', 'options', 'success']

  static HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'

  static HS_PORTAL_ID = 7692458

  static HS_SANDBOX_PORTAL_ID = 20854638

  static HS_FORM_GUID = '414aa297-a021-4a0e-8cd4-2e2d9366d49b'

  static HS_SANDBOX_FORM_GUID = 'f2b05cf6-3c03-4c6d-8dd4-ddd85e47b64b'

  static HS_SUBS_MARKETING_INFORMATION_ID = 9320643

  static HS_SANDBOX_SUBS_MARKETING_INFORMATION_ID = 24734259

  connect() {
    if (!(this.element instanceof HTMLFormElement)) {
      throw new Error('Form controller can only be used in <form> elements')
    }

    // Default values
    if (!this.hasValidateValue) this.validateValue = true

    // Disable native browser validation in favor of our own
    if (this.validateValue) {
      this.element.setAttribute('novalidate', '')
    }

    this.errorMessage = ''

    // Fill form email field if user is signed in
    this.checkLoginState()
  }

  enable() {
    this.actionTarget.disabled = !this.gdprConfirmTarget.checked
  }

  checkLoginState(event) {
    // If a storage event but not on the relevant keys, early return
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null

    // Fill out form with auth data
    if (this.user) {
      this.emailTarget.value = this.user.email
    }
  }

  submitRemotely() {
    this.generateFormActionURL()

    // Capture form data before any inputs are possibly disabled
    const formData = new FormData(this.element)

    // the GDPR checkbox needs to be checked
    if (!this.element.elements.gdpr.checked) return

    // Disable submission
    this.disableSubmission()

    /**
     * Prepate the data for the newsletter registration
     * based on this guideline provided by HubSpot:
     * https://legacydocs.hubspot.com/docs/methods/forms/submit_form
     */
    const postData = { fields: [] }

    // Add postdata timestamp
    postData.submittedAt = new Date().getTime()

    // Add newsletter form key / value pairs
    formData.forEach((value, key) => {
      if (key != 'gdpr') postData.fields.push({ name: key, value: value, objectTypeId: '0-1' })
    })

    // Cookie consent related additional params
    // This is mandatory as GDPR options are enabled in HubSpot's main settings
    postData.legalConsentOptions = {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Sketch to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId:
              GLOBALS.ENV === 'development'
                ? WaitingList.HS_SANDBOX_SUBS_MARKETING_INFORMATION_ID
                : WaitingList.HS_SUBS_MARKETING_INFORMATION_ID,
            text: 'I agree to receive educational emails from Sketch.',
          },
        ],
      },
    }

    // send postData to HubSpot
    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else this.showSuccessPanel()
      })
      .catch(() => {
        this.remoteSubmissionFailed()
      })
  }

  generateFormActionURL() {
    this.element.setAttribute(
      'action',
      `${WaitingList.HS_ENDPOINT_PREFIX}/${
        GLOBALS.ENV === 'development' ? WaitingList.HS_SANDBOX_PORTAL_ID : WaitingList.HS_PORTAL_ID
      }/${GLOBALS.ENV === 'development' ? WaitingList.HS_SANDBOX_FORM_GUID : WaitingList.HS_FORM_GUID}`
    )
  }

  resetForm() {
    this.actionTarget.classList.remove(FormController.classNames.WAITING)
  }

  showSuccessPanel() {
    this.resetForm()
    this.fieldsTarget.classList.add('js-hidden')
    this.optionsTarget.classList.add('js-hidden')
    this.successTarget.classList.remove('js-hidden')
    this.successTarget.classList.add('animated', 'animated--lay-in')
  }
}
