import { Controller } from 'stimulus'
import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper'
import { marked } from 'marked'

export default class FeatureCarouselController extends Controller {
  static targets = ['swiper', 'pagination', 'features']

  swiper = null

  isPaused = false

  circleSvg = `
    <svg width="24" height="24" class="feature-carousel__feature__circle-icon">
      <circle class="feature-carousel__feature__circle-initial" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none" stroke-opacity="0.3" />
      <circle class="feature-carousel__feature__circle-progress" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none" stroke-opacity="1" stroke-dasharray="75" stroke-dashoffset="0" />
    </svg>
  `

  pauseSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="feature-carousel__feature__pause-icon">
      <g fill="none" fill-rule="evenodd" transform="translate(4 4)">
        <path d="M0 0h16v16H0z" />
        <rect width="2" height="8" x="5" y="4" fill="currentColor" rx="1" />
        <rect width="2" height="8" x="9" y="4" fill="currentColor" rx="1" />
      </g>
    </svg>
  `

  playSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="feature-carousel__feature__play-icon">
      <g fill="none" fill-rule="evenodd">
          <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-opacity=".3" stroke-width="4" />
          <path d="M4 4h16v16H4z" />
          <path fill="currentColor" fill-rule="nonzero" d="m11.112 8.148 4.845 2.88c.167.098.305.242.4.416.296.537.117 1.222-.4 1.53l-4.845 2.878a1.046 1.046 0 0 1-.534.148c-.596 0-1.078-.502-1.078-1.12V9.12c0-.194.049-.386.142-.555a1.055 1.055 0 0 1 1.47-.417Z" />
      </g>
    </svg>
  `

  connect() {
    const outerObject = this

    this.swiper = new Swiper(this.swiperTarget, {
      effect: 'fade',
      allowTouchMove: false,
      fadeEffect: { crossFade: true },
      slidesPerView: 'auto',
      loop: false,
      preventClicks: false,
      autoplay: {
        delay: 12000,
        disableOnInteraction: false,
      },
      modules: [Autoplay, EffectFade, Pagination],
      breakpoints: {
        1024: {
          pagination: {
            el: this.featuresTarget,
            clickable: true,
            renderBullet: function (index, className) {
              const parent = this.el.closest('.feature-carousel')
              const bulletsContent = Array.from(parent.querySelectorAll('.feature-carousel__slider__slide')).map(
                (el) => {
                  const badge = el.getAttribute('data-badge')

                  if (badge) {
                    return `
                    <div class="feature-carousel__feature__title-wrapper">
                      <div class="feature-carousel__feature__progress-wrapper">
                        ${outerObject.circleSvg}
                        ${outerObject.pauseSvg}
                        ${outerObject.playSvg}
                      </div>
                      <p class="feature-carousel__feature__title">
                        ${el.getAttribute('data-title')}
                        <span class="feature-carousel__feature__badge">${badge}</span>
                      </p>
                    </div>
                    <div class="feature-carousel__feature__description styled-text">${marked(el.getAttribute('data-description'))}</div>
                  `
                  }
                  return `
                  <div class="feature-carousel__feature__title-wrapper">
                    <div class="feature-carousel__feature__progress-wrapper">
                      ${outerObject.circleSvg}
                      ${outerObject.pauseSvg}
                      ${outerObject.playSvg}
                    </div>
                    <span class="feature-carousel__feature__title">${el.getAttribute('data-title')}</span>
                  </div>
                  <div class="feature-carousel__feature__description styled-text">${marked(el.getAttribute('data-description'))}</div>
                `
                }
              )
              return `
            <div class="${className}" data-action="click->feature-carousel#handleBulletClick">
              ${bulletsContent[index]}
            </div>
          `
            },
            bulletClass: 'feature-carousel__feature',
            bulletActiveClass: 'is-active',
          },
        },
      },
    })

    this.featuresTargets.forEach((target) => {
      target.addEventListener('click', function (event) {
        if (event.target.tagName === 'A') {
          window.location = event.target.href
        }
      })
    })
  }

  handleBulletClick(event) {
    const featureElement = event.target.closest('.feature-carousel__feature')

    // If the user is clicking a currently active item
    if (featureElement.classList.contains('is-active')) {
      this.isPaused = !this.isPaused

      if (this.isPaused) {
        featureElement.classList.add('feature-carousel__feature--paused')
        this.swiper.autoplay.stop()
      } else {
        featureElement.classList.remove('feature-carousel__feature--paused')
        this.swiper.autoplay.start()
      }
    } else if (this.isPaused == true) {
      // If the user clicks another item other than the paused one

      // Remove the paused class from the previously paused item
      this.featuresTarget
        .querySelector('.feature-carousel__feature--paused')
        .classList.remove('feature-carousel__feature--paused')

      // Resume autoplay
      this.isPaused = false
      this.swiper.autoplay.start()
    }
  }
}
