import { Controller } from 'stimulus'
import { ajax } from '../../_js/base/utils'
import { BASE_API_URL } from '../../_js/base/endpoints'
import * as userUtils from '../../_js/helpers/user'

const HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HS_PORTAL_ID = 7692458
const HS_FORM_GUID = '6660b714-d29c-4868-a723-a37114acc481'
const HS_SANDBOX_PORTAL_ID = 20854638
const HS_SANDBOX_FORM_GUID = '52a22e21-95c9-4750-9ce2-0984e4dde716'

export default class FormArticleUsefulController extends Controller {
  static values = {
    feedbackTitlePositive: String,
    feedbackTitleNegative: String,
    feedbackSubTitlePositive: String,
    feedbackSubTitleNegative: String,
    collectionName: String,
  }

  static targets = [
    'emailPhase',
    'emailInput',
    'emailGDPR',
    'emailButton',
    'feedbackPhase',
    'votePhase',
    'feedbackTitle',
    'feedbackSubTitle',
    'feedbackHelp',
    'feedbackComment',
    'responseSuccess',
    'responseError',
    'voteButton',
    'votePositiveButton',
    'voteNegativeButton',
    'feedbackButton',
    'sendFeedbackButton',
    'cancelButton',
  ]

  isVotePositive = false

  voteWasSuccessful = false

  commentWasSuccessful = false

  emailWasSuccessful = false

  comment = ''

  hasVotingHistory() {
    const data = JSON.parse(window.localStorage.getItem('feedbackVotingHistory')) || {}
    const date = data[this.slugifyUrl(window.location.pathname)]
    if (date) {
      return Date.now() - parseInt(date) < 60 * 60 * 24 * 7 * 1000
    }
    return false
  }

  connect() {
    this.isProd = ['production', 'staging'].indexOf(this.element.dataset.env) !== -1

    this.prepopulateFields()

    if (this.hasVotingHistory()) {
      this.hideVotingPhase()
      this.showSuccessPhase()
    }
  }

  prepopulateFields(event) {
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null

    if (this.user) {
      this.emailInputTarget.value = this.user.email
    }
  }

  handleVoteClick(event) {
    event.preventDefault()
    const target = event.target

    this.disableVoteButtons()
    this.isVotePositive = target === this.votePositiveButtonTarget

    target.classList.add('is-pressed')
    this.votePhaseTarget.querySelector('.form-article-useful__feedback-question').classList.add('is-hidden')
    this.votePhaseTarget.querySelector('.form-article-useful__submitting-vote').classList.remove('is-hidden')

    setTimeout(() => {
      this.submitVote()
    }, 1500)
  }

  enableVoteButtons() {
    this.voteButtonTargets.forEach((button) => {
      button.disabled = false
      button.classList.remove('is-pressed')
    })
  }

  disableVoteButtons() {
    this.voteButtonTargets.forEach((button) => {
      button.disabled = true
    })
  }

  enableFeedbackButtons() {
    this.feedbackButtonTargets.forEach((button) => {
      button.disabled = false

      if (button.classList.contains('button--primary')) {
        button.classList.remove('is-waiting')
      }
    })
  }

  disableFeedbackButtons() {
    this.feedbackButtonTargets.forEach((button) => {
      button.disabled = true

      if (button.classList.contains('button--primary')) {
        button.classList.add('is-waiting')
      }
    })
  }

  enableFeedbackComment() {
    this.feedbackCommentTarget.disabled = false
  }

  disableFeedbackComment() {
    this.feedbackCommentTarget.disabled = true
  }

  async handleSendFeedbackClick(event) {
    event.preventDefault()

    // Remove errors
    this.emailInputTarget.classList.remove('is-invalid')
    this.emailGDPRTarget.classList.remove('is-invalid')
    this.feedbackCommentTarget.classList.remove('is-invalid')

    // Save variables
    this.comment = this.feedbackCommentTarget.value
    this.email = this.emailInputTarget.value

    // Check for validities
    if (!this.comment) {
      this.feedbackCommentTarget.classList.add('is-invalid')
      return
    }

    // Make the requests
    if (this.voteWasSuccessful) {
      if (!this.email) {
        this.handleCommentSubmission()
      } else if (Sketch.utils.isEmail(this.email)) {
        if (this.emailGDPRTarget.checked) {
          this.handleFullFormSubmission()
        } else {
          this.emailGDPRTarget.classList.add('is-invalid')
        }
      } else {
        this.emailInputTarget.classList.add('is-invalid')
      }
    }
  }

  async handleCommentSubmission() {
    this.disableFeedbackButtons()
    this.disableFeedbackComment()
    this.disableEmailInput()

    try {
      await this.postComment()

      this.hideFeedbackPhase()
      this.showSuccessPhase()
    } catch (error) {
      this.hideFeedbackPhase()
      this.showErrorPhase()
    }
  }

  // Send both the comment and the hubspot data
  async handleFullFormSubmission() {
    this.disableFeedbackButtons()
    this.disableFeedbackComment()
    this.disableEmailInput()

    let commentSuccess = false
    let hubspotSuccess = false

    // Attempt to send the comment
    try {
      await this.postComment()
      commentSuccess = true // Comment was successful
    } catch (error) {
      commentSuccess = false
    }

    // Attempt to send the HubSpot data only if comment was successful
    if (commentSuccess) {
      try {
        await this.postHubspotData()
        hubspotSuccess = true
      } catch (error) {
        hubspotSuccess = false
      }
    }

    // Determine the final success based on both sends
    if (commentSuccess && hubspotSuccess) {
      this.hideFeedbackPhase()
      this.showSuccessPhase() // Show success if both succeeded
    } else {
      this.hideFeedbackPhase()
      this.showErrorPhase() // Show error if either failed
    }
  }

  enableEmailInput() {
    this.emailInputTarget.disabled = false
  }

  disableEmailInput() {
    this.emailInputTarget.disabled = true
  }

  handleSkipClick(event) {
    event.preventDefault()
    this.skip()
  }

  skip() {
    this.hideFeedbackPhase()
    this.showSuccessPhase()
  }

  // eslint-disable-next-line class-methods-use-this
  slugifyUrl(url) {
    return (url.charAt(0) == '/' ? url.substr(1).slice(0, -1) : url).split('/').join('-')
  }

  saveVotingHistory() {
    const data = JSON.parse(window.localStorage.getItem('feedbackVotingHistory')) || {}
    data[this.slugifyUrl(window.location.pathname)] = Date.now()
    window.localStorage.setItem('feedbackVotingHistory', JSON.stringify(data))
  }

  submitVote() {
    // Send the submission to the API
    const data = {
      page: window.location.pathname,
      rating: this.isVotePositive ? 5 : 0,
      ratingType: this.collectionNameValue,
    }

    ajax({
      type: 'POST',
      url: `${BASE_API_URL}rating/`,
      data: data,
      success: () => {
        this.saveVotingHistory()
        this.voteWasSuccessful = true
        this.hideVotingPhase()
        this.showFeedbackPhase()
      },
      error: () => {
        this.voteWasSuccessful = false
        this.hideVotingPhase()
        this.showErrorPhase()

        this.votePhaseTarget.querySelector('.form-article-useful__feedback-question').classList.remove('is-hidden')
        this.votePhaseTarget.querySelector('.form-article-useful__submitting-vote').classList.add('is-hidden')

        setTimeout(() => {
          this.hideErrorPhase()
          this.enableVoteButtons()
          this.showVotingPhase()
        }, 3000)
      },
    })
  }

  postComment() {
    // Send the submission to the API
    const data = {
      page: window.location.pathname,
      rating: this.isVotePositive ? 5 : 0,
      ratingType: this.collectionNameValue,
      comment: this.comment,
    }

    return new Promise((resolve, reject) => {
      ajax({
        type: 'POST',
        url: `${BASE_API_URL}rating-comment/`,
        data: data,
        success: (response) => {
          resolve(response) // Resolve the Promise with the response
        },
        error: (error) => {
          reject(new Error('AJAX request failed: ' + error)) // Reject the Promise
        },
      })
    })
  }

  postHubspotData() {
    this.element.setAttribute(
      'action',
      `${HS_ENDPOINT_PREFIX}/${this.isProd ? HS_PORTAL_ID : HS_SANDBOX_PORTAL_ID}/${
        this.isProd ? HS_FORM_GUID : HS_SANDBOX_FORM_GUID
      }`
    )

    const postData = {
      submittedAt: new Date().getTime(),
      fields: [
        { objectTypeId: '0-1', name: 'email', value: this.email },
        { objectTypeId: '0-5', name: 'content', value: this.comment },
        { objectTypeId: '0-5', name: 'url_to_hubspot_conversation', value: window.location.href },
      ],
    }

    return fetch(this.element.action, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Submission to HubSpot failed')
      }
      return response.json() // Parses the JSON response and ensures promise resolves with it
    })
  }

  showVotingPhase() {
    this.votePhaseTarget.classList.remove('is-hidden')
  }

  hideVotingPhase() {
    this.votePhaseTarget.classList.add('is-hidden')
  }

  showFeedbackPhase() {
    this.feedbackTitleTarget.textContent = this.isVotePositive
      ? this.feedbackTitlePositiveValue
      : this.feedbackTitleNegativeValue
    this.feedbackSubTitleTarget.textContent = this.isVotePositive
      ? this.feedbackSubTitlePositiveValue
      : this.feedbackSubTitleNegativeValue

    if (this.isVotePositive) {
      this.feedbackPhaseTarget.classList.add('is-positive')
    }

    this.feedbackPhaseTarget.classList.remove('is-hidden')
  }

  hideFeedbackPhase() {
    this.feedbackPhaseTarget.classList.add('is-hidden')
  }

  showSuccessPhase() {
    this.responseSuccessTarget.classList.remove('is-hidden')
  }

  hideSuccessPhase() {
    this.responseSuccessTarget.classList.add('is-hidden')
  }

  showErrorPhase() {
    this.responseErrorTarget.classList.remove('is-hidden')
  }

  hideErrorPhase() {
    this.responseErrorTarget.classList.add('is-hidden')
  }
}
