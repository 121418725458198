import { Controller } from 'stimulus'

export default class WhatsNewController extends Controller {
  static targets = ['body', 'header']

  connect() {
    this.bodyTarget.addEventListener('scroll', this.scrollEventHandler.bind(this), { passive: true })
  }

  scrollEventHandler() {
    if (this.bodyTarget.scrollTop == 0) {
      this.headerTarget.classList.remove('small')
      return
    }
    this.headerTarget.classList.add('small')
  }
}
