// Added some useful console.info for NON-PROD envs
// when triggering GQL operations. Disabling the warnings here

/* eslint-disable no-console */
import * as auth from '../helpers/auth'
import { GRAPHQL_API_URL, GRAPHQL_PAYMENTS_API_URL } from './endpoints'

const API_ERROR = 'Something went wrong while trying to fetch the API'
const NOT_SIGNED_IN_ERROR = 'You need to provide both a valid auth token and a query'

export const SSO_ERROR = 'sso'

/**
 * For GraphQL operations we can use the following methods:
 *
 * - executeQuery()
 * - executeMutation() // not used yet
 * - executeUploadMutation()
 *
 * These offer a "data", "loading" and "error" state when using them,
 * some logging on non-prod envs (check console) and are basically a
 * wrapper around "gqlOperation" which can still be used as before.
 *
 * Wrapper flow:
 *   executeXXXXX() -> withAuthAndState() -> gqlOperation()
 *
 *   - "gqlOperation" actually does the fetch request
 *
 *   - "withAuthAndState" injects the "data", "loading" and "error" state and checks
 *     for an expired token to remove auth from localStorage
 *
 *   - "executeXXXXX" does some logging on non-prod envs
 *
 */
const gqlOperation = async ({
  authToken = null,
  query = null,
  variables = null,
  contentType = 'application/json',
  endpoint,
}) => {
  if (!authToken || typeof authToken !== 'string' || !query) {
    return Promise.reject(new Error(NOT_SIGNED_IN_ERROR))
  }

  const expirationDate = auth.getTokenExpirationDate('personal')
  const isTokenExpired = auth.isTokenExpired(expirationDate)

  // Check if the token has expired and try to refresh it
  if (isTokenExpired) {
    const refreshToken = auth.getRefreshToken('personal')

    const credentials = await auth.refreshAuthToken({
      authToken,
      refreshToken,
    })

    auth.updateLocalStorageCredentials({ credentials, previousToken: authToken })
  }

  // If the "authToken" was expired we might have refreshed it in the logic above
  // So we grab a brand new token from local storage to make sure its up-to-date and refreshed
  const latestToken = auth.getAuthToken('personal')

  let data
  const method = 'POST'
  const headers = {
    Authorization: `bearer ${latestToken}`,
  }

  // This is a Mutation with form data as a body
  if (query === 'formData') {
    data = await fetch(endpoint, {
      method,
      headers,
      body: variables,
    })
  }
  // This is a regular query / mutation (not FormData)
  else {
    data = await fetch(endpoint, {
      method,
      headers: {
        ...headers,
        'Content-Type': contentType,
      },
      body: JSON.stringify({ query, variables }),
    })
  }

  if (!data.ok) throw Error(API_ERROR)

  return data.json()
}

/**
 *
 * Injects "data", "loading" and "error" state for graphQL operations
 * and checks for authentication token
 */
const withStateAndAuth = async (args, endpoint = GRAPHQL_API_URL) => {
  try {
    const personalToken = auth.getAuthToken('personal')

    if (!personalToken && auth.hasSSOAuth()) {
      throw new Error(SSO_ERROR)
    }
    // Check if its a FormData mutation
    const hasFormData = Object.keys(args).some((key) => key === 'formData')

    // Check if its a mutation or a query operation by the args object keys
    const isQuery = Object.keys(args).some((key) => key === 'query')

    const { data, errors } = hasFormData
      ? await gqlOperation({ authToken: personalToken, query: 'formData', variables: args.formData, endpoint })
      : await gqlOperation({
          authToken: personalToken,
          query: isQuery ? args.query : args.mutation,
          variables: args.variables,
          contentType: args.contentType,
          endpoint,
        })

    // GraphQL Errors
    if (errors) {
      const isUnauthorized = errors.some((c) => c.code === 401)
      if (isUnauthorized) {
        auth.removeAuthAndProfileFromLocalStorage()
      }
    }

    return { data, errors }
  } catch (error) {
    return { errors: [{ message: error.message }] }
  }
}

/**
 * Run any graphQl mutation that has form data (used to upload files)
 *
 * example: executeUploadMutation({ formData }, ({ data, loading, error}) => {...} })
 */
export const executeUploadMutation = async ({ formData }, callback) => {
  if (GLOBALS.ENV === 'development') {
    console.groupCollapsed('%cRunning a GraphQL Operation: executeUploadMutation', 'font-weight: bold')
    console.info('%cMutation with FormData:', 'color: LightSlateGray')
    console.table([...formData])
    console.groupEnd()
  }

  return withStateAndAuth({ formData }, callback)
}

/**
 * Run any graphQl mutation and get data, loading and error states
 *
 * example: executeMutation({ mutation: ADD_USER_MUTATION, variables }, ({ data, loading, error}) => {...} })
 */
export const executeMutation = async ({ query, variables = null }, callback) => {
  if (GLOBALS.ENV === 'development') {
    console.groupCollapsed('%cRunning a GraphQL Operation: executeMutation', 'font-weight: bold')
    console.info('%cQuery:', 'color: LightSlateGray', query)
    console.info('%cVariables:', 'color: LightSlateGray', variables)
    console.groupEnd()
  }

  return withStateAndAuth({ query, variables }, callback)
}
/**
 * Run any graphQl query and get data, loading and error states
 *
 * example: executeQuery({ query: GET_USERS_QUERY, variables }, ({ data, loading, error}) => {...} })
 */
export const executeQuery = async ({ query, variables = null }) => {
  if (GLOBALS.ENV === 'development') {
    console.groupCollapsed('%cRunning a GraphQL Operation: executeQuery', 'font-weight: bold')
    console.info('%cQuery:', 'color: LightSlateGray', query)
    console.info('%cVariables:', 'color: LightSlateGray', variables)
    console.groupEnd()
  }

  return withStateAndAuth({ query, variables }, GRAPHQL_API_URL)
}

/**
 * Run any graphQl query and get data, loading and error states
 *
 * example: executeQuery({ query: GET_USERS_QUERY, variables }, ({ data, loading, error}) => {...} })
 */
export const executePaymentsQuery = async ({ query, variables = null }) => {
  if (GLOBALS.ENV === 'development') {
    console.groupCollapsed('%cRunning a GraphQL Operation: executePaymentsQuery', 'font-weight: bold')
    console.info('%cQuery:', 'color: LightSlateGray', query)
    console.info('%cVariables:', 'color: LightSlateGray', variables)
    console.groupEnd()
  }
  
  return withStateAndAuth({ query, variables }, GRAPHQL_PAYMENTS_API_URL)
}

export { gqlOperation }
