// eslint-disable-next-line import/prefer-default-export
export const GET_WORKSPACES_QUERY = `
  query getWorkspaces {
    me {
      workspaceMemberships(limit: 60) {
        entries {
          role
          workspace {
            identifier
            name
            type
            customer {
              ssoEnabled
              identifier
            }
          }
        }
      }
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const GET_SUBSCRIPTION_INFO_QUERY = `
  query getCustomer($customerId: UUID!) {
    customer(id: $customerId) {
      subscription {
        status
        subscriptionEnd
        currentPlan {    
          product
        }
      }
    }
  }
`
