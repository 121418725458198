import { serialize } from 'object-to-formdata'
import { BASE_API_URL } from './endpoints'

const DEFAULT_OPTIONS = {
  mode: 'cors',
}

async function request(method, endpoint, { params, opts } = {}) {
  let url = BASE_API_URL + endpoint.replace(/^\/+/, '')
  let options = { ...DEFAULT_OPTIONS, method }

  if (params) {
    if (method === 'GET') {
      url += '?' + new URLSearchParams(params).toString()
    } else {
      // The backend API accepts application/x-www-form-urlencoded or multipart/form-data
      options.body = serialize(params)
    }
  }

  if (opts) {
    options = { ...options, ...opts }
  }

  return fetch(url, options)
}

async function get(endpoint, params) {
  return request('GET', endpoint, { params })
}

async function post(endpoint, params) {
  return request('POST', endpoint, { params })
}

const API = {
  request,
  get,
  post
}

export default API
