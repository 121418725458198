const FOCUSABLE_ELEMENTS_SELECTOR = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not(:disabled)',
  'select:not(:disabled)',
  'textarea:not(:disabled)',
  'button:not(:disabled)',
  'iframe:not([tabindex^="-"])',
  'audio:not([tabindex^="-"])',
  'video:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])',
]

// Logic borrowed from jQuery's :visible selector
const elementIsVisible = function(element) {
  return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
}

/**
 * Find focusable elements
 * @param {(Document|DocumentFragment|Element)} [element=document] - the element to look for focusable elements in
 * @returns {Element[]} - An Array with the focusable Elements. Note: it's not a NodeList
 */
const focusableElements = function(node = document) {
  return [...node.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR.join(', '))].filter(function(el) {
    return elementIsVisible(el)
  })
}

/**
 * Find the first focusable element
 * @param {(Document|DocumentFragment|Element)} [element=document] - the element to look for focusable elements in
 * @returns {(Element|undefined)} - The first Element that is focusable Elements. Note: it's not a NodeList
 */
const firstFocusableElement = function(node = document) {
  return [...node.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR.join(', '))].find(function(el) {
    return elementIsVisible(el)
  })
}

/**
 * Focus on the first focusable element
 * @param {(Document|DocumentFragment|Element)} [element=document]
 */
const focusOnFirstElement = function(element = document) {
  return firstFocusableElement(element).focus()
}

export { focusableElements, firstFocusableElement, focusOnFirstElement }
