import { isLoggedIn, LOCALSTORAGE_USER_KEY, LOCALSTORAGE_USER_AUTHORIZATION_KEY } from './user'
import { storageAvailable } from '../base/feature-detect'
import { SIGN_IN_ENDPOINT } from '../base/endpoints'

const ALLOWED_AUTH_TYPES = ['personal', 'sso']
const ALLOWED_CREDENTIALS_PROPERTIES = ['expirationDateTime', 'authToken', 'refreshToken']

const SECONDS_TO_MILLISECONDS = 1000

function getAllAuthorizations() {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return []

  return JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_AUTHORIZATION_KEY))
}

export const isSsoOnly = () => {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return false

  try {
    return (
      !getAllAuthorizations().find((auth) => auth.type !== 'sso') && getAllAuthorizations().filter((auth) => auth.type === 'sso').length > 0
    )
  } catch {
    return false
  }
}

function hasSSOAuth() {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return false

  try {
    return getAllAuthorizations().filter((auth) => auth.type === 'sso').length > 0
  } catch {
    return false
  }
}

function getUserAuthTokenByIndex(authIndex = 0) {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return null

  return getAllAuthorizations()[authIndex]?.fragment.authToken
}

function getNumberOfAuths() {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return 0

  return getAllAuthorizations()?.length ?? 0
}

function removeAuthAndProfileFromLocalStorage() {
  if (!storageAvailable('localStorage') || !isLoggedIn()) return 0

  localStorage.removeItem(LOCALSTORAGE_USER_AUTHORIZATION_KEY)
  localStorage.removeItem(LOCALSTORAGE_USER_KEY)

  return true
}

// Compare token expiration date to check if it expired
const isTokenExpired = (expirationDateTime) => new Date(expirationDateTime) < new Date()

// Create the expired date
export const createExpiryDate = (duration) => new Date(Date.now() + duration * SECONDS_TO_MILLISECONDS).toISOString()

// Grab a property from the localStorage credentials "user:allAuthorizations.fragment"
const getPropertyFromCredentials = ({ type = 'personal', property }) => {
  const isAllowedProperty = ALLOWED_CREDENTIALS_PROPERTIES.includes(property)

  if (!isAllowedProperty) return null

  const allAuths = getAllAuthorizations()

  return allAuths.find((auth) => auth.type === type)?.fragment[property] ?? null
}

// Grabs the expiration date of a type of type
const getTokenExpirationDate = (type) => {
  const isAllowedType = ALLOWED_AUTH_TYPES.includes(type)

  if (!type || !isAllowedType) return null

  const expirationByToken = getPropertyFromCredentials({ type, property: 'expirationDateTime' })

  return expirationByToken
}

// Grab the authToken for personal authorization
const getAuthToken = (type) => {
  const isAllowedType = ALLOWED_AUTH_TYPES.includes(type)

  if (!isAllowedType) return null

  // We return all auths if we dont want a particular type
  if (!type) {
    return {
      personal: getPropertyFromCredentials({ type: 'personal', property: 'authToken' }),
      sso: getPropertyFromCredentials({ type: 'sso', property: 'authToken' }),
    }
  }

  const authToken = getPropertyFromCredentials({ type, property: 'authToken' })

  return authToken
}

// Get refresh token from auth
const getRefreshToken = (type) => {
  const isAllowedType = ALLOWED_AUTH_TYPES.includes(type)

  if (!type || !isAllowedType) return null

  const refreshToken = getPropertyFromCredentials({ type, property: 'refreshToken' })

  return refreshToken
}

// Format the response from the refresh token to have the proper structure
const formatCredentials = (responseJson) => {
  // eslint-disable-next-line camelcase
  const { access_token, refresh_token, expires_in, token_type } = responseJson

  // eslint-disable-next-line camelcase
  if (token_type === 'basic') {
    throw new Error('Unexpected basic token type')
  }

  return {
    __typename: 'OAuthCredentials',
    authToken: access_token,
    refreshToken: refresh_token,
    expirationDateTime: createExpiryDate(expires_in),
  }
}

// Refresh token  with authToken and refreshToken
const refreshAuthToken = async ({ authToken, refreshToken }) => {
  const headers = {
    'x-client-name': 'Website-frontend',
    'Content-Type': 'application/json',
    Authorization: `bearer ${authToken}`,
  }

  const response = await fetch(SIGN_IN_ENDPOINT, {
    method: 'post',
    body: JSON.stringify({
      access_token: authToken,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    }),
    headers,
  })

  const responseJson = await response.json()

  if (response.status === 200) {
    return formatCredentials(responseJson)
  }

  throw new Error(responseJson.message || 'Something went wrong')
}

// With the new refreshed token credentials and the old token
// we can replace the values on local storage effectively updating the expired token
const updateLocalStorageCredentials = ({ credentials, previousToken }) => {
  // Grab the expired credentials by the auth toke
  const allAuths = getAllAuthorizations()

  const newAuth = allAuths.map((authItem) => {
    if (authItem.fragment.authToken === previousToken) {
      return { ...authItem, fragment: credentials }
    }

    return authItem
  })

  // Update the local storage key with the new refresh credentials
  window.localStorage.setItem(LOCALSTORAGE_USER_AUTHORIZATION_KEY, JSON.stringify(newAuth))
}

export {
  isTokenExpired,
  formatCredentials,
  getTokenExpirationDate,
  getAuthToken,
  getNumberOfAuths,
  getUserAuthTokenByIndex,
  getAllAuthorizations,
  getRefreshToken,
  hasSSOAuth,
  updateLocalStorageCredentials,
  refreshAuthToken,
  removeAuthAndProfileFromLocalStorage,
}
